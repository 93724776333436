@import "../../mixins";
@import "../../variables";

.MN {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 11;

  &__head {
    background: #001e69;
    height: 60px;
    overflow: hidden;

    &__links {
      display: inline-block;
      vertical-align: middle;
      height: 60px;
      width: calc(100% - 64px);

      & > a {
        display: inline-block;
        font-size: 18px;
        color: #fff;
        padding: 0px 18px;
        line-height: 60px;
        vertical-align: middle;
        text-decoration: none;

        @include xsm {
          font-size: 14px;
        }

        &.active {
          background: white;
          color: #001e69;
        }
      }
    }
    &__close {
      display: inline-block;
      vertical-align: middle;
      max-width: 64px;
      padding: 12px;
      color: white;
      position: relative;

      &:before {
        content: "";
        width: 2px;
        height: 37px;
        position: absolute;
        display: block;
        top: 12px;
        left: 0px;
        background: #b7b7b7;
      }

      & > span {
        font-size: 16px;

        &:before {
          font-family: "natwest icons";
          content: "\e010";
          display: block;
          font-size: 16px;
          color: white;
        }
      }
    }
  }

  &__content {
    &__block {
      width: 100%;
      padding: 0px 18px;
      box-sizing: border-box;
      overflow: scroll;
      height: 100vh;

      &--2 {
        @extend .MN__content__block;
        position: absolute;
        background: white;
        left: 0;
        top: 60px;
        z-index: 10;
        display: none;

        &.NM__Active {
          display: block;
        }
      }

      &--3 {
        @extend .MN__content__block;
        position: absolute;
        background: white;
        left: 0;
        top: 0px;
        z-index: 10;
        display: none;

        &.NM__Active {
          display: block;
        }
      }
    }
    &__title {
      width: 100%;
      position: relative;
      display: block;
      border-bottom: 1px solid rgba(51, 51, 51, 0.4);
      font-size: 20px;
      line-height: 30px;
      padding: 12px 0 10px 0;
      text-align: center;
    }

    &__links {
      list-style-type: none;
      width: 100%;

      &--single > a,
      &--single > button {
        font-size: 18px;
        line-height: 48px;
        position: relative;
        color: #1d7b8a;
        display: block;
        width: 100%;
        text-align: left;
        text-decoration: none;

        & > svg {
          position: absolute;
          height: 18px;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }

      &--single > form {
        padding-top: 10px;

        input {
          border: none;
        }
      }
    }
  }

  &__bottom {
    width: calc(100% - 20px);
    position: fixed;
    background: white;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 12px 0px;
    border-top: 1px solid rgba(51, 51, 51, 0.4);
    display: flex;
    justify-content: space-evenly;
    z-index: 11;

    & > button {
      background: #1d7b8a;
      color: white;
      font-size: 18px;
      letter-spacing: 0.2;
      border: none;
      height: 56px;
      line-height: 56px;
      width: calc(50% - 18px);

      & > svg {
        margin-right: 7px;
        position: relative;
        top: 5px;
      }
    }
  }

  &__modal {
    position: absolute;
    top: 60px;
    left: 0;
    background: white;
    width: 100%;
    height: calc(100% - 60px);
    z-index: 10;

    &__back {
      position: absolute;
      left: 0;
      height: 31px;
      width: 60px;
      & > svg {
        transform: scale(-1, 1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 18px;
      }
    }
  }
}
