@import '../../../variables';


.field-amount {

  @media (max-width: #{$screen-sm-min}) {
    width: 100%;
    
    label {
      width: 100%;
    }

    .field-content {
      input {
        width: 100%;
      }
    }
  }

  &.field-block--sm {
    .input {
      width: 184px;
    }
  }

  &.field-block--lg {
    .input {
      width: 368px;
    }
  }

  .field-content > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 53%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
  }

  .field-content > input {
    padding: 4px 12px 0 12px;
    outline: none;
    font-weight: normal;
    border-radius: 8px;
  }
}