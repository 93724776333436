@import '../../../scss/colors';
@import '../../../variables';

.field-dropdown {

  @media (max-width: #{$screen-sm-min}) {
    width: 100%;
    label {
      width: 100%;
    }

    &.field-block--sm,
    &.field-block--lg {
      .dropdown {
        .btn.dropdown-toggle {
          width: 100%;
        }
      }
    }
  }

  &.field-block--sm {
    .dropdown {
      .btn {
        width: 184px;
      }

      [data-rr-ui-dropdown-item] {
        width: 184px;
      }
    }
  }

  &.field-block--lg {
    .dropdown {
      .btn {
        width: 368px;
      }

      [data-rr-ui-dropdown-item] {
        width: 368px;
      }
    }
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;
    float: right;
  }

  .dropdown {
    .btn {
      width: 235px;
    }

    .btn.dropdown-toggle {
      position: relative;;
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-border;
      text-align: left;
      padding-left: 16px;
      padding-top: 4px;
      border-radius: 8px;

      &:focus {
        box-shadow: none;
      }

      &::after {
        position: absolute;
        right: 10px;
        top: 20px;
      }
    }

    [data-rr-ui-dropdown-item] {
      width: 235px;
      font-size: 16px;
    }
  }
}
