@import "../../mixins";
@import "../../variables";
@import "../../scss/colors.scss";


.log-block {
  position:fixed;
  top: 0;
  right: 0;
  width: 600px;
  height: 200px;
  font-size: 16px;
  z-index: 10000;
  background-color: white;
  border: 2px solid red;
  overflow: scroll;
  overflow-wrap: anywhere;

  &--secondary {
    top: 300px;
    height: 100px;
  }

  &-2 {
    top: 220px;
    height: 150px;
  }
}

.ec {
  @media (max-width: #{$screen-sm-min}) {
    padding: 0 32px;
  }

  hr {
    margin: 24px 0;
  }
}

.ec__loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;background-color:rgba(0, 0, 0, 0.2);
  z-index: 10000;

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 47%;
    top: 47%;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.ec__banner {
  background-color: $color-bg;
  padding-top: 32px;
  padding-bottom: 32px;
  color: $color-white;

  @media (max-width: #{$screen-sm-min}) {
    &.row {
      margin: 0 -32px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &-title {
    font-size: 40px;
    line-height: 56px;
    font-family: $secondary-font;
  }

  &-subtitle {
    margin-top: 24px;
    font-size: 18px;
    line-height: 24px;;
  }
}

.ec__main {
  background-color: $color-ground-grey;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  background-color: $color-bg-light;

  p {
    margin-bottom: 16px;
  }

  a {
    color: $color-link;
  }
}

.ec__section-title {
  font-size: 20px;
  line-height: 32px;
  color: $color-heading-grey;
  margin-top: 32px;
}
.ec__section-content {
  margin-top: 16px;

  a {
    text-decoration: underline;
  }
}
.ec__section-footer {
  font-size: 14px;
  line-height: 19px;
  margin-top: 24px;
}








.ec__assets-table {
  height: 80px;
  
  &.ec__assets-table-single,
  &.ec__assets-table-multiple {
    // display: grid;
    // grid-template-columns: minmax(200px, 300px) 1fr 1fr minmax(71px, 200px);
    // grid-gap: 1rem;
    // border: 1px solid red;
    
    .ec__assets-row {
      padding: 16px;
      background-color: #f5f5f5;
      float: left;
      // height: 100px;

      .ec__assets-cell {
        // display: inline-block;
        float: left;
        margin-right: 24px;

        .ec__assets-cell--header {
          color: #666666;
        }

        &.ec__assets-cell--asset-type-count {
          min-width: 133px;
        }
        &.ec__assets-cell--asset-type-main {
          min-width: 120px;
        }
        &.ec__assets-cell--asset-type {
          min-width: 82px;
        }
        &.ec__assets-cell--asset-price {
          min-width: 231px;
        }
        &.ec__assets-cell--asset-deposit {
          min-width: 191px;
        }
      }
    }
  }
}



.ec__bullet {
  display: flex;
  margin-bottom: 16px;
}
.ec__bullet-left {
  position: relative;
  flex-basis: 24px;
}

.ec__bullet-right {
  //
}
.ec__bullet-point {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  top: 9px;
  position: absolute;
  background-color: $color-bullet-dot;
}













.ec--step-progress {
  margin-top: 24px;
  margin-bottom: 24px;
}
.ec--step-progress-bar {
  margin-top: 24px;
  margin-bottom: 24px;
  height: 20px;
  width: 100%;
  border: 2px solid $color-champion-purple;
  border-radius: 10px;
  position: relative;

  .ec--step-progress-bar-dot {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: $color-champion-purple;
    border: 2px solid $color-champion-purple;
    position: absolute;

    &:nth-of-type(2) {
      left: 33%;
    }
    &:nth-of-type(3) {
      left: 66%;
    }
  }

  .ec--step-progress-bar-step {
    height: 16px;
    background-color: $color-champion-purple;
    border-radius: 8px;
  }

  &.step-1 {
    .ec--step-progress-bar-step {
      width: 16px;
    }
  }
  &.step-2 {
    .ec--step-progress-bar-step {
      width: calc(33% + 16px);
    }
    .ec--step-progress-bar-dot:nth-of-type(2) {
      background-color: white;
    }
  }
  &.step-3 {
    .ec--step-progress-bar-step {
      width: calc(66% + 16px);
    }
    .ec--step-progress-bar-dot:nth-of-type(2) {
      background-color: white;
    }
    .ec--step-progress-bar-dot:nth-of-type(3) {
      background-color: white;
    }
  }
  &.step-4 {
    .ec--step-progress-bar-step {
      width: 100%;
    }
    .ec--step-progress-bar-dot:nth-of-type(2) {
      background-color: white;
    }
    .ec--step-progress-bar-dot:nth-of-type(3) {
      background-color: white;
    }
  }
}

.ec--step-title {
  h2 {
    font-size: 40px;
    line-height: 49px;;
    margin-bottom: 24px;
  }
}
.ec--step-subtitle {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  color: $color-copy-subtitle;
}

.calc-main .ec--step-bottom {
  margin-top: 24px;

  @media (max-width: #{$screen-sm-min}) {
    width: 100%;
    
    .btn {
      width: 100%;
    }
  }

  .btn {
    margin-bottom: 24px;
  }
  
  .btn-primary {
    display: inline-block;
    padding: 0 32px;
    line-height: 44px;
  }

  .btn-margin-right {
    margin-right: 24px;
  }

}

.ec__warning {
  position: relative;
  background: $color-info-grey;
  border: 2px solid $color-heading-grey;
  border-radius: 8px;
  margin-top: 32px;
  padding: 16px 24px 16px 72px;

  .inner-left-border {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 8px;
    height: 100%;
    background-color: $color-heading-grey;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.ec__warning--error {
    background: rgba(207, 34, 63, 0.1);
    border: 2px solid rgb(207, 34, 63);

    .ec__warning-title {
      font-weight: bold;
    }

    .inner-left-border-red {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      width: 8px;
      height: 100%;
      background-color: #cf223f;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &-icon {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  &-title {
    color: $color-heading-grey;
    font-size: 20px;
    line-height: 32px;
  }
  &-content {
    color: $color-body-grey;
    font-size: 16px;
    line-height: 24px;
  }
}


//
.ec--step-your-details {
  margin-top: 24px;
}




// ******************************************************
// field-input
// ******************************************************
.field-input {

  .input-icon {
    position: relative;
  }

  .input-icon > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 53%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
  }

  .input-icon > input {
    padding-left: 25px;
    padding-right: 0;
  }

  .input-icon-right > i {
    right: 0;
  }

  .input-icon-right > input {
    padding-left: 0;
    padding-right: 25px;
    text-align: right;
  }
}










.field-group {
  position: relative;
  margin-top: 24px;

  &--dark {
    background: $color-info-grey;
    border-radius: 0px;
    padding: 24px;
  }

  &__title {
    font-size: 18px;
  }

  &__action {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .field-group__action {
    .btn-link {
      display: flex;

        .deleteIcon {
          height: 24px;
          width: 24px;
          align-items: left;
        }
        
        div {
          vertical-align: text-bottom;
          margin-left: 8px;
        }
      }
    }
}







.field-summary {
  width: 512px;
  background: $color-info-grey;
  border-radius: 0px;
  padding: 24px;


  @media (max-width: #{$screen-sm-min}) {
    width: 100%;
  }

  &__item {
    margin-top: 16px;

    &:first-of-type {
      margin-top: 0;
    }

    &-title {
      color: rgb(102, 102, 102);
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      margin-bottom: 4px;


      svg {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        vertical-align: middle;;
      }
    }

    &-content {
      color: rgb(0, 0, 0);
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
    }
  }

  &__item--highlight {
    .field-summary__item-title {
      color: rgb(0, 0, 0);
      font-size: 20px;
      line-height: 32px;
    }

    .field-summary__item-content {
      color: rgb(102, 102, 102);
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__action {
    margin-top: 24px;

    .btn.btn-link {
      display: inline-block;
      color: rgb(205, 47, 0);
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__action-editing {

    .btn.btn-link {
      color: $color-primary;
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .field-block {
    margin-top: 16px;
  }
}