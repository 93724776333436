@import "../../scss/colors.scss";

.Search {
  &__label {
    border: 2px solid $color-champion-purple;
    height: 36px;
    width: 100%;
    display: block;
    position: relative;
    border-radius: 8px;

    &:after {
      content: "";
      position: absolute;
      opacity: 0;
      width: calc(100% - 4px);
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: 0 solid;
      // border-top: 6px solid $color-white;
      bottom: -7px;
      left: 0;
      transition: opacity 1s ease-out 0.1s;
    }

    &.is-focused:after {
      opacity: 1;
    }

    & > span {
      line-height: 36px;
      font-size: 14px;
      color: $color-ground-grey;
      position: absolute;
      display: block;
      width: 100%;
      top: -2px;
      left: 10px;
      pointer-events: none;
      font-weight: 300;
      transition: top 0.3s linear, font-size 0.3s linear,
        line-height 0.3s linear;

      &.Search--touched {
        font-size: 10px;
        line-height: 13px;
        height: 13px;
        top: 3px;
        color: #1d7b8a;

        &:after {
          opacity: 1;
        }
      }
    }

    & > input {
      // padding: 8px 10px 9px 10px;
      // display: block;
      // font-size: 14px;
      // width: calc(100% - 36px);
      // outline: none;
      // box-sizing: border-box;
      // height: 36px;
      // border: unset;
      // padding: 8px 10px 9px 10px;
      // display: block;
      // font-size: 14px;
      // width: calc(100% - 36px);
      // outline: none;
      // box-sizing: border-box;
      // height: 36px;
      // border: unset;
      // color: #333 !important;
      // width: calc(100% - 80px) !important;
      // // font-size: 18px !important;
      // line-height: 36px !important;
      // padding: 11px 0;
      // margin: 0 0 0 20px !important;
      // font-family: "RN House Sans";
      float: left;
    width: 116px;
    padding: 10px;
    margin-right: 0;
    border: 0;
    outline: 0;
    text-align: left;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #5f5f5f !important;
    background: 0;
      background: transparent;


      &:focus + span {
        font-size: 10px;
        line-height: 13px;
        height: 13px;
        top: 0px;
        color: $color-white;

        &:after {
          opacity: 1;
        }
      }
    }

    & > button {
      width: 40px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      & > svg {
        width: 30px;
        height: 15px;
      }
    }
  }
}
