@import "../../mixins";

.Footer {
  width: 100%;
  background: #001e69;
  padding: 0px 41px;
  box-sizing: border-box;

  @include lg {
    padding: 0px 51px;
  }

  &__top {
    border-bottom: 8px solid #fff;
    padding-top: 30px;

    @include lg {
      border: none;
      display: flex;
      justify-content: space-around;
    }

    & > a {
      text-decoration: none;
      display: block;
      padding: 0px 7px 30px 7px;

      & > span {
        font-family: "RN House Sans Bold";
        font-size: 20px;
        color: white;
        line-height: 24px;
        display: inline-block;
        vertical-align: middle;
      }

      & > svg, & > img {
        padding-right: 13px;
        display: inline-block;
        vertical-align: middle;
      }

      & > img {
        height: 30px;
      }
    }
  }

  &__nav {
    border-bottom: 4px solid white;
    padding-bottom: 44px;
    padding-top: 4px;

    @include lg {
      display: flex;
      justify-content: space-between;
    }

    &__section {
      @include lg {
        width: calc(33.3% - 22px);
        border-top: 8px solid white;
      }

      & > h3 {
        font-size: 18px;
        font-family: "RN House Sans Bold";
        color: #fff;
        padding-top: 21px;
        padding-bottom: 11px;
        margin: 0 7px;

        @include lg {
          margin: 0px;
          padding-top: 14px;
          font-size: 20px;
        }
      }

      & > ul {
        list-style-type: none;

        & > li {
          margin-left: 1px;
          padding-right: 22px;

          & > a {
            font-size: 14px;
            color: white;
            text-decoration: none;
            padding: 4px 7px;
            display: inline-block;
            position: relative;

            &::after {
              content: "";
              width: 0;
              height: 1px;
              background: #fff;
              transition: width 0.3s ease;
              bottom: 2px;
              position: absolute;
              left: 0;
              display: block;
            }

            &:hover {
              &::after {
                width: 100%;
              }
            }

            @include lg {
              padding: 5px 0;
              font-family: "RN House Sans Bold";
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  &__links {
    padding-top: 22px;
    padding-bottom: 30px;

    & > ul {
      list-style-type: none;

      & > li {
        width: 50%;
        display: inline-block;

        @include lg {
          width: 19.9%;
        }

        & > a {
          font-size: 14px;
          line-height: 18px;
          color: #fff;
          text-decoration: none;
          padding: 5px 0px;
          display: inline-block;
          position: relative;

          @include lg {
            font-family: "RN House Sans Bold";
          }

          &::after {
            content: "";
            width: 0;
            height: 1px;
            background: #fff;
            transition: width 0.3s ease;
            bottom: 2px;
            position: absolute;
            left: 0;
            display: block;
          }

          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__external {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    & > ul {
      list-style-type: none;
      & > li {
        margin-right: 12px;
        display: inline-block;
        & > a {
          width: 40px;
          display: block;
          text-decoration: none;

          & > img {
            width: 100%;
          }
        }
      }
    }
  }

  &__copyright {
    display: block;
    color: white;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 10px;
  }
}
