@import "../../../mixins";
@import "../../../variables";

.EC__confirmation {
  &--loading {
    position: relative;
    animation: circle-loading 1.5s linear infinite;
    display: flex;
    margin: auto;
    &::after {
      // animation: bar-loading 1s infinite;
      // background-color: $loading-bar;
      // bottom: 0;
      // content: '';
      // height: 4px;
      // left: 0;
      // position: absolute;
      // width: 20%;
      animation: circle-loading 1.5s linear infinite;
      top: 50%;
      content: '';
      height: 32px;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 32px;
    }
  }
  &__btn {
    height: 56px;
    background-color: #288090;
    display: block;
    color: white;
    text-decoration: none;
    width: 100%;
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;

    @include lg {
      width: 240px;
      margin-left: auto;
    }
  }
  & .EC__form__wrapper {
    max-width: initial;

    & > p {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #333333;
      padding: 24px 24px 56px 0px;

      & > strong {
        font-family: $primary-font-bold;
      }
    }
  }
  & .EC__Section {
    border-bottom: solid 1px #c9c6c6;
    padding: 24px 0px;

    & > h2 {
      display: block;
      margin-bottom: 24px;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2c1f42;
    }

    &__item {
      max-width: 500px;
      margin-bottom: 16px;

      & > span {
        width: 45%;
        display: inline-block;
        vertical-align: top;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: #333333;

        &:first-of-type {
          margin-right: 10%;
          width: 45%;
        }

        &:last-of-type {
          font-family: $primary-font-bold;
        }
      }
    }
  }

  @keyframes circle-loading {
    from{
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
