@import "../../mixins";
@import "../../variables";
@import "../../scss/colors.scss";

.Main {
  &__grey {
    background: #f5f5f5;
  }

  &__grey,
  &__section {
    // padding: 20px 0px 32px 0px;
  }

  &__section-accordian {
    padding: 0px 0px 0px 0px;
  }

  &__checkApply {
    background: #f5f5f5;
    padding: 0px;
    max-width: 1164px;
    margin: 0 auto;

    @include lg {
      padding: 0px 20px;
    }

    &__paragraph {
      display: block;
      margin-top: 30px;
      font-size: 16px;
      line-height: 1.63;
      color: #666666;
      padding: 0 20px;

      @include lg {
        background: #f5f5f5;
        padding: 0px;
        max-width: 1164px;
        margin: 30px auto 0 auto;
      }
    }
  }

  &__loginApply {
    display: block;
    max-width: 1164px;
    margin: 0 auto;
    padding-bottom: 20px;
    background: white;
    max-width: 1164px;
    margin: 0 auto;

    &>h2 {
      width: 100%;
      padding: 30px 20px 0px 0px;
      font-size: 34px;
      line-height: 1.29;
      color: #001e69;
    }
  }

  &__preApply {
    display: block;
    margin: 0 auto;
    background: white;
    margin: 0 auto;
  }

  &__warning {
    display: block;
    max-width: 1164px;
    margin: 0 auto;
    padding-bottom: 0px;
    background: white;
    max-width: 1164px;
    padding: 0 20px;
  }

  .EI__content__checklist li {
    list-style-type: none;

    .tick{
      margin-right: 24px;
      margin-left: 10px;
    }
  }

  .text-comp {
    a {
      text-decoration: underline;
    }
  }

  .btn {
    a {
      color: inherit;
    }
  }

  .productlistingmultiple,
  .singlearticle {
    .cta-wrapper {
      .btn.btn-primary {
        width: 240px;
        line-height: 40px;
        
        a {
          vertical-align: baseline;
          font-size: 18px;
        }
      }
    }

    .comp-rich-text{
      p {
        margin-left: 24px;
      }
    }
  }

  .comp_whyUs_shelf {
    .whyusblockwrapper {
      .row {
        margin-top: 24px;
      }

      .title-wrapper {
        margin-bottom: 16px;
      }

      .title-comp {
        text-align: center;
      }

      .text-comp {
        display: block;
        text-align: center;
      }

      .btn {
        margin-top: 16px;
      }
    }
  }

  .comp_whyUs_shelf {
    // margin-top: 16px;
  }

  .section-contact-us {
    margin-top: 48px;
    background-color: $color-ground-grey;
  }

  .title-comp,.h2-custom {
    text-align: center;

    .custom-font{
      font-family: $secondary-font;
      margin-left: 24px;
    }

    @include lg {
      text-align: left;
    }
  }
}

.Bottom {
  &__section {
    background: #f5f5f5;
    width: 100%;
  }

  &__desktop {
    display: block;
  }

  @include lg {
    &__desktop {
      display: block;
      padding: 30px 0px;
      border-top: 1px solid #c0c0c0;
    }
  }

  &__content {
    max-width: 1164px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 30px 20px 50px 20px;

    &>h2 {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #333333;
      margin-bottom: 20px;
    }

    & a {
      color: #288090;
    }

    &>a {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      display: block;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }

    & p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #666666;
    }
  }
}

.standalone-wrapper-wt {
  padding-top: 35px;
  padding-bottom: 35px;
}

.standalone-text-wrapper,
.iconWrapper {
  gap: 15px;
  display: flex;

  @include lg {
    gap: 24px;
    margin-left: 30px;
  }
}

.elig_icon_wt {
  @include lg {
    background-size: 35px;
    left: 53px;
    width: 35px;
    height: 35px;
  }

  left: 10px;
  height: 24px;
  width: 24px;
  background-size: 24px;
  background: url('../../assets/images/info_icon_quote.png');
}

.sa-bgGrey {
  background-color: #f5f5f5;
}

.in-page-nav .title-wrapper {
  margin-bottom: 14px;
  padding-left: 8px;
  padding-right: 12px;
}

.in-page-nav {
  padding: 20px 0;
  background-color: $color-lilac;
}

.multi-product-comp {
  padding: 35px 0;

  .flt__comp {
    margin: 35px 0;
  }
}

.shelf-titleWrapper {
  padding: 35px 0;
}

.productlistingmultiple {
  background-color: $color-ground-grey;
}

.main-title {
  font-family: $secondary-font;
}

.in-page-nav-links {
  margin-top: 10px;
}