@import "../scss/colors.scss";

footer {
    font-size: 14px;
    line-height: 30px;
    font-family: "RNHouseSansRegular", sans-serif;
    width: 100%;
    margin-top: auto;
    background: $color-champion-purple;
}

footer .iah__container {
    width: 100%;
    padding: 0 45px;
    margin-right: auto;
    margin-left: auto
}

@media(max-width:1255px) {
    footer .iah__container {
        padding: 0 51px
    }
}

@media(max-width:1023px) {
    footer .iah__container {
        padding: 0 30px
    }
}

@media(max-width:767px) {
    footer .iah__container {
        padding: 0 18px
    }
}

footer .iah__container:after {
    content: "";
    clear: both;
    display: block
}

footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0
}

footer ul li h4 {
    padding-bottom: 11px
}

footer ul .footer__header {
    font-size: 20px;
    font-family: "RNHouseSansBold", sans-serif;
    margin: 0;
    color: #fff;
    line-height: 24px
}

footer ul .footer__header a {
    font-size: 20px;
    text-decoration: none;
    font-family: "RNHouseSansBold", sans-serif;
    line-height: 24px;
    display: block;
    margin: 0
}

footer ul a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    position: relative
}

footer ul a:active {
    outline: 0 solid #fff !important
}

footer ul a:hover {
    text-decoration: none;
    color: #fff
}

.footer__list__two ul li a::after,
.footer__list__three ul li a::after,
.footer__list__one ul li a span::after {
    content: "";
    width: 0;
    height: 1px;
    display: block;
    background: #fff;
    transition: width .3s ease;
    -ms-transition: width .3s ease;
    position: absolute;
    left: 0;
    bottom: 2px
}

.footer__list__two ul li a:hover:after,
.footer__list__three ul li a:hover:after,
.footer__list__one ul li a span:hover:after {
    width: 100%
}

@media(max-width:767px) {
    .ia__footer .footer__list__two ul li a:hover:after {
        width: calc(100% - 12px);
        margin-left: 7px
    }
}

.footer__list__one ul li a span:after {
    bottom: -2px
}

.footer__list__one ul li a span:hover:after {
    bottom: -2px
}

.flex-rw {
    display: flex;
    flex-flow: row wrap
}

.footer__list__one {
    padding-top: 46px;
    padding-bottom: 52px
}

.footer__list__one ul .footer__header img {
    position: relative;
    padding-right: 13px;
    width: 43px;
    height: auto
}

.footer__list__one ul li {
    display: inline-block;
    width: 33.3%;
    margin: 0 auto;
    text-align: center;
    line-height: normal
}

.footer__list__two {
    padding-bottom: 45px;
    clear: both;
    display: inline-block;
    width: 100%;
    float: left
}

.footer__list__two.col__four ul {
    width: 25%
}

@media(max-width:767px) {
    .footer__list__two.col__four ul {
        width: 100%
    }
}

.footer__list__two ul {
    width: 33.3%;
    margin: 0 auto;
    float: left
}

.footer__list__two ul:before {
    content: "";
    border-top: 8px solid #fff;
    display: block;
    margin-right: 22px
}

@media(max-width:767px) {
    .footer__list__two ul:before {
        border-top: 0
    }
}

.footer__list__two ul li {
    line-height: normal
}

.footer__list__two ul li a {
    float: left;
    display: inline-block;
    line-height: 18px;
    padding: 5px 0
}

.footer__list__two ul:last-child {
    padding-right: 0
}

.footer__list__two ul:last-child:before {
    margin-right: 0
}

.footer__list__three {
    padding-top: 12px;
    padding-bottom: 25px;
    border-top: 4px solid #fff;
    float: left;
    width: 100%
}

.footer__list__three ul li {
    display: inline-block;
    width: 19.9%;
    line-height: normal
}

.footer__list__three ul li a {
    font-family: "RNHouseSansBold", sans-serif;
    padding: 5px 0;
    display: inline-flex
}

@media(max-width:767px) and (min-width:200px) {
    .footer__list__three ul li a {
        font-family: "RNHouseSansRegular", sans-serif
    }
}

.ia__footer--adaptive .footer__copytext p {
    padding: 10px 0 7px
}

.footer__list__sociallinks {
    float: left
}

.footer__list__sociallinks ul li {
    float: left;
    padding-right: 15px;
    height: 40px
}

@media(max-width:767px) {
    .footer__list__sociallinks ul li {
        padding-right: 4px
    }
}

.footer__list__sociallinks ul li a {
    font-size: 0;
    width: 40px;
    height: 40px
}

.footer__list__sociallinks ul li a i {
    height: 40px;
    width: 40px;
    background-size: 100% !important;
    display: inline-block
}

.footer__list__sociallinks ul li a .fa-facebook {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/FaceBookIcon.png") no-repeat right top
}

.footer__list__sociallinks ul li a .fa-facebook:hover {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/FaceBook-Icon-inverted.png") no-repeat right top
}

.footer__list__sociallinks ul li a .fa-twitter {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/Twitter-Icon.png") no-repeat right top
}

.footer__list__sociallinks ul li a .fa-twitter:hover {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/Twitter-Icon-inverted.png") no-repeat right top
}

.footer__list__sociallinks ul li a .fa-rssicon {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/RSS-Icon.png") no-repeat right top
}

.footer__list__sociallinks ul li a .fa-rssicon:hover {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/RSS-Icon-inverted.png") no-repeat right top
}

.footer__list__sociallinks ul li a .fa-linkedin {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/Linkedin-Icon.png") no-repeat right top
}

.footer__list__sociallinks ul li a .fa-linkedin:hover {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/Linkedin-Icon-inverted.png") no-repeat right top
}

.footer__list__sociallinks ul li a:after {
    height: 0 !important
}

.footer__list--right {
    float: right;
    top: -17px;
    position: relative
}

.footer__list--right .icon-fscs {
    font-size: 0;
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iafooter/clientlibs/clientlib_ia_footer_base/resources/images/fscs-footer.png") no-repeat right top;
    width: 146px;
    height: 35px;
    display: inline-block
}

@media(max-width:767px) {
    .footer__list--right {
        float: left
    }
}

.footer-social-icon {
    float: left;
    width: 100%;
    height: 40px;
    margin-bottom: 14px
}

@media(max-width:767px) {
    .footer-social-icon {
        margin-bottom: 7px;
        min-height: 40px;
        height: auto
    }
}

.footer__copytext {
    display: block;
    clear: both
}

.footer__copytext p {
    color: #fff;
    float: left;
    font-size: 14px;
    line-height: 18px;
    position: relative
}

.footer__copytext p a {
    color: #fff
}

.footer__copytext p:focus {
    outline: 1px auto #fff
}

.footer__list__two ul li {
    width: auto;
    clear: both;
    margin-left: 1px;
    padding-right: 22px
}

.ia__footer.ia__footer--responsive nav.listonehide.listtwohide.listthreehide .footer-social-icon {
    min-height: 65px
}

.ia__footer.ia__footer--responsive nav.listthreehide.listtwohide.listonehide.footer__premier .footer__copytext {
    padding-top: 10px
}

@media(max-width:767px) {
    .footer-social-icon {
        margin-bottom: 20px
    }

    .footer__list__one ul li,
    .footer__list__two,
    .footer__list__two ul {
        width: 100%;
        text-align: left;
        margin: 0
    }

    .footer__list__one {
        padding-bottom: 39px;
        padding-top: 45px
    }

    .footer__list__one ul li {
        padding-left: 0;
        padding-bottom: 30px
    }

    .footer__list__one ul li a:before {
        left: -15px !important
    }

    .footer__list__one ul li:last-child {
        padding-bottom: 0
    }

    .ia__footer.ia__footer--adaptive nav.listonehide.listtwohide.listthreehide .footer-social-icon {
        min-height: 65px
    }

    .ia__footer.ia__footer--adaptive nav.listthreehide.listtwohide.listonehide.footer__premier .footer__copytext {
        padding-top: 10px
    }

    .cq-wcm-edit .footer__list__sociallinks ul li a i {
        float: left
    }

    .footer__list__two {
        border-top: 8px solid #fff;
        padding-bottom: 44px;
        padding-top: 4px
    }

    .footer__list__two ul {
        border: 0 none;
        padding-bottom: 0
    }

    .footer__list__three {
        padding-top: 22px;
        padding-bottom: 30px
    }

    .footer__list__three ul li {
        display: inline-block;
        padding-right: 0;
        float: left;
        width: 50%
    }

    footer ul .footer__header.question-circle::before {
        left: 0;
        position: relative
    }

    .footer__list__one ul li a {
        padding: 0 7px
    }

    .footer__list__two ul li a {
        padding: 4px 7px;
        float: none
    }

    footer h4.footer__header {
        padding-top: 25px;
        padding-bottom: 15px;
        margin-top: 0;
        font-size: 18px
    }

    .footer__list__sociallinks {
        padding-bottom: 12px;
        min-width: 176px;
        width: calc(100% - 146px)
    }

    .footer__list__sociallinks .footer__list--right {
        top: 0
    }
}

@media(min-width:768px) {
    .footer__hide--desktop {
        display: none
    }
}

@media(max-width:767px) {
    .footer__hide--mobile {
        display: none
    }
}

.ia__footer.ia__footer--adaptive .footer__list__one .footer__header {
    display: inline-block
}

.ia__footer.ia__footer--adaptive .footer__list__one img {
    vertical-align: bottom;
    top: 0
}

.ia__footer.ia__footer--adaptive .footer__list__one span {
    display: inline-block;
    position: relative;
    top: 0
}

.ia__footer.ia__footer--adaptive .footer__list__two ul li {
    clear: both
}

.ia__footer.ia__footer--adaptive .footer__list--right a:focus {
    overflow: initial
}

.ia__footer.ia__footer--adaptive a:active {
    outline: 0 solid #ffbf47 !important
}

.ia__footer.ia__footer--adaptive a:focus {
    outline: 1px auto #fff !important
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    .ia__footer.ia__footer--adaptive a:focus {
        outline: 1px solid #fff !important
    }
}

html:not(.firefox) .ia__footer.ia__footer--adaptive a:focus {
    outline: 1px auto #fff !important
}

.footer__list__one a.footer__header:focus {
    float: none !important;
    text-decoration: none;
    color: #fff;
    overflow: inherit;
    display: inherit
}

.footer__list__one span {
    display: inline-block;
    position: relative;
    top: 3px
}

footer ul li a:focus {
    color: #fff;
    text-decoration: none
}

html:not(.firefox) footer ul li a:focus {
    outline: 1px auto #fff;
    color: #fff;
    text-decoration: none
}

.footer__list__two h4.footer__header {
    padding-top: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

@media(max-width:767px) {
    .footer__list__two h4.footer__header {
        padding-top: 21px;
        padding-bottom: 11px;
        margin: 0 7px
    }
}

html:not(.firefox) footer .footer__list--right a.icon-fscs:focus {
    outline: 1px auto #fff;
    outline-offset: 0;
    overflow: inherit
}

nav.footer__premier .footer__list__one {
    padding-bottom: 43px
}

nav.listtwohide.listonehide .footer__list__three {
    border: 0 none;
    padding-top: 40px
}

.listonehide .footer__list__two {
    border-top: 0 none
}

.listonehide .footer__list__two ul {
    margin-top: 20px
}

@media(max-width:767px) {
    .listonehide .footer__list__two ul {
        margin-top: 0
    }
}

.listonehide .footer__list__two ul:before {
    border: 0 none
}

nav.listonehide.listtwohide.listthreehide .footer-social-icon {
    padding-top: 25px;
    min-height: 65px
}

@media(max-width:767px) {
    nav.listonehide.listtwohide.listthreehide .footer-social-icon {
        padding-top: 30px
    }
}