
@import "../scss/colors.scss";

.calc-main {

  .btn {
    display: block;
    height: 44px;
    border: none;
    border-radius: 0;
    font-size: 16px;
    line-height: 24px;

    &.btn-primary {
      background-color: $color-bright-purple;
      border-radius: 26px;
      color: $color-btn-text;
      line-height: 44px;

      &:hover {
        background-color: $color-regal-purple;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 3px $color-bright-purple;
      }
    }

    &.btn-tertiary {
      color: $color-bright-purple;
      background-color: $color-white;
      border: 1px solid $color-bright-purple;
      border-radius: 26px;

      &:hover {
          color: $color-regal-purple;
          border: 1px solid $color-regal-purple;
          background-color: $color-lilac;
          border-color: $color-regal-purple ;
          border-width: 2px;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 3px $color-bright-purple;
      }
    }

    &:disabled,
    &[disabled] {
      background-color: #a9ccd3;
      border: solid 1px #a9ccd3;
      pointer-events: none;
    }
  }

  .btn-link {
    padding: 0;
    width: auto;
    height: auto;
    text-decoration: underline;
    color: $color-bright-purple;
  }
}


.calc-main {

  .input-group {

    .input-group-text {
      font-size: 16px;
      line-height: 20px;
      background: none;
    }

    input {
      font-size: 16px;
      line-height: 20px;
      border-left: none;
    }
  }
}