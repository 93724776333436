@import "../scss/colors.scss";

.single-article-shelf {
    padding-top: 35px;
    padding-bottom: 50px;
    display: block
}

.single-article-shelf .cta-horizontal-alignment .additional-cta-wrapper,
.single-article-shelf .cta-horizontal-alignment .cta-wrapper {
    display: block;
    cursor: pointer
}

.single-article-shelf .cta-horizontal-alignment .additional-cta-wrapper {
    margin-right: initial;
    float: none
}

.single-article-shelf .cta-horizontal-alignment .toggle-app.clearfix:before,
.single-article-shelf .cta-horizontal-alignment .toggle-app.clearfix:after {
    display: block
}

.single-article-shelf.acc-enabled .accordion-comp .panel-group {
    padding-top: 21px
}

.single-article-shelf.acc-enabled .accordion-comp .panel-group .panel {
    padding-left: 10px
}

.single-article-shelf.acc-enabled .accordion-comp .panel-group .panel .panel-heading {
    padding-top: 18px;
    padding-bottom: 0
}

.single-article-shelf.acc-enabled .accordion-comp .panel-group .panel .panel-heading .acordion-crossicon {
    margin-right: 20px;
    margin-top: 7px
}

.single-article-shelf.acc-enabled .accordion-comp .panel-group .panel .panel-heading .acordion-animation {
    margin-left: 36px
}

.single-article-shelf.acc-enabled .accordion-comp .panel-group .panel .panel-body {
    padding-top: 18px !important;
    padding-bottom: 0 !important
}

.single-article-shelf.acc-enabled .cta.cta-primary {
    margin-top: 27px !important
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text {
    color: #333
}

.single-article-shelf.theme-rose {
    background-color: #cf223f;
    color: #fff
}

.single-article-shelf.theme-rose .accordion-comp .panel-group .panel .panel-heading a .acordion-animation h3,
.single-article-shelf.theme-rose .accordion-comp .panel-group .panel .panel-heading a .section h3 {
    color: #fff;
    width: 100%;
    width: -moz-calc(100%);
    width: -webkit-calc(100%);
    width: -o-calc(100%);
    width: calc(100%);
    background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, #fff);
    background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
    background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
    background-image: linear-gradient(transparent calc(100% - 1px), #fff 10px, #fff);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-transition: background-size ease-in-out 180ms;
    -moz-transition: background-size ease-in-out 180ms;
    -o-transition: background-size ease-in-out 180ms;
    transition: background-size ease-in-out 180ms;
    padding-bottom: 3px
}

html:not(.firefox) .single-article-shelf.theme-rose .accordion-comp .panel-group .panel .panel-heading a .acordion-animation h3:focus,
html:not(.firefox) .single-article-shelf.theme-rose .accordion-comp .panel-group .panel .panel-heading a .section h3:focus {
    outline: #fff
}

.single-article-shelf.theme-rose .accordion-comp .panel-group .panel .panel-heading a:hover .acordion-animation h3,
.single-article-shelf.theme-rose .accordion-comp .panel-group .panel .panel-heading a:hover .section h3 {
    text-decoration: none;
    background-size: 0 100%
}

.single-article-shelf.theme-rose .accordion-comp .comp-rich-text a {
    color: #fff;
    background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, color);
    background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
    background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
    background-image: linear-gradient(transparent calc(100% - 1px), #fff 10px, #fff)
}

.single-article-shelf.theme-rose .accordion-comp .panel-group .panel .panel-heading a .acordion-crossicon {
    background-image: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/acc-img-plus2.svg")
}

.single-article-shelf.theme--midnight-blue {
    background-color: $color-champion-purple;
    color: #fff;
}

.single-article-shelf.theme--midnight-blue .accordion-comp .panel-group .panel .panel-heading a .acordion-animation h3,
.single-article-shelf.theme--midnight-blue .accordion-comp .panel-group .panel .panel-heading a .section h3 {
    color: #fff;
    width: 100%;
    width: -moz-calc(100%);
    width: -webkit-calc(100%);
    width: -o-calc(100%);
    width: calc(100%);
    background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, #fff);
    background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
    background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
    background-image: linear-gradient(transparent calc(100% - 1px), #fff 10px, #fff);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-transition: background-size ease-in-out 180ms;
    -moz-transition: background-size ease-in-out 180ms;
    -o-transition: background-size ease-in-out 180ms;
    transition: background-size ease-in-out 180ms;
    padding-bottom: 3px
}

html:not(.firefox) .single-article-shelf.theme--midnight-blue .accordion-comp .panel-group .panel .panel-heading a .acordion-animation h3:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .accordion-comp .panel-group .panel .panel-heading a .section h3:focus {
    outline: #fff
}

.single-article-shelf.theme--midnight-blue .accordion-comp .panel-group .panel .panel-heading a:hover .acordion-animation h3,
.single-article-shelf.theme--midnight-blue .accordion-comp .panel-group .panel .panel-heading a:hover .section h3 {
    text-decoration: none;
    background-size: 0 100%
}

.single-article-shelf.theme--midnight-blue .accordion-comp .comp-rich-text a {
    color: #fff;
    background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, color);
    background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
    background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
    background-image: linear-gradient(transparent calc(100% - 1px), #fff 10px, #fff)
}

.single-article-shelf.theme--midnight-blue .accordion-comp .panel-group .panel .panel-heading a .acordion-crossicon {
    background-image: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/acc-img-plus2.svg")
}

.single-article-shelf.theme-rose {
    padding-top: 70px;
    padding-bottom: 70px
}

.single-article-shelf.theme-rose .image-wrapper .rbsTwill {
    background: 0
}

.single-article-shelf.theme-rose .cta.cta-primary:hover .cta-text:after,
.single-article-shelf.theme-rose .cta.cta-primary:focus .cta-text:after {
    background-size: cover;
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/cta-hover-yellowbg.png")
}

.single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn {
    background-color: #001a72
}

.single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn.selected {
    background-color: #001a72
}

.single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn:after {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/cta-hover-nw.png");
    background-size: cover
}

.single-article-shelf.theme-iom-yellow,
.single-article-shelf.theme-copper,
.single-article-shelf.theme-stone {
    padding-top: 70px;
    padding-bottom: 70px
}

.single-article-shelf.theme-iom-green {
    padding-top: 70px;
    padding-bottom: 70px
}

.single-article-shelf.theme--light-grey {
    padding-top: 70px;
    padding-bottom: 70px
}

.single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn {
    background-color: #1d7b8a
}

.single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn.selected {
    background-color: #1d7b8a
}

.single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn:after {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/cta-hover-nw.png");
    background-size: cover
}

.single-article-shelf.theme--midnight-blue {
    padding-top: 70px;
    padding-bottom: 70px
}

.single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn {
    background-color: #fff;
    color: #333
}

.single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn.selected {
    background-color: #fff
}

.single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn:after {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/cta-hover-aqua-lombard.png");
    background-size: cover
}

.single-article-shelf.theme-grey .image-wrapper .rbsTwill {
    background: 0
}

.single-article-shelf.single-article--left-align .image-wrapper {
    float: none
}

.single-article-shelf.theme-blue .toggle-app .btnwrapper .appbtn:after {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/cta-hover-nw.png");
    background-size: cover;
    background-repeat: no-repeat
}

.single-article-shelf.theme-white .btnwrapper .appbtn,
.single-article-shelf.theme-grey .btnwrapper .appbtn,
.single-article-shelf.theme--light-grey .btnwrapper .appbtn {
    background-color: #1d7b8a
}

.single-article-shelf.theme-white .btnwrapper .appbtn:after,
.single-article-shelf.theme-grey .btnwrapper .appbtn:after,
.single-article-shelf.theme--light-grey .btnwrapper .appbtn:after {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/cta-hover-nw.png");
    background-size: cover;
    background-repeat: no-repeat
}

html:not(.firefox) .single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme-aqua .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme--violet .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme--heather .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme-purple .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme-berry .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
html:not(.firefox) .single-article-shelf.theme--navy .toggle-app .btnwrapper .appbtn:focus span.buttonfocus {
    outline: 1px auto #fff
}

.msedge .single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme-aqua .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme-aqua .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme-aqua .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme--violet .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme--violet .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme--violet .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme--heather .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme--heather .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme--heather .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme--royal-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme--royal-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme--royal-blue .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme-purple .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme-purple .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme-purple .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme-berry .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme-berry .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme-berry .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msedge .single-article-shelf.theme--navy .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme--navy .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme--navy .toggle-app .btnwrapper .appbtn:focus span.buttonfocus {
    outline: 1px dotted #fff
}

html:not(.firefox) .single-article-shelf.theme-rose .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-rose .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--violet .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--violet .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--heather .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--heather .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-blue .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-blue .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-purple .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-purple .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-berry .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-berry .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--navy .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--navy .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-secondary:focus {
    outline: 1px auto #fff
}

.firefox .single-article-shelf.theme-rose .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-rose .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-aqua .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-aqua .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--midnight-blue .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--midnight-blue .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--midnight-blue .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--midnight-blue .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--violet .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--violet .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--heather .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--heather .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-blue .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-blue .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-purple .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-purple .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-berry .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-berry .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--navy .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--navy .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-secondary:focus {
    outline-offset: 0
}

html:not(.firefox) .single-article-shelf.theme-rose .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--violet .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--heather .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-blue .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-purple .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-berry .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--navy .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-primary:focus {
    outline: 1px auto #1d7b8a
}

html:not(.firefox) .single-article-shelf.theme-rose .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme-rose .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme--violet .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme--violet .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme--heather .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme--heather .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme-blue .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme-blue .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme-purple .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme-purple .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme-berry .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme-berry .app-store-links__item--android a:focus,
html:not(.firefox) .single-article-shelf.theme--navy .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme--navy .app-store-links__item--android a:focus {
    outline: 1px auto #fff
}

.firefox .single-article-shelf.theme-rose .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme-rose .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme-aqua .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme-aqua .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme--midnight-blue .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme--midnight-blue .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme--violet .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme--violet .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme--heather .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme--heather .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme-blue .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme-blue .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme--royal-blue .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme--royal-blue .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme-purple .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme-purple .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme-berry .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme-berry .app-store-links__item--android a:focus,
.firefox .single-article-shelf.theme--navy .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme--navy .app-store-links__item--android a:focus {
    outline-offset: 0
}

html:not(.firefox) .single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn:focus span.buttonfocus {
    outline: 1px auto #1d7b8a
}

.msedge .single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.firefox .single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn:focus span.buttonfocus {
    outline: 1px dotted #1d7b8a
}

html:not(.firefox) .single-article-shelf.theme--light-grey .cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey .cta-wrapper .cta.cta-secondary:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey .additional-cta-wrapper .cta.cta-primary:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey .additional-cta-wrapper .cta.cta-secondary:focus {
    outline: 1px auto #1d7b8a
}

.firefox .single-article-shelf.theme--light-grey .cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--light-grey .cta-wrapper .cta.cta-secondary:focus,
.firefox .single-article-shelf.theme--light-grey .additional-cta-wrapper .cta.cta-primary:focus,
.firefox .single-article-shelf.theme--light-grey .additional-cta-wrapper .cta.cta-secondary:focus {
    outline-offset: 0
}

html:not(.firefox) .single-article-shelf.theme--light-grey .app-store-links__item--ios a:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey .app-store-links__item--android a:focus {
    outline: 1px auto #1d7b8a
}

.firefox .single-article-shelf.theme--light-grey .app-store-links__item--ios a:focus,
.firefox .single-article-shelf.theme--light-grey .app-store-links__item--android a:focus {
    outline-offset: 0
}

.single-article-shelf.theme-rose .title-wrapper .title-comp,
.single-article-shelf.theme--midnight-blue .title-wrapper .title-comp,
.single-article-shelf.theme-aqua .title-wrapper .title-comp,
.single-article-shelf.theme--violet .title-wrapper .title-comp,
.single-article-shelf.theme-purple .title-wrapper .title-comp,
.single-article-shelf.theme--heather .title-wrapper .title-comp,
.single-article-shelf.theme-berry .title-wrapper .title-comp,
.single-article-shelf.theme--royal-blue .title-wrapper .title-comp,
.single-article-shelf.theme-copper .title-wrapper .title-comp,
.single-article-shelf.theme--navy .title-wrapper .title-comp {
    color: #fff
}

.single-article-shelf.theme-rose .comp-rich-text h1,
.single-article-shelf.theme-rose .comp-rich-text h2,
.single-article-shelf.theme-rose .comp-rich-text h3,
.single-article-shelf.theme-rose .comp-rich-text h4,
.single-article-shelf.theme-rose .comp-rich-text h5,
.single-article-shelf.theme-rose .comp-rich-text h6,
.single-article-shelf.theme-rose .comp-rich-text .subheader,
.single-article-shelf.theme--midnight-blue .comp-rich-text h1,
.single-article-shelf.theme--midnight-blue .comp-rich-text h2,
.single-article-shelf.theme--midnight-blue .comp-rich-text h3,
.single-article-shelf.theme--midnight-blue .comp-rich-text h4,
.single-article-shelf.theme--midnight-blue .comp-rich-text h5,
.single-article-shelf.theme--midnight-blue .comp-rich-text h6,
.single-article-shelf.theme--midnight-blue .comp-rich-text .subheader,
.single-article-shelf.theme-aqua .comp-rich-text h1,
.single-article-shelf.theme-aqua .comp-rich-text h2,
.single-article-shelf.theme-aqua .comp-rich-text h3,
.single-article-shelf.theme-aqua .comp-rich-text h4,
.single-article-shelf.theme-aqua .comp-rich-text h5,
.single-article-shelf.theme-aqua .comp-rich-text h6,
.single-article-shelf.theme-aqua .comp-rich-text .subheader,
.single-article-shelf.theme--violet .comp-rich-text h1,
.single-article-shelf.theme--violet .comp-rich-text h2,
.single-article-shelf.theme--violet .comp-rich-text h3,
.single-article-shelf.theme--violet .comp-rich-text h4,
.single-article-shelf.theme--violet .comp-rich-text h5,
.single-article-shelf.theme--violet .comp-rich-text h6,
.single-article-shelf.theme--violet .comp-rich-text .subheader,
.single-article-shelf.theme-purple .comp-rich-text h1,
.single-article-shelf.theme-purple .comp-rich-text h2,
.single-article-shelf.theme-purple .comp-rich-text h3,
.single-article-shelf.theme-purple .comp-rich-text h4,
.single-article-shelf.theme-purple .comp-rich-text h5,
.single-article-shelf.theme-purple .comp-rich-text h6,
.single-article-shelf.theme-purple .comp-rich-text .subheader,
.single-article-shelf.theme--heather .comp-rich-text h1,
.single-article-shelf.theme--heather .comp-rich-text h2,
.single-article-shelf.theme--heather .comp-rich-text h3,
.single-article-shelf.theme--heather .comp-rich-text h4,
.single-article-shelf.theme--heather .comp-rich-text h5,
.single-article-shelf.theme--heather .comp-rich-text h6,
.single-article-shelf.theme--heather .comp-rich-text .subheader,
.single-article-shelf.theme-berry .comp-rich-text h1,
.single-article-shelf.theme-berry .comp-rich-text h2,
.single-article-shelf.theme-berry .comp-rich-text h3,
.single-article-shelf.theme-berry .comp-rich-text h4,
.single-article-shelf.theme-berry .comp-rich-text h5,
.single-article-shelf.theme-berry .comp-rich-text h6,
.single-article-shelf.theme-berry .comp-rich-text .subheader,
.single-article-shelf.theme--royal-blue .comp-rich-text h1,
.single-article-shelf.theme--royal-blue .comp-rich-text h2,
.single-article-shelf.theme--royal-blue .comp-rich-text h3,
.single-article-shelf.theme--royal-blue .comp-rich-text h4,
.single-article-shelf.theme--royal-blue .comp-rich-text h5,
.single-article-shelf.theme--royal-blue .comp-rich-text h6,
.single-article-shelf.theme--royal-blue .comp-rich-text .subheader,
.single-article-shelf.theme-copper .comp-rich-text h1,
.single-article-shelf.theme-copper .comp-rich-text h2,
.single-article-shelf.theme-copper .comp-rich-text h3,
.single-article-shelf.theme-copper .comp-rich-text h4,
.single-article-shelf.theme-copper .comp-rich-text h5,
.single-article-shelf.theme-copper .comp-rich-text h6,
.single-article-shelf.theme-copper .comp-rich-text .subheader,
.single-article-shelf.theme--navy .comp-rich-text h1,
.single-article-shelf.theme--navy .comp-rich-text h2,
.single-article-shelf.theme--navy .comp-rich-text h3,
.single-article-shelf.theme--navy .comp-rich-text h4,
.single-article-shelf.theme--navy .comp-rich-text h5,
.single-article-shelf.theme--navy .comp-rich-text h6,
.single-article-shelf.theme--navy .comp-rich-text .subheader {
    color: #fff
}

.single-article-shelf.theme-rose .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme-rose .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme-rose .comp-rich-text ul li:before,
.single-article-shelf.theme--midnight-blue .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme--midnight-blue .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme--midnight-blue .comp-rich-text ul li:before,
.single-article-shelf.theme-aqua .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme-aqua .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme-aqua .comp-rich-text ul li:before,
.single-article-shelf.theme--violet .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme--violet .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme--violet .comp-rich-text ul li:before,
.single-article-shelf.theme-purple .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme-purple .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme-purple .comp-rich-text ul li:before,
.single-article-shelf.theme--heather .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme--heather .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme--heather .comp-rich-text ul li:before,
.single-article-shelf.theme-berry .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme-berry .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme-berry .comp-rich-text ul li:before,
.single-article-shelf.theme--royal-blue .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme--royal-blue .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme--royal-blue .comp-rich-text ul li:before,
.single-article-shelf.theme-copper .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme-copper .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme-copper .comp-rich-text ul li:before,
.single-article-shelf.theme--navy .comp-rich-text span.list-item-tick:before,
.single-article-shelf.theme--navy .comp-rich-text span.list-item-square:before,
.single-article-shelf.theme--navy .comp-rich-text ul li:before {
    color: #fff
}

.single-article-shelf.theme--light-grey .title-wrapper .title-comp {
    color: #001a72
}

.single-article-shelf.theme--light-grey .comp-rich-text h1,
.single-article-shelf.theme--light-grey .comp-rich-text h2,
.single-article-shelf.theme--light-grey .comp-rich-text h3,
.single-article-shelf.theme--light-grey .comp-rich-text h4,
.single-article-shelf.theme--light-grey .comp-rich-text h5,
.single-article-shelf.theme--light-grey .comp-rich-text h6 {
    color: #fff
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-white .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-white .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-white .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-white .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-white .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-white .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-white .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text h6,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text p,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text ul,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text h2,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text h3,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text h4,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text h5,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text h6 {
    margin-bottom: 0;
    margin-top: 20px
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text ul li,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text ul li {
    margin-top: 20px
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text ol,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text ol {
    margin-left: 20px
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-white .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text .list-item-square,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text .list-item-tick,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text .list-item-square {
    margin-bottom: 0;
    margin-top: 20px
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text a {
    color: #fff;
    width: 100%;
    width: -moz-calc(100%);
    width: -webkit-calc(100%);
    width: -o-calc(100%);
    width: calc(100%);
    background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, #fff);
    background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
    background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
    background-image: linear-gradient(transparent calc(100% - 1px), #fff 10px, #fff);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-transition: background-size ease-in-out 180ms;
    -moz-transition: background-size ease-in-out 180ms;
    -o-transition: background-size ease-in-out 180ms;
    transition: background-size ease-in-out 180ms;
    padding-bottom: 3px
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text a:hover {
    background-size: 0 100%
}

.msedge .single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-rose .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-blue .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-purple .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--violet .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--heather .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-berry .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--navy .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-stone .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-copper .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text a:hover {
    background-image: none
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-rose .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-purple .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--violet .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--heather .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-berry .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--navy .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-stone .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-copper .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text a:focus {
    background-image: none
}

html:not(.firefox) .single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-rose .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-blue .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-purple .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--violet .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--heather .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-berry .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--navy .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-stone .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-copper .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text a:focus {
    outline: 1px auto #fff
}

html:not(.firefox) .single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-rose .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-rose .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-blue .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-blue .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-purple .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-purple .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-aqua .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-aqua .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--midnight-blue .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--violet .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--violet .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--heather .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--heather .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--royal-blue .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--royal-blue .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-berry .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-berry .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-iom-green .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-iom-green .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-iom-yellow .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-iom-yellow .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--navy .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--navy .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-stone .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-stone .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-copper .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-copper .additional-rte-wrapper .comp-rich-text a:focus:hover {
    outline: 1px auto #fff
}

.single-article-shelf.theme-white.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-grey.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme--light-grey.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-rose.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-blue.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-purple.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-aqua.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme--midnight-blue.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme--violet.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme--heather.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme--royal-blue.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-berry.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-gold.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-iom-green.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-iom-yellow.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme--navy.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-stone.theme--heather .comp-rich-text a:focus,
.single-article-shelf.theme-copper.theme--heather .comp-rich-text a:focus {
    background-image: none
}

html:not(.firefox) .single-article-shelf.theme-white.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-grey.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-rose.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-blue.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-purple.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-aqua.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--midnight-blue.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--violet.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--heather.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-berry.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-gold.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-iom-green.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-iom-yellow.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--navy.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-stone.theme--heather .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-copper.theme--heather .comp-rich-text a:focus {
    outline: 1px auto #1d7b8a
}

html:not(.firefox) .single-article-shelf.theme-white.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-grey.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--light-grey.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-rose.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-blue.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-purple.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-aqua.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--midnight-blue.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--violet.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--heather.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--royal-blue.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-berry.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-gold.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-iom-green.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-iom-yellow.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--navy.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-stone.theme--heather .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-copper.theme--heather .comp-rich-text a:focus:hover {
    outline: 1px auto #1d7b8a
}

.single-article-shelf.theme-white.theme-blue,
.single-article-shelf.theme-grey.theme-blue,
.single-article-shelf.theme--light-grey.theme-blue,
.single-article-shelf.theme-rose.theme-blue,
.single-article-shelf.theme-blue.theme-blue,
.single-article-shelf.theme-purple.theme-blue,
.single-article-shelf.theme-aqua.theme-blue,
.single-article-shelf.theme--midnight-blue.theme-blue,
.single-article-shelf.theme--violet.theme-blue,
.single-article-shelf.theme--heather.theme-blue,
.single-article-shelf.theme--royal-blue.theme-blue,
.single-article-shelf.theme-berry.theme-blue,
.single-article-shelf.theme-gold.theme-blue,
.single-article-shelf.theme-iom-green.theme-blue,
.single-article-shelf.theme-iom-yellow.theme-blue,
.single-article-shelf.theme--navy.theme-blue,
.single-article-shelf.theme-stone.theme-blue,
.single-article-shelf.theme-copper.theme-blue {
    padding-top: 70px;
    padding-bottom: 70px
}

.single-article-shelf.theme-white.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-grey.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme--light-grey.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-rose.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-blue.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-purple.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-aqua.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme--midnight-blue.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme--violet.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme--heather.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme--royal-blue.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-berry.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-gold.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-iom-green.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-iom-yellow.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme--navy.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-stone.theme-blue .image-wrapper .rbsTwill,
.single-article-shelf.theme-copper.theme-blue .image-wrapper .rbsTwill {
    background: 0
}

.single-article-shelf.theme-white.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-white.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-grey.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-grey.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--light-grey.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--light-grey.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-rose.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-rose.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-blue.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-blue.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-purple.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-purple.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-aqua.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-aqua.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--midnight-blue.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--midnight-blue.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--violet.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--violet.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--heather.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--heather.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--royal-blue.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--royal-blue.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-berry.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-berry.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-gold.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-gold.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-iom-green.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-iom-green.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-iom-yellow.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-iom-yellow.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--navy.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--navy.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-stone.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-stone.theme-blue .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-copper.theme-blue .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-copper.theme-blue .additional-rte-wrapper .comp-rich-text a {
    width: 100%;
    width: -moz-calc(100%);
    width: -webkit-calc(100%);
    width: -o-calc(100%);
    width: calc(100%);
    background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, #fff);
    background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
    background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
    background-image: linear-gradient(transparent calc(100% - 1px), #fff 10px, #fff);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-transition: background-size ease-in-out 180ms;
    -moz-transition: background-size ease-in-out 180ms;
    -o-transition: background-size ease-in-out 180ms;
    transition: background-size ease-in-out 180ms;
    padding-bottom: 3px;
    color: #fff
}

.single-article-shelf.theme-white.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-white.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-grey.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-grey.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--light-grey.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--light-grey.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-rose.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-rose.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-blue.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-blue.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-purple.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-purple.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-aqua.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-aqua.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--midnight-blue.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--midnight-blue.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--violet.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--violet.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--heather.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--heather.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--royal-blue.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--royal-blue.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-berry.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-berry.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-gold.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-gold.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-iom-green.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-iom-green.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-iom-yellow.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-iom-yellow.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--navy.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--navy.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-stone.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-stone.theme-blue .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-copper.theme-blue .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-copper.theme-blue .additional-rte-wrapper .comp-rich-text a:focus {
    background-image: none
}

.single-article-shelf.theme-white .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-white .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-grey .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-grey .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--light-grey .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--light-grey .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-rose .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-blue .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-purple .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-aqua .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--midnight-blue .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--midnight-blue .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--violet .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--heather .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-berry .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-gold .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-gold .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-iom-green .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-iom-green .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-iom-yellow .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-iom-yellow .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--navy .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-stone .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-stone .additional-cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-copper .cta-wrapper .cta.cta-primary,
.single-article-shelf.theme-copper .additional-cta-wrapper .cta.cta-primary {
    margin-top: 35px
}

.single-article-shelf.theme-white .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-white .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-grey .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-grey .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--light-grey .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--light-grey .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-rose .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-blue .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-purple .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-aqua .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--midnight-blue .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--midnight-blue .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--violet .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--heather .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-berry .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-gold .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-gold .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-iom-green .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-iom-green .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-iom-yellow .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-iom-yellow .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--navy .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-stone .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-stone .additional-cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-copper .cta-wrapper .cta.cta-secondary,
.single-article-shelf.theme-copper .additional-cta-wrapper .cta.cta-secondary {
    margin-top: 20px
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a {
    width: 100%;
    width: -moz-calc(100%);
    width: -webkit-calc(100%);
    width: -o-calc(100%);
    width: calc(100%);
    background-image: -webkit-linear-gradient(transparent 95%, #1d7b8a 10px, #1d7b8a);
    background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #1d7b8a 10px, #1d7b8a);
    background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #1d7b8a 10px, #1d7b8a);
    background-image: linear-gradient(transparent calc(100% - 1px), #1d7b8a 10px, #1d7b8a);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-transition: background-size ease-in-out 180ms;
    -moz-transition: background-size ease-in-out 180ms;
    -o-transition: background-size ease-in-out 180ms;
    transition: background-size ease-in-out 180ms;
    padding-bottom: 3px;
    color: #1d7b8a
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:hover,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:hover {
    background-size: 0 100%
}

.msedge .single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:hover,
.msedge .single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:hover {
    background-image: none
}

.single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:focus {
    background-image: none
}

html:not(.firefox) .single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:focus {
    outline: 1px auto #1d7b8a
}

html:not(.firefox) .single-article-shelf.theme-white .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-white .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-grey .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-grey .additional-rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--light-grey .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme--light-grey .additional-rte-wrapper .comp-rich-text a:focus:hover {
    outline: 1px auto #1d7b8a
}

.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a {
    width: 100%;
    width: -moz-calc(100%);
    width: -webkit-calc(100%);
    width: -o-calc(100%);
    width: calc(100%);
    background-image: -webkit-linear-gradient(transparent 95%, #001a72 10px, #001a72);
    background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #001a72 10px, #001a72);
    background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #001a72 10px, #001a72);
    background-image: linear-gradient(transparent calc(100% - 1px), #001a72 10px, #001a72);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-transition: background-size ease-in-out 180ms;
    -moz-transition: background-size ease-in-out 180ms;
    -o-transition: background-size ease-in-out 180ms;
    transition: background-size ease-in-out 180ms;
    padding-bottom: 3px;
    color: #001a72
}

.single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a:focus,
.single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a:focus {
    background-image: none
}

html:not(.firefox) .single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a:focus,
html:not(.firefox) .single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a:focus {
    outline: 1px auto #001a72
}

html:not(.firefox) .single-article-shelf.theme-gold .rte-wrapper .comp-rich-text a:focus:hover,
html:not(.firefox) .single-article-shelf.theme-gold .additional-rte-wrapper .comp-rich-text a:focus:hover {
    outline: 1px auto #001a72
}

.single-article-shelf .toggle-app .btnwrapper {
    height: auto;
    width: auto;
    position: relative;
    margin-top: 35px;
    margin-right: 0;
    padding: 6px 0 6px 0
}

.single-article-shelf .toggle-app .btnwrapper .appbtn {
    padding: 15px 20px;
    max-width: 292px;
    min-width: 240px;
    color: #fff;
    border-width: 0;
    line-height: 30px;
    background-color: #1d7b8a
}

.single-article-shelf .toggle-app .btnwrapper .appbtn span.btntext {
    max-height: inherit
}

.single-article-shelf .toggle-app .btnwrapper .appbtn.selected {
    background-color: #1d7b8a
}

.single-article-shelf .toggle-app .btnwrapper .appbtn:after {
    content: '';
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/cta-hover-nw.png");
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: height .15s ease-out, bottom .15s ease-out;
    -moz-transition: height .15s ease-out, bottom .15s ease-out;
    -ms-transition: height .15s ease-out, bottom .15s ease-out;
    transition: height .15s ease-out, bottom .15s ease-out
}

.single-article-shelf .toggle-app .btnwrapper .appbtn:hover:after,
.single-article-shelf .toggle-app .btnwrapper .appbtn:focus:after {
    height: 8px;
    bottom: -8px
}

.single-article-shelf .toggle-app .btnwrapper .appbtn:focus span.buttonfocus {
    bottom: -14px
}

.msedge .single-article-shelf .toggle-app .btnwrapper .appbtn:focus span.buttonfocus,
.msie .single-article-shelf .toggle-app .btnwrapper .appbtn:focus span.buttonfocus {
    outline: 1px dotted #001a72
}

html:not(.firefox) .single-article-shelf .toggle-app .btnwrapper .appbtn:focus span.buttonfocus {
    outline: 1px auto #001a72
}

.single-article-shelf .additional-rte-wrapper {
    margin-top: 20px
}

.single-article-shelf .cta-wrapper .cta.cta-primary {
    vertical-align: bottom
}

.single-article-shelf .app-store-links {
    margin-top: 35px;
    overflow: hidden
}

.single-article-shelf .app-store-links__item {
    float: left
}

.single-article-shelf .app-store-links__item--ios {
    margin-right: 22px
}

.single-article-shelf .app-store-links__item--ios .comp-img-generic,
.single-article-shelf .app-store-links__item--ios .comp-svg-generic {
    width: 179.5px
}

.single-article-shelf .app-store-links__item--ios .comp-img-generic .svg-image-wrap,
.single-article-shelf .app-store-links__item--ios .comp-svg-generic .svg-image-wrap {
    width: 179.5px;
    text-align: center
}

.single-article-shelf .app-store-links__item--android .comp-img-generic,
.single-article-shelf .app-store-links__item--android .comp-svg-generic {
    width: 202px
}

.single-article-shelf .app-store-links__item--android .comp-img-generic .svg-image-wrap,
.single-article-shelf .app-store-links__item--android .comp-svg-generic .svg-image-wrap {
    width: 202px;
    text-align: center
}

.single-article-shelf .app-store-links__item--ios .comp-img-generic,
.single-article-shelf .app-store-links__item--ios .comp-svg-generic,
.single-article-shelf .app-store-links__item--android .comp-img-generic,
.single-article-shelf .app-store-links__item--android .comp-svg-generic {
    height: 60px
}

.single-article-shelf .app-store-links__item--ios .comp-img-generic .svg-image-wrap,
.single-article-shelf .app-store-links__item--ios .comp-svg-generic .svg-image-wrap,
.single-article-shelf .app-store-links__item--android .comp-img-generic .svg-image-wrap,
.single-article-shelf .app-store-links__item--android .comp-svg-generic .svg-image-wrap {
    height: 60px;
    text-align: center;
    padding: calc(60px*0.12)
}

.msie .single-article-shelf .app-store-links__item--ios img,
.msedge .single-article-shelf .app-store-links__item--ios img,
.msie .single-article-shelf .app-store-links__item--android img,
.msedge .single-article-shelf .app-store-links__item--android img {
    max-width: 100%
}

.single-article-shelf .app-store-links__item--ios svg,
.single-article-shelf .app-store-links__item--android svg {
    width: auto;
    height: 100%
}

.single-article-shelf .app-store-links__item--ios svg.wider-flag,
.single-article-shelf .app-store-links__item--android svg.wider-flag {
    width: 100%;
    height: 100%
}

.single-article-shelf .app-store-links.hide-desktop-image {
    display: block
}

.single-article-shelf .app-store-links.hide-mobile-image {
    display: none
}

.single-article-shelf .image-wrapper {
    height: 206px
}

.single-article-shelf .image-wrapper .comp-svg-generic .svg-image-wrap {
    height: 206px;
    padding: calc(206px*0.12);
    text-align: center
}

.single-article-shelf.sixty-forty .image-wrapper {
    width: 335px
}

.single-article-shelf.sixty-forty .image-wrapper .comp-svg-generic .svg-image-wrap {
    width: 335px;
    text-align: center
}

.single-article-shelf .image-wrapper {
    width: 445px;
    height: 275px;
    overflow: hidden;
    float: right;
    position: relative
}

.single-article-shelf .image-wrapper .rbsTwill {
    width: 100%;
    display: none;
    height: 10px;
    position: absolute;
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/twill-rbos-pattern.svg")
}

.single-article-shelf .image-wrapper .comp-img-generic,
.single-article-shelf .image-wrapper .comp-svg-generic {
    width: 100%;
    height: 100%
}

.single-article-shelf .image-wrapper .comp-img-generic .svg-image-wrap,
.single-article-shelf .image-wrapper .comp-svg-generic .svg-image-wrap {
    width: 445px;
    height: 275px;
    text-align: center;
    padding: calc(275px*0.12)
}

.msie .single-article-shelf .image-wrapper img,
.msedge .single-article-shelf .image-wrapper img {
    max-width: 100%;
    max-height: 100%
}

.single-article-shelf .image-wrapper svg {
    width: auto;
    height: 100%
}

.single-article-shelf .image-wrapper svg.wider-flag {
    width: 100%;
    height: 100%
}

.single-article-shelf .image-wrapper.hide-desktop-image {
    display: none
}

.single-article-shelf .image-wrapper.hide-mobile-image {
    display: block
}

.cq-wcm-edit .single-article-shelf .toggle-app .btnwrapper .appbtn,
.aem-AuthorLayer-Edit .single-article-shelf .toggle-app .btnwrapper .appbtn {
    height: auto
}

.cq-wcm-edit .single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn,
.cq-wcm-edit .single-article-shelf.theme-aqua .toggle-app .btnwrapper .appbtn,
.aem-AuthorLayer-Edit .single-article-shelf.theme-rose .toggle-app .btnwrapper .appbtn,
.aem-AuthorLayer-Edit .single-article-shelf.theme-aqua .toggle-app .btnwrapper .appbtn {
    background-color: #001a72
}

.cq-wcm-edit .single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn,
.aem-AuthorLayer-Edit .single-article-shelf.theme--midnight-blue .toggle-app .btnwrapper .appbtn {
    background-color: #fff
}

.cq-wcm-edit .single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn,
.aem-AuthorLayer-Edit .single-article-shelf.theme--light-grey .toggle-app .btnwrapper .appbtn {
    background-color: #1d7b8a
}

.cq-wcm-edit .single-article-shelf .toggle-app .btnwrapper,
.aem-AuthorLayer-Edit .single-article-shelf .toggle-app .btnwrapper {
    margin-top: 35px
}

.cq-wcm-edit .single-article-shelf .webchatCta,
.aem-AuthorLayer-Edit .single-article-shelf .webchatCta {
    margin-top: 20px
}

.single-article-shelf div#lpButtonDiv-chat-singleteaser-article {
    margin-top: 29px;
    display: inline-block
}

.single-article-shelf div#lpButtonDiv-chat-singleteaser-article .LPMcontainer .cta-wrapper .cta.cta-primary {
    margin-top: 0
}

html:not(.firefox) .single-article-shelf div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus {
    outline: 1px auto #001a72
}

.single-article-shelf div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus .cta-wrapper .cta.cta-primary span.cta-text:after {
    height: 8px;
    bottom: -8px
}

html:not(.firefox) .single-article-shelf.theme-rose div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus,
html:not(.firefox) .single-article-shelf.theme-aqua div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus,
html:not(.firefox) .single-article-shelf.theme--violet div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus,
html:not(.firefox) .single-article-shelf.theme--heather div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus,
html:not(.firefox) .single-article-shelf.theme-blue div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus,
html:not(.firefox) .single-article-shelf.theme--royal-blue div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus,
html:not(.firefox) .single-article-shelf.theme-purple div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus,
html:not(.firefox) .single-article-shelf.theme-berry div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus,
html:not(.firefox) .single-article-shelf.theme-gold div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus {
    outline: 1px auto #fff
}

.single-article-shelf.theme-rose div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:hover .cta.cta-primary .cta-text:after,
.single-article-shelf.theme-rose div#lpButtonDiv-chat-singleteaser-article .LPMcontainer:focus .cta.cta-primary .cta-text:after {
    background: url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_base/resources/images/cta-hover-nw.png");
    background-size: cover
}

@media(min-width:1024px) and (max-width:1255px) {
    .single-article-shelf.sixty-forty .image-wrapper {
        width: 265px
    }

    .single-article-shelf.sixty-forty .comp-svg-generic .svg-image-wrap {
        width: 265px;
        text-align: center
    }

    .single-article-shelf .cta-horizontal-alignment .toggle-app.apptogglebtn1 .btnwrapper {
        width: 40%;
        margin-right: 0
    }

    .single-article-shelf.acc-enabled .accordion-comp .panel-group {
        padding-top: 16px
    }

    .single-article-shelf .image-wrapper {
        width: 360px;
        height: 223px;
        overflow: hidden
    }

    .single-article-shelf .image-wrapper .comp-svg-generic .svg-image-wrap {
        width: 360px;
        height: 223px;
        text-align: center;
        padding: calc(223px*0.12)
    }

    .single-article-shelf .app-store-links__item--ios {
        margin-right: 21px
    }

    .single-article-shelf .title-wrapper {
        margin-bottom: 0
    }

    .single-article-shelf .appstore-android-margin-mobile .app-store-links .app-store-links__item--android .comp-img-generic {
        margin-top: 15px
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .single-article-shelf {
        padding-top: 30px;
        padding-bottom: 50px
    }

    .single-article-shelf .mobileapp-left {
        float: right;
        margin-right: 0
    }

    .single-article-shelf.theme-rose,
    .single-article-shelf.theme--light-grey,
    .single-article-shelf.theme--heather,
    .single-article-shelf.theme-berry,
    .single-article-shelf.theme--violet,
    .single-article-shelf.theme-purple,
    .single-article-shelf.theme-aqua,
    .single-article-shelf.theme--midnight-blue,
    .single-article-shelf.theme--royal-blue,
    .single-article-shelf.theme-blue,
    .single-article-shelf.theme-gold,
    .single-article-shelf.theme--navy {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .single-article-shelf.sixty-forty .image-wrapper {
        width: 202px
    }

    .single-article-shelf.sixty-forty .image-wrapper .comp-svg-generic .svg-image-wrap {
        width: 202px;
        text-align: center
    }

    .single-article-shelf.sixty-forty .image-wrapper.hide-desktop-image {
        display: block
    }

    .single-article-shelf.sixty-forty .image-wrapper.hide-mobile-image {
        display: none
    }

    .single-article-shelf.acc-enabled .accordion-comp .panel-group {
        padding-top: 17px
    }

    .single-article-shelf .image-wrapper {
        width: 265px;
        height: 161px;
        overflow: hidden;
        text-align: center
    }

    .single-article-shelf .image-wrapper .comp-svg-generic .svg-image-wrap {
        width: 265px;
        height: 161px;
        text-align: center;
        padding: calc(161px*0.12)
    }

    .single-article-shelf .image-wrapper.hide-desktop-image {
        display: block
    }

    .single-article-shelf .image-wrapper.hide-mobile-image {
        display: none
    }

    .single-article-shelf .app-store-links {
        margin-top: 30px
    }

    .single-article-shelf .app-store-links__item--ios {
        margin-right: 21px
    }

    .single-article-shelf .title-wrapper {
        margin-bottom: 0
    }

    .single-article-shelf.theme-white .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-white .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-grey .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-grey .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--light-grey .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--light-grey .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--navy .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-rose .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-blue .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-aqua .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--heather .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-purple .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--violet .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-berry .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-gold .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-gold .additional-cta-wrapper .cta.cta-primary {
        margin-top: 30px
    }

    .single-article-shelf.theme-white .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-grey .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--light-grey .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--navy .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-rose .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-blue .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-aqua .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--heather .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--royal-blue .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-purple .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--violet .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-berry .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-gold .cta-horizontal-alignment .cta-wrapper .cta.cta-primary {
        margin-top: 30px
    }

    .single-article-shelf div#lpButtonDiv-chat-singleteaser-article {
        margin-top: 24px
    }

    .single-article-shelf .appstore-android-margin-desktop .app-store-links .app-store-links__item--android .comp-img-generic {
        margin-top: 15px
    }
}

@media(max-width:767px) {
    .single-article-shelf {
        padding-top: 30px;
        padding-bottom: 50px
    }

    .single-article-shelf .cta-horizontal-alignment .additional-cta-wrapper,
    .single-article-shelf .cta-horizontal-alignment .cta-primary-two,
    .single-article-shelf .cta-horizontal-alignment .toggle-app.clearfix:before,
    .single-article-shelf .cta-horizontal-alignment .toggle-app.clearfix:after {
        display: block
    }

    .single-article-shelf .cta-horizontal-alignment .additional-cta-wrapper {
        float: none
    }

    .single-article-shelf .cta-horizontal-alignment .toggle-app.apptogglebtn1 .btnwrapper {
        width: 56%
    }

    .single-article-shelf.acc-enabled .accordion-comp .panel-group {
        padding-top: 17px
    }

    .single-article-shelf.acc-enabled .accordion-comp .panel-group .panel .panel-body {
        padding-top: 13px !important
    }

    .single-article-shelf.theme-rose,
    .single-article-shelf.theme--light-grey,
    .single-article-shelf.theme--heather,
    .single-article-shelf.theme--violet,
    .single-article-shelf.theme-purple,
    .single-article-shelf.theme-berry,
    .single-article-shelf.theme-aqua,
    .single-article-shelf.theme--midnight-blue,
    .single-article-shelf.theme--royal-blue,
    .single-article-shelf.theme-blue,
    .single-article-shelf.theme-gold,
    .single-article-shelf.theme--navy {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .single-article-shelf .image-wrapper {
        width: 335px;
        height: 206px;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        float: none;
        margin-bottom: 20px;
        display: block
    }

    .iphone .single-article-shelf .image-wrapper {
        max-width: 100%
    }

    .single-article-shelf .image-wrapper .comp-svg-generic .svg-image-wrap {
        width: 335px;
        height: 206px;
        text-align: center;
        padding: calc(206px*0.12)
    }

    .single-article-shelf .image-wrapper.hide-desktop-image {
        display: block
    }

    .single-article-shelf .image-wrapper.hide-mobile-image {
        display: none
    }

    .single-article-shelf .app-store-links {
        margin-top: 25px
    }

    .single-article-shelf .app-store-links__item {
        float: none
    }

    .single-article-shelf .app-store-links__item--ios {
        overflow: hidden
    }

    .single-article-shelf .app-store-links__item--android .comp-img-generic,
    .single-article-shelf .app-store-links__item--android .comp-svg-generic {
        margin-top: 15px
    }

    .single-article-shelf .app-store-links.hide-desktop-image {
        display: none
    }

    .single-article-shelf .app-store-links.hide-mobile-image {
        display: block
    }

    .single-article-shelf.theme-white .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-white .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-grey .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-grey .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--light-grey .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--light-grey .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-rose .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-rose .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-blue .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-blue .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-aqua .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-aqua .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--midnight-blue .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--midnight-blue .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--heather .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--heather .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--royal-blue .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--royal-blue .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--violet .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--violet .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-purple .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-purple .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-berry .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-berry .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-gold .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-gold .additional-cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--navy .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--navy .additional-cta-wrapper .cta.cta-primary {
        margin-top: 30px
    }

    .single-article-shelf.theme-white .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-grey .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--light-grey .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-rose .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-blue .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-aqua .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--midnight-blue .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--heather .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--royal-blue .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--violet .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-purple .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-berry .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme-gold .cta-horizontal-alignment .cta-wrapper .cta.cta-primary,
    .single-article-shelf.theme--navy .cta-horizontal-alignment .cta-wrapper .cta.cta-primary {
        margin-top: 30px
    }

    .single-article-shelf div#lpButtonDiv-chat-singleteaser-article {
        margin-top: 24px
    }
}

@media(min-width:768px) {

    .app-store-links__item--ios,
    .app-store-links__item--android {
        float: left
    }
}

@media(max-width:374px) {
    .single-article-shelf .image-wrapper {
        width: 100%
    }

    .single-article-shelf.sixty-forty .image-wrapper {
        width: 100%
    }
}