@import '../../variables';
@import "../../scss/colors.scss";

.field-error {
  position: relative;

  .arrow-up {
    position: absolute;
    top: 0;
    left: 16px;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;    
    border-bottom: 10px solid #cf223f;
  }
  .arrow-up-inner {
    position: absolute;
    top: 3px;
    left: 17px;
    width: 0; 
    height: 0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;    
    border-bottom: 8px solid #f7eaeb;
    // border-bottom: 8px solid rgba(207, 34, 63, 0.1);
    // border-bottom-color: rgba(207, 34, 63, 0.1);
  }

  span.errorMsg { 
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    color: $color-heading-grey;
    padding-top: 6px;
    border-top: 1px solid transparent;

    .errorIcon {
      border: 0;
      vertical-align: middle;
      height: 24px;
      padding-right: 16px;
    }
  }
}