/// #######################
/// Base colors
/// #######################
$color-regal-purple: rgb(60, 16, 83);
$color-champion-purple: rgb(90, 40, 125);
$color-bright-purple: rgb(94, 16, 177);
$color-secondary-purple: rgb(139, 63, 178);

$color-black: rgb(0, 0, 0);
$color-heading-grey: rgb(51, 51, 51);
$color-body-grey: rgb(100, 96, 104);
$color-light-grey: rgb(204, 207, 208);
$color-ground-grey: rgb(242, 242, 248);
$color-white: rgb(255, 255, 255);

$color-tangerine: rgb(219, 55, 0);
$color-lilac: rgb(242, 234, 249);
$color-blush: rgb(242, 234, 249);
$color-sand: #FFEAE6;
$color-mist: #DBEDFA;

$color-confirmation-green: #ECF4EC;
$color-warning-yellow: #FEF8E5;
$color-error-red: #FAE8E8;
$color-info-grey: #F5F5F5;


/// #######################
/// Color usage
/// #######################
$color-primary: $color-bright-purple;

$color-bg: $color-champion-purple;
$color-bg-light: $color-ground-grey;
$color-bullet-dot: $color-bright-purple;

$color-border: $color-light-grey;
$color-border-focus: transparent;
$color-border-alternative: $color-body-grey;

$color-copy: $color-body-grey;
$color-copy-title: $color-heading-grey;
$color-copy-subtitle: $color-body-grey;
$color-copy-label: $color-body-grey;

$color-btn-text: $color-white;

$color-input: $color-black;

$color-link: $color-bright-purple;