@import "../../mixins";
@import "../../variables";

.EL {
  box-sizing: border-box;
  display: block;
  text-align: left;

  @include lg {
    &__flex {
      display: flex;
      justify-content: space-between;
    }
  }

  &__wrapper {
    max-width: 1164px;
    margin: 0 auto;

    @include lg {
      padding: 0px 20px;
    }
  }

  &__heading {
    padding: 30px 20px;
    width: 100%;
    background: linear-gradient(to left, #1e1532, #422e5c);
    box-sizing: border-box;

    @include lg {
      padding: 30px 0px;
    }

    &__title > h1 {
      font-size: 34px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 12px;
      text-align: left;

      @include lg {
        font-size: 48px;
      }
    }
  }

  &__content {
    padding: 30px 20px;
    box-sizing: border-box;

    @include lg {
      padding: 50px 0px;
    }

    &__title {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #333333;
      margin-bottom: 30px;
      text-align: left;

      &--large {
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #2c1f42;
        margin-bottom: 20px;
      }
    }

    &__divider {
      height: 1px;
      display: block;
      margin: 30px 0;
      background: #d1d1d1;
    }

    &__checklist {
      margin-bottom: 30px;
      list-style-type: none;

      @include lg {
        &__wrapper {
          width: 60%;
          max-width: 649px;
          display: block;
          box-sizing: border-box;
          padding-right: 50px;
          margin-bottom: 0;
        }
      }

      & > li {
        display: block;
        padding-left: 45px;
        position: relative;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: #333333;
        margin-bottom: 15px;

        @include lg {
          margin-bottom: 25px;
        }

        & > strong {
          font-family: $primary-font-bold;
        }

        & > a {
          color: #288090;
        }

        & > span {
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          left: 0;
          top: 0;
          background: #001e69;
          border-radius: 50%;
          line-height: 24px;
          text-align: center;
          color: white;
          font-size: 16px;
        }

        & > svg {
          position: absolute;
          left: 0;
          top: 0;
          height: 26px;
          width: 27px;
          object-fit: contain;
        }
      }
    }
  }
  &__Calculator__output {
    width: 100%;
    height: 100%;
    display: block;
    padding: 32px;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.03);
    border: solid 1px #eae9e9;
    background-color: #ffffff;

    @include lg {
      width: 40%;
      min-width: 363px;
    }
    h2 {
      font-size: 24px;
      font-family: $primary-font-bold;
      line-height: 1.33;
      letter-spacing: normal;
      color: #333333;
      margin: 30px 0px 20px 0px;
    }

    @include lg {
      width: 40%;
      height: 100%;
      padding-left: 32px;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;

      h2 {
        font-size: 26px;
        line-height: 1.31;
        margin: 0px 0px 20px 0px;
      }
    }

    &_single {
      & .CO {
        &__label {
          font-size: 18px;
          line-height: 1.44;
          letter-spacing: 0.2px;
          color: #333333;
          display: block;
          margin-bottom: 6px;
        }
        &__value {
          font-size: 24px;
          font-family: $primary-font-bold;
          line-height: 1.33;
          letter-spacing: normal;
          color: #001e69;
          display: block;
          margin-bottom: 10px;

          & > span {
            font-size: 18px;
            font-family: $primary-font-bold;
            line-height: 1.44;
            letter-spacing: 0.2px;
            color: #001e69;
          }
        }
      }
    }
    &_info {
      font-size: 16px;
      line-height: 1.5;
      color: #666666;
      margin-top: 26px;
    }
  }

  &__bottom {
    padding: 20px 16px;
    max-width: 1164px;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;

    @include lg {
      padding: 32px 0px;
      &__wrapper {
        padding: 0px 20px;
        background-color: #f5f5f5;
      }
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #333333;
      margin-bottom: 30px;
      display: block;

      & > strong {
        font-family: $primary-font-bold;
      }
    }

    a {
      color: #288090;
      display: block;
      &:first-of-type {
        margin-bottom: 30px;
      }
    }
  }

  &__btn {
    background-color: #288090;
    border: solid 1px #288090;
    display: block;
    height: 56px;
    font-size: 18px;
    line-height: 56px;
    text-align: center;
    margin-top: 20px;
    color: white !important;
    text-decoration: none;

    @include lg {
      width: 292px;

      &__item {
        margin-top: 50px;
        padding-left: 0 !important;
      }
    }
  }

  &__desktop {
    display: none;
    @include lg {
      display: block;
    }
  }

  &__mobile {
    @include lg {
      display: none;
    }
  }

  &__edit__link {
    color: #288090;
    font-size: 16px;
    margin-bottom: 6px;
    text-decoration: underline;
    & > svg {
      height: 12px;
      margin-right: 2px;
      object-fit: contain;
      vertical-align: middle;
    }
  }
  &__vertical__line {
    border-left: 1px solid #288090;
    height: 21px;
    margin-left: 8px;
    margin-right: 8px;
  }

  &__close__link {
    color: #333333;
    font-size: 14px;
    margin-bottom: 6px;
    text-decoration: underline;
    & > svg {
      height: 12px;
      margin-right: 2px;
      object-fit: contain;
      vertical-align: middle;
    }
  }

  &__edit__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  &__edit__button__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .prefixed-input input{
    padding-left: 24px;
  }

  input,
  .CI__Wrap input {
    height: 54px;
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: 0.2px;
    background-color: #f5f5f5;
    color: #333333;
    border: none;
    padding: 14px 18px;
    box-sizing: border-box;
    border-radius: 0px;
    -webkit-appearance: none;
    border-bottom: 6px solid white;
    outline: none;

    &.touched {
      border-bottom: 6px solid #fbba20;
    }

    &.error {
      border-bottom: 6px solid #e60303;
    }

    &.success {
      border-bottom: 6px solid #429448;
    }

    &.CI__full {
      width: 100%;
      position: relative;
      padding: 14px 18px 14px 18px;
      // border: 1px solid transparent;
    }

    @include lg {
      width: 149px;
      margin-right: 17px;
      &.CI__full {
        width: 149px;
      }
    }
  }
  .CI__Wrap input.disabled {
    pointer-events: none;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    color: #c0c0c0;
  }

  & .CI__full__wrap {
    position: relative;
    &::before {
      content: "£";
      font-family: "RN House Sans Bold";
      font-size: 16px;
      position: absolute;
      left: 10px;
      top: 13px;
      height: 31px;
      z-index: 10;
    }
  }

  & .CI__Wrap {
    display: flex;
    justify-content: flex-start;

    & > label {
      margin-right: 10px;
      position: relative;
      flex: 1;

      @include lg {
        max-width: 149px;
        position: relative;
      }

      & > .prepend {
        pointer-events: none;
        position: absolute;
        left: 40px;
        height: 25px;
        top: 14px;
        margin: auto;
        z-index: 10;

        &.padding {
          left: 45px;
        }
      }
      &.disabled > span {
        // top: 35px;
        color: #c0c0c0;
      }
    }

    @include lg {
      justify-content: left;
    }
  }
}
