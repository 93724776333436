/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @import "../scss/colors.scss";

*,
*::before,
*::after {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  font-size: 10px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline
}

body {
  margin: 0
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 21px;
  margin-bottom: 21px;
  border: 0;
  border-top: 1px solid #eee
}

[role="button"] {
  cursor: pointer
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem
}

p {
  margin: 0 0 10px
}

a {
  color: $color-bright-purple;
  text-decoration: none
}

a:hover,
a:focus {
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0
}

dt {
  font-weight: 700
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

blockquote {
  margin: 0 0 1rem
}

b,
strong {
  font-weight: 700
}

small {
  font-size: 80%
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

a:active,
html:not(.msie):not(.msedge):not(.firefox) a:hover {
  outline: 0
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto
}

figure {
  margin: 0
}

img {
  border: 0;
  vertical-align: middle
}

svg {
  overflow: hidden
}

svg:not(:root) {
  overflow: hidden
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom
}

th,
td {
  text-align: inherit;
  padding: 0
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font: inherit;
  line-height: inherit
}

button {
  overflow: visible
}

button,
select {
  text-transform: none
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border: 0
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox
}

textarea {
  overflow: auto;
  resize: vertical
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}

legend {
  border: 0;
  padding: 0
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto
}

[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

output {
  display: inline-block
}

[hidden],
template {
  display: none
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit
}

h1,
.h1 {
  font-size: 2.5rem
}

h2,
.h2 {
  font-size: 2rem
}

h3,
.h3 {
  font-size: 1.75rem
}

h4,
.h4 {
  font-size: 1.5rem
}

h5,
.h5 {
  font-size: 1.25rem
}

h6,
.h6 {
  font-size: 1rem
}

.lead {
  font-size: 1.25rem;
  font-weight: 300
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1)
}

small,
.small {
  font-size: 80%;
  font-weight: 400
}

mark,
.mark {
  padding: .2em;
  background-color: #fcf8e3
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none
}

.list-inline-item {
  display: inline-block
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem
}

.initialism {
  font-size: 90%;
  text-transform: uppercase
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d
}

.blockquote-footer::before {
  content: "\2014\00A0"
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto
}

.figure {
  display: inline-block
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1
}

.figure-caption {
  font-size: 90%;
  color: #6c757d
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word
}

a>code {
  color: inherit
}

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px
}

.container:before,
.container:after {
  content: " ";
  display: table
}

.container:after {
  clear: both
}

@media(min-width:768px) and (max-width:1023px) {
  .container {
    width: 720px
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .container {
    width: 956px
  }
}

@media(min-width:1256px) {
  .container {
    width: 956px
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 24px;
    padding-right: 24px
  }

  .container:before,
  .container:after {
    content: " ";
    display: table
  }

  .container:after {
    clear: both
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 30px;
    padding-right: 30px
  }

  .container:before,
  .container:after {
    content: " ";
    display: table
  }

  .container:after {
    clear: both
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 8px
}

.container-fluid:before,
.container-fluid:after {
  content: " ";
  display: table
}

.container-fluid:after {
  clear: both
}

@media(min-width:768px) and (max-width:1023px) {
  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 12px;
    padding-right: 12px
  }

  .container-fluid:before,
  .container-fluid:after {
    content: " ";
    display: table
  }

  .container-fluid:after {
    clear: both
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
  }

  .container-fluid:before,
  .container-fluid:after {
    content: " ";
    display: table
  }

  .container-fluid:after {
    clear: both
  }
}

.row {
  margin-left: -8px;
  margin-right: -8px
}

.row:before,
.row:after {
  content: " ";
  display: table
}

.row:after {
  clear: both
}

@media(min-width:768px) {
  .row {
    margin-left: -12px;
    margin-right: -12px
  }

  .row:before,
  .row:after {
    content: " ";
    display: table
  }

  .row:after {
    clear: both
  }
}

@media(min-width:1024px) {
  .row {
    margin-left: -15px;
    margin-right: -15px
  }

  .row:before,
  .row:after {
    content: " ";
    display: table
  }

  .row:after {
    clear: both
  }
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px
}

@media(min-width:768px) and (max-width:1023px) {

  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 12px;
    padding-right: 12px
  }
}

@media(min-width:1024px) and (max-width:1255px) {

  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
  }
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left
}

.col-xs-1 {
  width: 8.33333%
}

.col-xs-2 {
  width: 16.66667%
}

.col-xs-3 {
  width: 25%
}

.col-xs-4 {
  width: 33.33333%
}

.col-xs-5 {
  width: 41.66667%
}

.col-xs-6 {
  width: 50%
}

.col-xs-7 {
  width: 58.33333%
}

.col-xs-8 {
  width: 66.66667%
}

.col-xs-9 {
  width: 75%
}

.col-xs-10 {
  width: 83.33333%
}

.col-xs-11 {
  width: 91.66667%
}

.col-xs-12 {
  width: 100%
}

.col-xs-pull-0 {
  right: auto
}

.col-xs-pull-1 {
  right: 8.33333%
}

.col-xs-pull-2 {
  right: 16.66667%
}

.col-xs-pull-3 {
  right: 25%
}

.col-xs-pull-4 {
  right: 33.33333%
}

.col-xs-pull-5 {
  right: 41.66667%
}

.col-xs-pull-6 {
  right: 50%
}

.col-xs-pull-7 {
  right: 58.33333%
}

.col-xs-pull-8 {
  right: 66.66667%
}

.col-xs-pull-9 {
  right: 75%
}

.col-xs-pull-10 {
  right: 83.33333%
}

.col-xs-pull-11 {
  right: 91.66667%
}

.col-xs-pull-12 {
  right: 100%
}

.col-xs-push-0 {
  left: auto
}

.col-xs-push-1 {
  left: 8.33333%
}

.col-xs-push-2 {
  left: 16.66667%
}

.col-xs-push-3 {
  left: 25%
}

.col-xs-push-4 {
  left: 33.33333%
}

.col-xs-push-5 {
  left: 41.66667%
}

.col-xs-push-6 {
  left: 50%
}

.col-xs-push-7 {
  left: 58.33333%
}

.col-xs-push-8 {
  left: 66.66667%
}

.col-xs-push-9 {
  left: 75%
}

.col-xs-push-10 {
  left: 83.33333%
}

.col-xs-push-11 {
  left: 91.66667%
}

.col-xs-push-12 {
  left: 100%
}

.col-xs-offset-0 {
  margin-left: 0
}

.col-xs-offset-1 {
  margin-left: 8.33333%
}

.col-xs-offset-2 {
  margin-left: 16.66667%
}

.col-xs-offset-3 {
  margin-left: 25%
}

.col-xs-offset-4 {
  margin-left: 33.33333%
}

.col-xs-offset-5 {
  margin-left: 41.66667%
}

.col-xs-offset-6 {
  margin-left: 50%
}

.col-xs-offset-7 {
  margin-left: 58.33333%
}

.col-xs-offset-8 {
  margin-left: 66.66667%
}

.col-xs-offset-9 {
  margin-left: 75%
}

.col-xs-offset-10 {
  margin-left: 83.33333%
}

.col-xs-offset-11 {
  margin-left: 91.66667%
}

.col-xs-offset-12 {
  margin-left: 100%
}

@media(min-width:768px) and (max-width:1023px) {

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left
  }

  .col-sm-1 {
    width: 8.33333%
  }

  .col-sm-2 {
    width: 16.66667%
  }

  .col-sm-3 {
    width: 25%
  }

  .col-sm-4 {
    width: 33.33333%
  }

  .col-sm-5 {
    width: 41.66667%
  }

  .col-sm-6 {
    width: 50%
  }

  .col-sm-7 {
    width: 58.33333%
  }

  .col-sm-8 {
    width: 66.66667%
  }

  .col-sm-9 {
    width: 75%
  }

  .col-sm-10 {
    width: 83.33333%
  }

  .col-sm-11 {
    width: 91.66667%
  }

  .col-sm-12 {
    width: 100%
  }

  .col-sm-pull-0 {
    right: auto
  }

  .col-sm-pull-1 {
    right: 8.33333%
  }

  .col-sm-pull-2 {
    right: 16.66667%
  }

  .col-sm-pull-3 {
    right: 25%
  }

  .col-sm-pull-4 {
    right: 33.33333%
  }

  .col-sm-pull-5 {
    right: 41.66667%
  }

  .col-sm-pull-6 {
    right: 50%
  }

  .col-sm-pull-7 {
    right: 58.33333%
  }

  .col-sm-pull-8 {
    right: 66.66667%
  }

  .col-sm-pull-9 {
    right: 75%
  }

  .col-sm-pull-10 {
    right: 83.33333%
  }

  .col-sm-pull-11 {
    right: 91.66667%
  }

  .col-sm-pull-12 {
    right: 100%
  }

  .col-sm-push-0 {
    left: auto
  }

  .col-sm-push-1 {
    left: 8.33333%
  }

  .col-sm-push-2 {
    left: 16.66667%
  }

  .col-sm-push-3 {
    left: 25%
  }

  .col-sm-push-4 {
    left: 33.33333%
  }

  .col-sm-push-5 {
    left: 41.66667%
  }

  .col-sm-push-6 {
    left: 50%
  }

  .col-sm-push-7 {
    left: 58.33333%
  }

  .col-sm-push-8 {
    left: 66.66667%
  }

  .col-sm-push-9 {
    left: 75%
  }

  .col-sm-push-10 {
    left: 83.33333%
  }

  .col-sm-push-11 {
    left: 91.66667%
  }

  .col-sm-push-12 {
    left: 100%
  }

  .col-sm-offset-0 {
    margin-left: 0
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%
  }

  .col-sm-offset-3 {
    margin-left: 25%
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%
  }

  .col-sm-offset-6 {
    margin-left: 50%
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%
  }

  .col-sm-offset-9 {
    margin-left: 75%
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%
  }

  .col-sm-offset-12 {
    margin-left: 100%
  }
}

@media(min-width:1024px) and (max-width:1255px) {

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left
  }

  .col-md-1 {
    width: 8.33333%
  }

  .col-md-2 {
    width: 16.66667%
  }

  .col-md-3 {
    width: 25%
  }

  .col-md-4 {
    width: 33.33333%
  }

  .col-md-5 {
    width: 41.66667%
  }

  .col-md-6 {
    width: 50%
  }

  .col-md-7 {
    width: 58.33333%
  }

  .col-md-8 {
    width: 66.66667%
  }

  .col-md-9 {
    width: 75%
  }

  .col-md-10 {
    width: 83.33333%
  }

  .col-md-11 {
    width: 91.66667%
  }

  .col-md-12 {
    width: 100%
  }

  .col-md-pull-0 {
    right: auto
  }

  .col-md-pull-1 {
    right: 8.33333%
  }

  .col-md-pull-2 {
    right: 16.66667%
  }

  .col-md-pull-3 {
    right: 25%
  }

  .col-md-pull-4 {
    right: 33.33333%
  }

  .col-md-pull-5 {
    right: 41.66667%
  }

  .col-md-pull-6 {
    right: 50%
  }

  .col-md-pull-7 {
    right: 58.33333%
  }

  .col-md-pull-8 {
    right: 66.66667%
  }

  .col-md-pull-9 {
    right: 75%
  }

  .col-md-pull-10 {
    right: 83.33333%
  }

  .col-md-pull-11 {
    right: 91.66667%
  }

  .col-md-pull-12 {
    right: 100%
  }

  .col-md-push-0 {
    left: auto
  }

  .col-md-push-1 {
    left: 8.33333%
  }

  .col-md-push-2 {
    left: 16.66667%
  }

  .col-md-push-3 {
    left: 25%
  }

  .col-md-push-4 {
    left: 33.33333%
  }

  .col-md-push-5 {
    left: 41.66667%
  }

  .col-md-push-6 {
    left: 50%
  }

  .col-md-push-7 {
    left: 58.33333%
  }

  .col-md-push-8 {
    left: 66.66667%
  }

  .col-md-push-9 {
    left: 75%
  }

  .col-md-push-10 {
    left: 83.33333%
  }

  .col-md-push-11 {
    left: 91.66667%
  }

  .col-md-push-12 {
    left: 100%
  }

  .col-md-offset-0 {
    margin-left: 0
  }

  .col-md-offset-1 {
    margin-left: 8.33333%
  }

  .col-md-offset-2 {
    margin-left: 16.66667%
  }

  .col-md-offset-3 {
    margin-left: 25%
  }

  .col-md-offset-4 {
    margin-left: 33.33333%
  }

  .col-md-offset-5 {
    margin-left: 41.66667%
  }

  .col-md-offset-6 {
    margin-left: 50%
  }

  .col-md-offset-7 {
    margin-left: 58.33333%
  }

  .col-md-offset-8 {
    margin-left: 66.66667%
  }

  .col-md-offset-9 {
    margin-left: 75%
  }

  .col-md-offset-10 {
    margin-left: 83.33333%
  }

  .col-md-offset-11 {
    margin-left: 91.66667%
  }

  .col-md-offset-12 {
    margin-left: 100%
  }
}

@media(min-width:1256px) {

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left
  }

  .col-lg-1 {
    width: 8.33333%
  }

  .col-lg-2 {
    width: 16.66667%
  }

  .col-lg-3 {
    width: 25%
  }

  .col-lg-4 {
    width: 33.33333%
  }

  .col-lg-5 {
    width: 41.66667%
  }

  .col-lg-6 {
    width: 50%
  }

  .col-lg-7 {
    width: 58.33333%
  }

  .col-lg-8 {
    width: 66.66667%
  }

  .col-lg-9 {
    width: 75%
  }

  .col-lg-10 {
    width: 83.33333%
  }

  .col-lg-11 {
    width: 91.66667%
  }

  .col-lg-12 {
    width: 100%
  }

  .col-lg-pull-0 {
    right: auto
  }

  .col-lg-pull-1 {
    right: 8.33333%
  }

  .col-lg-pull-2 {
    right: 16.66667%
  }

  .col-lg-pull-3 {
    right: 25%
  }

  .col-lg-pull-4 {
    right: 33.33333%
  }

  .col-lg-pull-5 {
    right: 41.66667%
  }

  .col-lg-pull-6 {
    right: 50%
  }

  .col-lg-pull-7 {
    right: 58.33333%
  }

  .col-lg-pull-8 {
    right: 66.66667%
  }

  .col-lg-pull-9 {
    right: 75%
  }

  .col-lg-pull-10 {
    right: 83.33333%
  }

  .col-lg-pull-11 {
    right: 91.66667%
  }

  .col-lg-pull-12 {
    right: 100%
  }

  .col-lg-push-0 {
    left: auto
  }

  .col-lg-push-1 {
    left: 8.33333%
  }

  .col-lg-push-2 {
    left: 16.66667%
  }

  .col-lg-push-3 {
    left: 25%
  }

  .col-lg-push-4 {
    left: 33.33333%
  }

  .col-lg-push-5 {
    left: 41.66667%
  }

  .col-lg-push-6 {
    left: 50%
  }

  .col-lg-push-7 {
    left: 58.33333%
  }

  .col-lg-push-8 {
    left: 66.66667%
  }

  .col-lg-push-9 {
    left: 75%
  }

  .col-lg-push-10 {
    left: 83.33333%
  }

  .col-lg-push-11 {
    left: 91.66667%
  }

  .col-lg-push-12 {
    left: 100%
  }

  .col-lg-offset-0 {
    margin-left: 0
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%
  }

  .col-lg-offset-3 {
    margin-left: 25%
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%
  }

  .col-lg-offset-6 {
    margin-left: 50%
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%
  }

  .col-lg-offset-9 {
    margin-left: 75%
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%
  }

  .col-lg-offset-12 {
    margin-left: 100%
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent
}

.table th,
.table td {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left
}

th {
  text-align: left
}

.table-sm th,
.table-sm td {
  padding: .3rem
}

.table-bordered {
  border: 1px solid #dee2e6
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
  border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05)
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075)
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #b8daff
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
  border-color: #7abaff
}

.table-hover .table-primary:hover {
  background-color: #9fcdff
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #9fcdff
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #d6d8db
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
  border-color: #b3b7bb
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #c3e6cb
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
  border-color: #8fd19e
}

.table-hover .table-success:hover {
  background-color: #b1dfbb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #bee5eb
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
  border-color: #86cfda
}

.table-hover .table-info:hover {
  background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffeeba
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
  border-color: #ffdf7e
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #f5c6cb
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
  border-color: #ed969e
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7
}

.table-light,
.table-light>th,
.table-light>td {
  background-color: #fdfdfe
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
  border-color: #fbfcfc
}

.table-hover .table-light:hover {
  background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6
}

.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c6c8ca
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
  border-color: #95999c
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075)
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.table-dark {
  color: #fff;
  background-color: #343a40
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55
}

.table-dark.table-bordered {
  border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05)
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075)
}

@media(max-width:575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-sm>.table-bordered {
    border: 0
  }
}

@media(max-width:767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-md>.table-bordered {
    border: 0
  }
}

@media(max-width:991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-lg>.table-bordered {
    border: 0
  }
}

@media(max-width:1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-xl>.table-bordered {
    border: 0
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
  border: 0
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 21px;
  font-size: 1.5rem;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal
}

input[type="file"] {
  display: block
}

input[type="range"] {
  display: block;
  width: 100%
}

select[multiple],
select[size] {
  height: auto
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

input[type="radio"][disabled],
input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

select.form-control[size],
select.form-control[multiple] {
  height: auto
}

textarea.form-control {
  height: auto
}

.form-group {
  margin-bottom: 1rem
}

.form-text {
  display: block;
  margin-top: .25rem
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  color: #6c757d
}

.form-check-label {
  margin-bottom: 0
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: .25rem
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'viewBox='0088'%3e%3cpathfill='%2328a745'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='4'height='5'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px, url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'viewBox='0088'%3e%3cpathfill='%2328a745'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.25)
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #28a745
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
  display: block
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: #28a745
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
  border-color: #28a745
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.25)
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #28a745
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
  border-color: #28a745
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.25)
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: .25rem
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='12'height='12'fill='none'stroke='%23dc3545'viewBox='001212'%3e%3ccirclecx='6'cy='6'r='4.5'/%3e%3cpathstroke-linejoin='round'd='M5.83.6h.4L66.5z'/%3e%3ccirclecx='6'cy='8.2'r='.6'fill='%23dc3545'stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='4'height='5'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px, url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='12'height='12'fill='none'stroke='%23dc3545'viewBox='001212'%3e%3ccirclecx='6'cy='6'r='4.5'/%3e%3cpathstroke-linejoin='round'd='M5.83.6h.4L66.5z'/%3e%3ccirclecx='6'cy='8.2'r='.6'fill='%23dc3545'stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.25)
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #dc3545
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
  display: block
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
  color: #dc3545
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  border-color: #dc3545
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.25)
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: #dc3545
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #dc3545
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.25)
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center
}

.form-inline .form-check {
  width: 100%
}

@media(min-width:576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle
  }

  .form-inline .form-control-plaintext {
    display: inline-block
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center
  }

  .form-inline .custom-control-label {
    margin-bottom: 0
  }
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: none
}

.btn:hover {
  color: #212529;
  text-decoration: none
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.btn.disabled,
.btn:disabled {
  opacity: .65
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, 0.5)
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, 0.5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, 0.5)
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem rgba(72, 180, 97, 0.5)
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(72, 180, 97, 0.5)
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem rgba(58, 176, 195, 0.5)
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(58, 176, 195, 0.5)
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem rgba(222, 170, 12, 0.5)
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(222, 170, 12, 0.5)
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem rgba(225, 83, 97, 0.5)
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(225, 83, 97, 0.5)
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem rgba(216, 217, 219, 0.5)
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(216, 217, 219, 0.5)
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem rgba(82, 88, 93, 0.5)
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(82, 88, 93, 0.5)
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.5)
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, 0.5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, 0.5)
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5)
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5)
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none
}

.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block+.btn-block {
  margin-top: .5rem
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%
}

.fade {
  opacity: 0;
  transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {}

.fade:not(.show) {
  opacity: 0
}

.fade.in {
  opacity: 1
}

.collapse {
  display: none
}

.collapse.in {
  display: block
}

.collapse:not(.show) {
  display: none
}

tr.collapse.in {
  display: table-row
}

tbody.collapse.in {
  display: table-row-group
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

@media(prefers-reduced-motion:reduce) {}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

@media(min-width:576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto
  }
}

@media(min-width:768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto
  }
}

@media(min-width:992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto
  }
}

@media(min-width:1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropright .dropdown-toggle::after {
  vertical-align: 0
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""
}

.dropleft .dropdown-toggle::after {
  display: none
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0
}

.dropdown-item:hover,
.dropdown-item:focus {

  text-decoration: none;
  background-color: $color-lilac;
  border: 1px solid $color-bright-purple;

}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: $color-regal-purple;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent
}

.dropdown-menu.show {
  display: block
}

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap
}

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  flex: 1 1 0;
  min-width: 0;
  margin-bottom: 0
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
  margin-left: -1px
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
  z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.input-group>.custom-file {
  display: flex;
  align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.input-group-prepend,
.input-group-append {
  display: flex
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
  margin-left: -1px
}

.input-group-prepend {
  margin-right: -1px
}

.input-group-append {
  margin-left: -1px
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
  height: calc(1.5em + 1rem + 2px)
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
  height: calc(1.5em + 0.5rem + 2px)
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 1.75rem
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #80bdff
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
  color: #6c757d
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px
}

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%
}

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'viewBox='0088'%3e%3cpathfill='%23fff'd='M6.564.75l-3.593.612-1.538-1.55L04.26l2.9742.99L82.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='4'height='4'viewBox='0044'%3e%3cpathstroke='%23fff'd='M02h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5)
}

.custom-radio .custom-control-label::before {
  border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='12'height='12'viewBox='-4-488'%3e%3ccircler='3'fill='%23fff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5)
}

.custom-switch {
  padding-left: 2.25rem
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5)
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='4'height='5'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  appearance: none
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef
}

.custom-select::-ms-expand {
  display: none
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0
}

.custom-file-input:focus~.custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
  background-color: #e9ecef
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse)
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none
}

.custom-range:focus {
  outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.custom-range::-moz-focus-outer {
  border: 0
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media(prefers-reduced-motion:reduce) {}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media(prefers-reduced-motion:reduce) {}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .2rem;
  margin-left: .2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media(prefers-reduced-motion:reduce) {}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd
}

.custom-range:disabled::-moz-range-track {
  cursor: default
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.nav-link {
  display: block;
  padding: .5rem 1rem
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
  margin-bottom: -1px
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav-pills .nav-link {
  border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center
}

.tab-content>.tab-pane {
  display: none
}

.tab-content>.active {
  display: block
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem
}

.navbar .container,
.navbar .container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%
}

@media(max-width:575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media(min-width:576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl {
    flex-wrap: nowrap
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-sm .navbar-toggler {
    display: none
  }
}

@media(max-width:767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media(min-width:768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl {
    flex-wrap: nowrap
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-md .navbar-toggler {
    display: none
  }
}

@media(max-width:991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media(min-width:992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl {
    flex-wrap: nowrap
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-lg .navbar-toggler {
    display: none
  }
}

@media(max-width:1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media(min-width:1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl {
    flex-wrap: nowrap
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-xl .navbar-toggler {
    display: none
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
  padding-right: 0;
  padding-left: 0
}

.navbar-expand .navbar-nav {
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
  flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto
}

.navbar-expand .navbar-toggler {
  display: none
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7)
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3)
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1)
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='30'height='30'viewBox='003030'%3e%3cpathstroke='rgba(0,0,0,0.5)'stroke-linecap='round'stroke-miterlimit='10'stroke-width='2'd='M47h22M415h22M423h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-dark .navbar-brand {
  color: #fff
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25)
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1)
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'width='30'height='30'viewBox='003030'%3e%3cpathstroke='rgba(255,255,255,0.5)'stroke-linecap='round'stroke-miterlimit='10'stroke-width='2'd='M47h22M415h22M423h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-text a {
  color: #fff
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem
}

.card>hr {
  margin-right: 0;
  margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem
}

.card-title {
  margin-bottom: .75rem
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0
}

.card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link+.card-link {
  margin-left: 1.25rem
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125)
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125)
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px)
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px)
}

.card-deck .card {
  margin-bottom: 15px
}

@media(min-width:576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px
  }

  .card-deck .card {
    flex: 1 0 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px
  }
}

.card-group>.card {
  margin-bottom: 15px
}

@media(min-width:576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap
  }

  .card-group>.card {
    flex: 1 0 0;
    margin-bottom: 0
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0
  }
}

.card-columns .card {
  margin-bottom: .75rem
}

@media(min-width:576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1
  }

  .card-columns .card {
    display: inline-block;
    width: 100%
  }
}

.accordion>.card {
  overflow: hidden
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.accordion>.card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0
  }

  to {
    background-position: 0 0
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width .6s ease
}

@media(prefers-reduced-motion:reduce) {}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite
}

@media(prefers-reduced-motion:reduce) {
  .progress-bar-animated {
    animation: none
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125)
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.list-group-item+.list-group-item {
  border-top-width: 0
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px
}

.list-group-horizontal {
  flex-direction: row
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0
}

.list-group-horizontal .list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0
}

.list-group-horizontal .list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px
}

@media(min-width:576px) {
  .list-group-horizontal-sm {
    flex-direction: row
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-sm .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-sm .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media(min-width:768px) {
  .list-group-horizontal-md {
    flex-direction: row
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-md .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-md .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media(min-width:992px) {
  .list-group-horizontal-lg {
    flex-direction: row
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-lg .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-lg .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media(min-width:1200px) {
  .list-group-horizontal-xl {
    flex-direction: row
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-xl .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-xl .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: .875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: .25rem
}

.toast:not(:last-child) {
  margin-bottom: .75rem
}

.toast.showing {
  opacity: 1
}

.toast.show {
  display: block;
  opacity: 1
}

.toast.hide {
  display: none
}

.toast-header {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.toast-body {
  padding: .75rem
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em
}

@keyframes spinner-grow {
  0 {
    transform: scale(0)
  }

  50% {
    opacity: 1
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.bg-primary {
  background-color: #007bff !important
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important
}

.bg-secondary {
  background-color: #6c757d !important
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important
}

.bg-success {
  background-color: #28a745 !important
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important
}

.bg-info {
  background-color: #17a2b8 !important
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important
}

.bg-warning {
  background-color: #ffc107 !important
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important
}

.bg-danger {
  background-color: #dc3545 !important
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important
}

.bg-light {
  background-color: #f8f9fa !important
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important
}

.bg-dark {
  background-color: #343a40 !important
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important
}

.bg-white {
  background-color: #fff !important
}

.bg-transparent {
  background-color: transparent !important
}

.border-top {
  border-top: 1px solid #dee2e6 !important
}

.border-right {
  border-right: 1px solid #dee2e6 !important
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important
}

.border-left {
  border-left: 1px solid #dee2e6 !important
}

.border-0 {
  border: 0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #007bff !important
}

.border-secondary {
  border-color: #6c757d !important
}

.border-success {
  border-color: #28a745 !important
}

.border-info {
  border-color: #17a2b8 !important
}

.border-warning {
  border-color: #ffc107 !important
}

.border-danger {
  border-color: #dc3545 !important
}

.border-light {
  border-color: #f8f9fa !important
}

.border-dark {
  border-color: #343a40 !important
}

.border-white {
  border-color: #fff !important
}

.rounded-sm {
  border-radius: .2rem !important
}

.rounded {
  border-radius: .25rem !important
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-lg {
  border-radius: .3rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-pill {
  border-radius: 50rem !important
}

.rounded-0 {
  border-radius: 0 !important
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}

.clearfix:after {
  clear: both
}

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: flex !important
}

.d-inline-flex {
  display: inline-flex !important
}

@media(min-width:576px) {
  .d-sm-none {
    display: none !important
  }

  .d-sm-inline {
    display: inline !important
  }

  .d-sm-inline-block {
    display: inline-block !important
  }

  .d-sm-block {
    display: block !important
  }

  .d-sm-table {
    display: table !important
  }

  .d-sm-table-row {
    display: table-row !important
  }

  .d-sm-table-cell {
    display: table-cell !important
  }

  .d-sm-flex {
    display: flex !important
  }

  .d-sm-inline-flex {
    display: inline-flex !important
  }
}

@media(min-width:768px) {
  .d-md-none {
    display: none !important
  }

  .d-md-inline {
    display: inline !important
  }

  .d-md-inline-block {
    display: inline-block !important
  }

  .d-md-block {
    display: block !important
  }

  .d-md-table {
    display: table !important
  }

  .d-md-table-row {
    display: table-row !important
  }

  .d-md-table-cell {
    display: table-cell !important
  }

  .d-md-flex {
    display: flex !important
  }

  .d-md-inline-flex {
    display: inline-flex !important
  }
}

@media(min-width:992px) {
  .d-lg-none {
    display: none !important
  }

  .d-lg-inline {
    display: inline !important
  }

  .d-lg-inline-block {
    display: inline-block !important
  }

  .d-lg-block {
    display: block !important
  }

  .d-lg-table {
    display: table !important
  }

  .d-lg-table-row {
    display: table-row !important
  }

  .d-lg-table-cell {
    display: table-cell !important
  }

  .d-lg-flex {
    display: flex !important
  }

  .d-lg-inline-flex {
    display: inline-flex !important
  }
}

@media(min-width:1200px) {
  .d-xl-none {
    display: none !important
  }

  .d-xl-inline {
    display: inline !important
  }

  .d-xl-inline-block {
    display: inline-block !important
  }

  .d-xl-block {
    display: block !important
  }

  .d-xl-table {
    display: table !important
  }

  .d-xl-table-row {
    display: table-row !important
  }

  .d-xl-table-cell {
    display: table-cell !important
  }

  .d-xl-flex {
    display: flex !important
  }

  .d-xl-inline-flex {
    display: inline-flex !important
  }
}

@media print {
  .d-print-none {
    display: none !important
  }

  .d-print-inline {
    display: inline !important
  }

  .d-print-inline-block {
    display: inline-block !important
  }

  .d-print-block {
    display: block !important
  }

  .d-print-table {
    display: table !important
  }

  .d-print-table-row {
    display: table-row !important
  }

  .d-print-table-cell {
    display: table-cell !important
  }

  .d-print-flex {
    display: flex !important
  }

  .d-print-inline-flex {
    display: inline-flex !important
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden
}

.embed-responsive::before {
  display: block;
  content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%
}

.embed-responsive-16by9::before {
  padding-top: 56.25%
}

.embed-responsive-4by3::before {
  padding-top: 75%
}

.embed-responsive-1by1::before {
  padding-top: 100%
}

.flex-row {
  flex-direction: row !important
}

.flex-column {
  flex-direction: column !important
}

.flex-row-reverse {
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  flex-direction: column-reverse !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-nowrap {
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}

.flex-fill {
  flex: 1 1 auto !important
}

.flex-grow-0 {
  flex-grow: 0 !important
}

.flex-grow-1 {
  flex-grow: 1 !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  flex-shrink: 1 !important
}

.justify-content-start {
  justify-content: flex-start !important
}

.justify-content-end {
  justify-content: flex-end !important
}

.justify-content-center {
  justify-content: center !important
}

.justify-content-between {
  justify-content: space-between !important
}

.justify-content-around {
  justify-content: space-around !important
}

.align-items-start {
  align-items: flex-start !important
}

.align-items-end {
  align-items: flex-end !important
}

.align-items-center {
  align-items: center !important
}

.align-items-baseline {
  align-items: baseline !important
}

.align-items-stretch {
  align-items: stretch !important
}

.align-content-start {
  align-content: flex-start !important
}

.align-content-end {
  align-content: flex-end !important
}

.align-content-center {
  align-content: center !important
}

.align-content-between {
  align-content: space-between !important
}

.align-content-around {
  align-content: space-around !important
}

.align-content-stretch {
  align-content: stretch !important
}

.align-self-auto {
  align-self: auto !important
}

.align-self-start {
  align-self: flex-start !important
}

.align-self-end {
  align-self: flex-end !important
}

.align-self-center {
  align-self: center !important
}

.align-self-baseline {
  align-self: baseline !important
}

.align-self-stretch {
  align-self: stretch !important
}

@media(min-width:576px) {
  .flex-sm-row {
    flex-direction: row !important
  }

  .flex-sm-column {
    flex-direction: column !important
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .flex-sm-fill {
    flex: 1 1 auto !important
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important
  }

  .justify-content-sm-start {
    justify-content: flex-start !important
  }

  .justify-content-sm-end {
    justify-content: flex-end !important
  }

  .justify-content-sm-center {
    justify-content: center !important
  }

  .justify-content-sm-between {
    justify-content: space-between !important
  }

  .justify-content-sm-around {
    justify-content: space-around !important
  }

  .align-items-sm-start {
    align-items: flex-start !important
  }

  .align-items-sm-end {
    align-items: flex-end !important
  }

  .align-items-sm-center {
    align-items: center !important
  }

  .align-items-sm-baseline {
    align-items: baseline !important
  }

  .align-items-sm-stretch {
    align-items: stretch !important
  }

  .align-content-sm-start {
    align-content: flex-start !important
  }

  .align-content-sm-end {
    align-content: flex-end !important
  }

  .align-content-sm-center {
    align-content: center !important
  }

  .align-content-sm-between {
    align-content: space-between !important
  }

  .align-content-sm-around {
    align-content: space-around !important
  }

  .align-content-sm-stretch {
    align-content: stretch !important
  }

  .align-self-sm-auto {
    align-self: auto !important
  }

  .align-self-sm-start {
    align-self: flex-start !important
  }

  .align-self-sm-end {
    align-self: flex-end !important
  }

  .align-self-sm-center {
    align-self: center !important
  }

  .align-self-sm-baseline {
    align-self: baseline !important
  }

  .align-self-sm-stretch {
    align-self: stretch !important
  }
}

@media(min-width:768px) {
  .flex-md-row {
    flex-direction: row !important
  }

  .flex-md-column {
    flex-direction: column !important
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-md-wrap {
    flex-wrap: wrap !important
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .flex-md-fill {
    flex: 1 1 auto !important
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important
  }

  .justify-content-md-start {
    justify-content: flex-start !important
  }

  .justify-content-md-end {
    justify-content: flex-end !important
  }

  .justify-content-md-center {
    justify-content: center !important
  }

  .justify-content-md-between {
    justify-content: space-between !important
  }

  .justify-content-md-around {
    justify-content: space-around !important
  }

  .align-items-md-start {
    align-items: flex-start !important
  }

  .align-items-md-end {
    align-items: flex-end !important
  }

  .align-items-md-center {
    align-items: center !important
  }

  .align-items-md-baseline {
    align-items: baseline !important
  }

  .align-items-md-stretch {
    align-items: stretch !important
  }

  .align-content-md-start {
    align-content: flex-start !important
  }

  .align-content-md-end {
    align-content: flex-end !important
  }

  .align-content-md-center {
    align-content: center !important
  }

  .align-content-md-between {
    align-content: space-between !important
  }

  .align-content-md-around {
    align-content: space-around !important
  }

  .align-content-md-stretch {
    align-content: stretch !important
  }

  .align-self-md-auto {
    align-self: auto !important
  }

  .align-self-md-start {
    align-self: flex-start !important
  }

  .align-self-md-end {
    align-self: flex-end !important
  }

  .align-self-md-center {
    align-self: center !important
  }

  .align-self-md-baseline {
    align-self: baseline !important
  }

  .align-self-md-stretch {
    align-self: stretch !important
  }
}

@media(min-width:992px) {
  .flex-lg-row {
    flex-direction: row !important
  }

  .flex-lg-column {
    flex-direction: column !important
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .flex-lg-fill {
    flex: 1 1 auto !important
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important
  }

  .justify-content-lg-start {
    justify-content: flex-start !important
  }

  .justify-content-lg-end {
    justify-content: flex-end !important
  }

  .justify-content-lg-center {
    justify-content: center !important
  }

  .justify-content-lg-between {
    justify-content: space-between !important
  }

  .justify-content-lg-around {
    justify-content: space-around !important
  }

  .align-items-lg-start {
    align-items: flex-start !important
  }

  .align-items-lg-end {
    align-items: flex-end !important
  }

  .align-items-lg-center {
    align-items: center !important
  }

  .align-items-lg-baseline {
    align-items: baseline !important
  }

  .align-items-lg-stretch {
    align-items: stretch !important
  }

  .align-content-lg-start {
    align-content: flex-start !important
  }

  .align-content-lg-end {
    align-content: flex-end !important
  }

  .align-content-lg-center {
    align-content: center !important
  }

  .align-content-lg-between {
    align-content: space-between !important
  }

  .align-content-lg-around {
    align-content: space-around !important
  }

  .align-content-lg-stretch {
    align-content: stretch !important
  }

  .align-self-lg-auto {
    align-self: auto !important
  }

  .align-self-lg-start {
    align-self: flex-start !important
  }

  .align-self-lg-end {
    align-self: flex-end !important
  }

  .align-self-lg-center {
    align-self: center !important
  }

  .align-self-lg-baseline {
    align-self: baseline !important
  }

  .align-self-lg-stretch {
    align-self: stretch !important
  }
}

@media(min-width:1200px) {
  .flex-xl-row {
    flex-direction: row !important
  }

  .flex-xl-column {
    flex-direction: column !important
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .flex-xl-fill {
    flex: 1 1 auto !important
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important
  }

  .justify-content-xl-start {
    justify-content: flex-start !important
  }

  .justify-content-xl-end {
    justify-content: flex-end !important
  }

  .justify-content-xl-center {
    justify-content: center !important
  }

  .justify-content-xl-between {
    justify-content: space-between !important
  }

  .justify-content-xl-around {
    justify-content: space-around !important
  }

  .align-items-xl-start {
    align-items: flex-start !important
  }

  .align-items-xl-end {
    align-items: flex-end !important
  }

  .align-items-xl-center {
    align-items: center !important
  }

  .align-items-xl-baseline {
    align-items: baseline !important
  }

  .align-items-xl-stretch {
    align-items: stretch !important
  }

  .align-content-xl-start {
    align-content: flex-start !important
  }

  .align-content-xl-end {
    align-content: flex-end !important
  }

  .align-content-xl-center {
    align-content: center !important
  }

  .align-content-xl-between {
    align-content: space-between !important
  }

  .align-content-xl-around {
    align-content: space-around !important
  }

  .align-content-xl-stretch {
    align-content: stretch !important
  }

  .align-self-xl-auto {
    align-self: auto !important
  }

  .align-self-xl-start {
    align-self: flex-start !important
  }

  .align-self-xl-end {
    align-self: flex-end !important
  }

  .align-self-xl-center {
    align-self: center !important
  }

  .align-self-xl-baseline {
    align-self: baseline !important
  }

  .align-self-xl-stretch {
    align-self: stretch !important
  }
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.float-none {
  float: none !important
}

@media(min-width:576px) {
  .float-sm-left {
    float: left !important
  }

  .float-sm-right {
    float: right !important
  }

  .float-sm-none {
    float: none !important
  }
}

@media(min-width:768px) {
  .float-md-left {
    float: left !important
  }

  .float-md-right {
    float: right !important
  }

  .float-md-none {
    float: none !important
  }
}

@media(min-width:992px) {
  .float-lg-left {
    float: left !important
  }

  .float-lg-right {
    float: right !important
  }

  .float-lg-none {
    float: none !important
  }
}

@media(min-width:1200px) {
  .float-xl-left {
    float: left !important
  }

  .float-xl-right {
    float: right !important
  }

  .float-xl-none {
    float: none !important
  }
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: sticky !important
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}

@supports(position:sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.075) !important
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15) !important
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important
}

.shadow-none {
  box-shadow: none !important
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.w-auto {
  width: auto !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.h-auto {
  height: auto !important
}

.mw-100 {
  max-width: 100% !important
}

.mh-100 {
  max-height: 100% !important
}

.min-vw-100 {
  min-width: 100vw !important
}

.min-vh-100 {
  min-height: 100vh !important
}

.vw-100 {
  width: 100vw !important
}

.vh-100 {
  height: 100vh !important
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent
}

.m-0 {
  margin: 0 !important
}

.mt-0,
.my-0 {
  margin-top: 0 !important
}

.mr-0,
.mx-0 {
  margin-right: 0 !important
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
  margin-left: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.mt-1,
.my-1 {
  margin-top: .25rem !important
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.mt-2,
.my-2 {
  margin-top: .5rem !important
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important
}

.m-3 {
  margin: 1rem !important
}

.mt-3,
.my-3 {
  margin-top: 1rem !important
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important
}

.m-5 {
  margin: 3rem !important
}

.mt-5,
.my-5 {
  margin-top: 3rem !important
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important
}

.p-0 {
  padding: 0 !important
}

.pt-0,
.py-0 {
  padding-top: 0 !important
}

.pr-0,
.px-0 {
  padding-right: 0 !important
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important
}

.pl-0,
.px-0 {
  padding-left: 0 !important
}

.p-1 {
  padding: .25rem !important
}

.pt-1,
.py-1 {
  padding-top: .25rem !important
}

.pr-1,
.px-1 {
  padding-right: .25rem !important
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
  padding-left: .25rem !important
}

.p-2 {
  padding: .5rem !important
}

.pt-2,
.py-2 {
  padding-top: .5rem !important
}

.pr-2,
.px-2 {
  padding-right: .5rem !important
}

.pb-2,
.py-2 {
  padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
  padding-left: .5rem !important
}

.p-3 {
  padding: 1rem !important
}

.pt-3,
.py-3 {
  padding-top: 1rem !important
}

.pr-3,
.px-3 {
  padding-right: 1rem !important
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
  padding-left: 1rem !important
}

.p-4 {
  padding: 1.5rem !important
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important
}

.p-5 {
  padding: 3rem !important
}

.pt-5,
.py-5 {
  padding-top: 3rem !important
}

.pr-5,
.px-5 {
  padding-right: 3rem !important
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
  padding-left: 3rem !important
}

.m-n1 {
  margin: -0.25rem !important
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important
}

.m-n2 {
  margin: -0.5rem !important
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important
}

.m-n3 {
  margin: -1rem !important
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important
}

.m-n4 {
  margin: -1.5rem !important
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important
}

.m-n5 {
  margin: -3rem !important
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important
}

.m-auto {
  margin: auto !important
}

.mt-auto,
.my-auto {
  margin-top: auto !important
}

.mr-auto,
.mx-auto {
  margin-right: auto !important
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
  margin-left: auto !important
}

@media(min-width:576px) {
  .m-sm-0 {
    margin: 0 !important
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important
  }

  .m-sm-1 {
    margin: .25rem !important
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important
  }

  .m-sm-2 {
    margin: .5rem !important
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: .5rem !important
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .5rem !important
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .5rem !important
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .5rem !important
  }

  .m-sm-3 {
    margin: 1rem !important
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important
  }

  .m-sm-4 {
    margin: 1.5rem !important
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important
  }

  .m-sm-5 {
    margin: 3rem !important
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important
  }

  .p-sm-1 {
    padding: .25rem !important
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important
  }

  .p-sm-2 {
    padding: .5rem !important
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: .5rem !important
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: .5rem !important
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .5rem !important
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: .5rem !important
  }

  .p-sm-3 {
    padding: 1rem !important
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important
  }

  .p-sm-4 {
    padding: 1.5rem !important
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important
  }

  .p-sm-5 {
    padding: 3rem !important
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important
  }

  .m-sm-n1 {
    margin: -0.25rem !important
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important
  }

  .m-sm-n2 {
    margin: -0.5rem !important
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important
  }

  .m-sm-n3 {
    margin: -1rem !important
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important
  }

  .m-sm-n4 {
    margin: -1.5rem !important
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important
  }

  .m-sm-n5 {
    margin: -3rem !important
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important
  }

  .m-sm-auto {
    margin: auto !important
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important
  }
}

@media(min-width:768px) {
  .m-md-0 {
    margin: 0 !important
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important
  }

  .m-md-1 {
    margin: .25rem !important
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important
  }

  .m-md-2 {
    margin: .5rem !important
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: .5rem !important
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: .5rem !important
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: .5rem !important
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: .5rem !important
  }

  .m-md-3 {
    margin: 1rem !important
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important
  }

  .m-md-4 {
    margin: 1.5rem !important
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important
  }

  .m-md-5 {
    margin: 3rem !important
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important
  }

  .p-md-1 {
    padding: .25rem !important
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important
  }

  .p-md-2 {
    padding: .5rem !important
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: .5rem !important
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: .5rem !important
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: .5rem !important
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: .5rem !important
  }

  .p-md-3 {
    padding: 1rem !important
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important
  }

  .p-md-4 {
    padding: 1.5rem !important
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important
  }

  .p-md-5 {
    padding: 3rem !important
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important
  }

  .m-md-n1 {
    margin: -0.25rem !important
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important
  }

  .m-md-n2 {
    margin: -0.5rem !important
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important
  }

  .m-md-n3 {
    margin: -1rem !important
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important
  }

  .m-md-n4 {
    margin: -1.5rem !important
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important
  }

  .m-md-n5 {
    margin: -3rem !important
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important
  }

  .m-md-auto {
    margin: auto !important
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important
  }
}

@media(min-width:992px) {
  .m-lg-0 {
    margin: 0 !important
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important
  }

  .m-lg-1 {
    margin: .25rem !important
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important
  }

  .m-lg-2 {
    margin: .5rem !important
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: .5rem !important
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .5rem !important
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .5rem !important
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .5rem !important
  }

  .m-lg-3 {
    margin: 1rem !important
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important
  }

  .m-lg-4 {
    margin: 1.5rem !important
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important
  }

  .m-lg-5 {
    margin: 3rem !important
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important
  }

  .p-lg-1 {
    padding: .25rem !important
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important
  }

  .p-lg-2 {
    padding: .5rem !important
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: .5rem !important
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: .5rem !important
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .5rem !important
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: .5rem !important
  }

  .p-lg-3 {
    padding: 1rem !important
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important
  }

  .p-lg-4 {
    padding: 1.5rem !important
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important
  }

  .p-lg-5 {
    padding: 3rem !important
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important
  }

  .m-lg-n1 {
    margin: -0.25rem !important
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important
  }

  .m-lg-n2 {
    margin: -0.5rem !important
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important
  }

  .m-lg-n3 {
    margin: -1rem !important
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important
  }

  .m-lg-n4 {
    margin: -1.5rem !important
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important
  }

  .m-lg-n5 {
    margin: -3rem !important
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important
  }

  .m-lg-auto {
    margin: auto !important
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important
  }
}

@media(min-width:1200px) {
  .m-xl-0 {
    margin: 0 !important
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important
  }

  .m-xl-1 {
    margin: .25rem !important
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important
  }

  .m-xl-2 {
    margin: .5rem !important
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: .5rem !important
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .5rem !important
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .5rem !important
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .5rem !important
  }

  .m-xl-3 {
    margin: 1rem !important
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important
  }

  .m-xl-4 {
    margin: 1.5rem !important
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important
  }

  .m-xl-5 {
    margin: 3rem !important
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important
  }

  .p-xl-1 {
    padding: .25rem !important
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important
  }

  .p-xl-2 {
    padding: .5rem !important
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: .5rem !important
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: .5rem !important
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .5rem !important
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: .5rem !important
  }

  .p-xl-3 {
    padding: 1rem !important
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important
  }

  .p-xl-4 {
    padding: 1.5rem !important
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important
  }

  .p-xl-5 {
    padding: 3rem !important
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important
  }

  .m-xl-n1 {
    margin: -0.25rem !important
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important
  }

  .m-xl-n2 {
    margin: -0.5rem !important
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important
  }

  .m-xl-n3 {
    margin: -1rem !important
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important
  }

  .m-xl-n4 {
    margin: -1.5rem !important
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important
  }

  .m-xl-n5 {
    margin: -3rem !important
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important
  }

  .m-xl-auto {
    margin: auto !important
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
  text-align: justify !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

@media(min-width:576px) {
  .text-sm-left {
    text-align: left !important
  }

  .text-sm-right {
    text-align: right !important
  }

  .text-sm-center {
    text-align: center !important
  }
}

@media(min-width:768px) {
  .text-md-left {
    text-align: left !important
  }

  .text-md-right {
    text-align: right !important
  }

  .text-md-center {
    text-align: center !important
  }
}

@media(min-width:992px) {
  .text-lg-left {
    text-align: left !important
  }

  .text-lg-right {
    text-align: right !important
  }

  .text-lg-center {
    text-align: center !important
  }
}

@media(min-width:1200px) {
  .text-xl-left {
    text-align: left !important
  }

  .text-xl-right {
    text-align: right !important
  }

  .text-xl-center {
    text-align: center !important
  }
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.font-weight-light {
  font-weight: 300 !important
}

.font-weight-lighter {
  font-weight: lighter !important
}

.font-weight-normal {
  font-weight: 400 !important
}

.font-weight-bold {
  font-weight: 700 !important
}

.font-weight-bolder {
  font-weight: bolder !important
}

.font-italic {
  font-style: italic !important
}

.text-white {
  color: #fff !important
}

.text-primary {
  color: #007bff !important
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0056b3 !important
}

.text-secondary {
  color: #6c757d !important
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important
}

.text-success {
  color: #28a745 !important
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important
}

.text-info {
  color: #17a2b8 !important
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important
}

.text-warning {
  color: #ffc107 !important
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important
}

.text-danger {
  color: #dc3545 !important
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important
}

.text-light {
  color: #f8f9fa !important
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important
}

.text-dark {
  color: #343a40 !important
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important
}

.text-body {
  color: #212529 !important
}

.text-muted {
  color: #6c757d !important
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.text-decoration-none {
  text-decoration: none !important
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important
}

.text-reset {
  color: inherit !important
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.pull-right {
  float: right !important
}

.pull-left {
  float: left !important
}

.hide {
  display: none !important
}

.show {
  display: block !important
}

.hidden {
  display: none !important
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

@media print {

  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important
  }

  a:not(.btn) {
    text-decoration: underline
  }

  abbr[title]::after {
    content: " (" attr(title) ")"
  }

  pre {
    white-space: pre-wrap !important
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid
  }

  thead {
    display: table-header-group
  }

  tr,
  img {
    page-break-inside: avoid
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3
  }

  h2,
  h3 {
    page-break-after: avoid
  }

  @page {
    size: a3
  }

  body {
    min-width: 992px !important
  }

  .container {
    min-width: 992px !important
  }

  .navbar {
    display: none
  }

  .badge {
    border: 1px solid #000
  }

  .table {
    border-collapse: collapse !important
  }

  .table td,
  .table th {
    background-color: #fff !important
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important
  }

  .table-dark {
    color: inherit
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody+tbody {
    border-color: #dee2e6
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6
  }
}

@-ms-viewport {
  width: device-width
}

.visible-xs {
  display: none !important
}

.visible-sm {
  display: none !important
}

.visible-md {
  display: none !important
}

.visible-lg {
  display: none !important
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important
}

@media(max-width:767px) {
  .visible-xs {
    display: block !important
  }

  table.visible-xs {
    display: table !important
  }

  tr.visible-xs {
    display: table-row !important
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important
  }
}

@media(max-width:767px) {
  .visible-xs-block {
    display: block !important
  }
}

@media(max-width:767px) {
  .visible-xs-inline {
    display: inline !important
  }
}

@media(max-width:767px) {
  .visible-xs-inline-block {
    display: inline-block !important
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .visible-sm {
    display: block !important
  }

  table.visible-sm {
    display: table !important
  }

  tr.visible-sm {
    display: table-row !important
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .visible-sm-block {
    display: block !important
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .visible-sm-inline {
    display: inline !important
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .visible-sm-inline-block {
    display: inline-block !important
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .visible-md {
    display: block !important
  }

  table.visible-md {
    display: table !important
  }

  tr.visible-md {
    display: table-row !important
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .visible-md-block {
    display: block !important
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .visible-md-inline {
    display: inline !important
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .visible-md-inline-block {
    display: inline-block !important
  }
}

@media(min-width:1256px) {
  .visible-lg {
    display: block !important
  }

  table.visible-lg {
    display: table !important
  }

  tr.visible-lg {
    display: table-row !important
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important
  }
}

@media(min-width:1256px) {
  .visible-lg-block {
    display: block !important
  }
}

@media(min-width:1256px) {
  .visible-lg-inline {
    display: inline !important
  }
}

@media(min-width:1256px) {
  .visible-lg-inline-block {
    display: inline-block !important
  }
}

@media(max-width:767px) {
  .hidden-xs {
    display: none !important
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .hidden-sm {
    display: none !important
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .hidden-md {
    display: none !important
  }
}

@media(min-width:1256px) {
  .hidden-lg {
    display: none !important
  }
}

.visible-print {
  display: none !important
}

@media print {
  .visible-print {
    display: block !important
  }

  table.visible-print {
    display: table !important
  }

  tr.visible-print {
    display: table-row !important
  }

  th.visible-print,
  td.visible-print {
    display: table-cell !important
  }
}

.visible-print-block {
  display: none !important
}

@media print {
  .visible-print-block {
    display: block !important
  }
}

.visible-print-inline {
  display: none !important
}

@media print {
  .visible-print-inline {
    display: inline !important
  }
}

.visible-print-inline-block {
  display: none !important
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important
  }
}

@media print {
  .hidden-print {
    display: none !important
  }
}

@-ms-viewport {
  width: device-width
}

@-o-viewport {
  width: device-width
}

@viewport {
  width: device-width
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
  padding-left: 10px;
  padding-right: 10px
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px
}

.row {
  margin-left: -10px;
  margin-right: -10px
}

.container-fluid {
  padding-left: 0;
  padding-right: 0
}

@media(min-width:768px) and (max-width:1023px) {
  .container {
    width: 756px;
    padding-left: 24px;
    padding-right: 24px
  }

  .row {
    margin-left: -12px;
    margin-right: -12px
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
    padding-left: 12px;
    padding-right: 12px
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .container {
    width: 972px;
    padding-left: 24px;
    padding-right: 24px
  }

  .row {
    margin-left: -12px;
    margin-right: -12px
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
    padding-left: 12px;
    padding-right: 12px
  }
}

@media(min-width:1256px) {
  .container {
    width: 1212px;
    padding-left: 24px;
    padding-right: 24px
  }

  .row {
    margin-left: -12px;
    margin-right: -12px
  }

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
    padding-left: 12px;
    padding-right: 12px
  }
}

.col-xs-offset-right-12 {
  margin-right: 100%
}

.col-xs-offset-right-11 {
  margin-right: 91.66666667%
}

.col-xs-offset-right-10 {
  margin-right: 83.33333333%
}

.col-xs-offset-right-9 {
  margin-right: 75%
}

.col-xs-offset-right-8 {
  margin-right: 66.66666667%
}

.col-xs-offset-right-7 {
  margin-right: 58.33333333%
}

.col-xs-offset-right-6 {
  margin-right: 50%
}

.col-xs-offset-right-5 {
  margin-right: 41.66666667%
}

.col-xs-offset-right-4 {
  margin-right: 33.33333333%
}

.col-xs-offset-right-3 {
  margin-right: 25%
}

.col-xs-offset-right-2 {
  margin-right: 16.66666667%
}

.col-xs-offset-right-1 {
  margin-right: 8.33333333%
}

.col-xs-offset-right-0 {
  margin-right: 0
}

@media(min-width:768px) and (max-width:1023px) {
  .col-sm-offset-right-12 {
    margin-right: 100%
  }

  .col-sm-offset-right-11 {
    margin-right: 91.66666667%
  }

  .col-sm-offset-right-10 {
    margin-right: 83.33333333%
  }

  .col-sm-offset-right-9 {
    margin-right: 75%
  }

  .col-sm-offset-right-8 {
    margin-right: 66.66666667%
  }

  .col-sm-offset-right-7 {
    margin-right: 58.33333333%
  }

  .col-sm-offset-right-6 {
    margin-right: 50%
  }

  .col-sm-offset-right-5 {
    margin-right: 41.66666667%
  }

  .col-sm-offset-right-4 {
    margin-right: 33.33333333%
  }

  .col-sm-offset-right-3 {
    margin-right: 25%
  }

  .col-sm-offset-right-2 {
    margin-right: 16.66666667%
  }

  .col-sm-offset-right-1 {
    margin-right: 8.33333333%
  }

  .col-sm-offset-right-0 {
    margin-right: 0
  }
}

@media(min-width:1024px) and (max-width:1255px) {
  .col-md-offset-right-12 {
    margin-right: 100%
  }

  .col-md-offset-right-11 {
    margin-right: 91.66666667%
  }

  .col-md-offset-right-10 {
    margin-right: 83.33333333%
  }

  .col-md-offset-right-9 {
    margin-right: 75%
  }

  .col-md-offset-right-8 {
    margin-right: 66.66666667%
  }

  .col-md-offset-right-7 {
    margin-right: 58.33333333%
  }

  .col-md-offset-right-6 {
    margin-right: 50%
  }

  .col-md-offset-right-5 {
    margin-right: 41.66666667%
  }

  .col-md-offset-right-4 {
    margin-right: 33.33333333%
  }

  .col-md-offset-right-3 {
    margin-right: 25%
  }

  .col-md-offset-right-2 {
    margin-right: 16.66666667%
  }

  .col-md-offset-right-1 {
    margin-right: 8.33333333%
  }

  .col-md-offset-right-0 {
    margin-right: 0
  }
}

@media(min-width:1256px) {
  .col-lg-offset-right-12 {
    margin-right: 100%
  }

  .col-lg-offset-right-11 {
    margin-right: 91.66666667%
  }

  .col-lg-offset-right-10 {
    margin-right: 83.33333333%
  }

  .col-lg-offset-right-9 {
    margin-right: 75%
  }

  .col-lg-offset-right-8 {
    margin-right: 66.66666667%
  }

  .col-lg-offset-right-7 {
    margin-right: 58.33333333%
  }

  .col-lg-offset-right-6 {
    margin-right: 50%
  }

  .col-lg-offset-right-5 {
    margin-right: 41.66666667%
  }

  .col-lg-offset-right-4 {
    margin-right: 33.33333333%
  }

  .col-lg-offset-right-3 {
    margin-right: 25%
  }

  .col-lg-offset-right-2 {
    margin-right: 16.66666667%
  }

  .col-lg-offset-right-1 {
    margin-right: 8.33333333%
  }

  .col-lg-offset-right-0 {
    margin-right: 0
  }
}

/* @font-face{font-family:"RNHouseSansBold";src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.eot);src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.eot?#iefix) format("embedded-opentype"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.woff2) format("woff2"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.woff) format("woff"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.ttf) format("truetype"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.svg) format("svg");font-display:swap}
@font-face{font-family:"RNHouseSansRegular";src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.eot);src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.eot?#iefix) format("embedded-opentype"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.woff2) format("woff2"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.woff) format("woff"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.ttf) format("truetype"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.svg) format("svg");font-display:swap}
@font-face{font-family:"RNHouseSansLight";src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.eot);src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.eot?#iefix) format("embedded-opentype"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.woff2) format("woff2"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.woff) format("woff"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.ttf) format("truetype"),url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.svg) format("svg");font-display:swap} */

/* @font-face{
  font-family:"RNHouseSansBold";
  src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.eot);
  src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.eot?#iefix) format("embedded-opentype"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.woff2) format("woff2"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.woff) format("woff"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.ttf) format("truetype"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Bold.svg) format("svg");
  font-display:swap
}
@font-face{
  font-family:"RNHouseSansRegular";
  src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.eot);
  src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.eot?#iefix) format("embedded-opentype"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.woff2) format("woff2"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.woff) format("woff"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.ttf) format("truetype"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01Regular.svg) format("svg");
  font-display:swap
}
@font-face{
  font-family:"RNHouseSansLight";
  src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.eot);
  src:url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.eot?#iefix) format("embedded-opentype"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.woff2) format("woff2"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.woff) format("woff"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.ttf) format("truetype"),
      url(https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/RNHouseSansW01-Light.svg) format("svg");
  font-display:swap
} */
body {
  font-size: 18px;
  line-height: 26px;
  color: #333;
  letter-spacing: .2px;
  font-family: "RNHouseSansRegular", sans-serif;
  -ms-overflow-style: scrollbar
}

section {
  display: block
}

h1 {
  margin: 0;
  font-weight: normal;
  color: #001a72;
  font-family: "RNHouseSansRegular", sans-serif
}

h2,
h3,
h4,
h5,
h6,
.h3-custom,
.h2-custom,
.h2,
.h3,
.h4 {
  margin: 0;
  font-weight: normal;
  color: $color-champion-purple;
  font-family: "RNHouseSansRegular", sans-serif
}

h4,
.h4 {
  font-family: "RNHouseSansRegular", sans-serif;
  letter-spacing: .2px
}

h5,
.h5 {
  font-size: 14px;
  line-height: 15.4px
}

h6,
.h6 {
  font-size: 12px;
  line-height: 13.2px
}

.bgPurple {
  color: #fff;
  background-color: #001a72
}

.bgPurple h1,
.bgPurple h2,
.bgPurple h3,
.bgPurple h4,
.bgPurple h5,
.bgPurple h6,
.bgPurple .h2,
.bgPurple .h3,
.bgPurple .h4,
.bgPurple .h3-custom,
.bgPurple .h2-custom {
  color: #fff
}

.theme-grey {
  background-color: #f5f5f5
}

.theme--light-grey {
  background-color: #f5f5f5
}

.theme-blue {
  background-color: #001a72;
  background: #001a72;
  color: #fff
}

.theme-blue h1,
.theme-blue h2,
.theme-blue h3,
.theme-blue h4,
.theme-blue h5,
.theme-blue h6,
.theme-blue .h3-custom,
.theme-blue .h2-custom,
.theme-blue a {
  color: #fff
}

.theme-iom-blue {
  background-color: #bbdde6;
  color: #001a72
}

.theme-iom-blue h1,
.theme-iom-blue h2,
.theme-iom-blue h3,
.theme-iom-blue h4,
.theme-iom-blue h5,
.theme-iom-blue h6,
.theme-iom-blue .h3-custom,
.theme-iom-blue .h2-custom,
.theme-iom-blue a {
  color: #001a72
}

.theme-iom-lavender {
  background-color: #b74271;
  color: #fff
}

.theme-iom-lavender h1,
.theme-iom-lavender h2,
.theme-iom-lavender h3,
.theme-iom-lavender h4,
.theme-iom-lavender h5,
.theme-iom-lavender h6,
.theme-iom-lavender .h3-custom,
.theme-iom-lavender .h2-custom,
.theme-iom-lavender a {
  color: #fff
}

.theme-iom-lightgreen {
  background-color: #007a33;
  color: #fff
}

.theme-iom-lightgreen h1,
.theme-iom-lightgreen h2,
.theme-iom-lightgreen h3,
.theme-iom-lightgreen h4,
.theme-iom-lightgreen h5,
.theme-iom-lightgreen h6,
.theme-iom-lightgreen .h3-custom,
.theme-iom-lightgreen .h2-custom,
.theme-iom-lightgreen a {
  color: #fff
}

.theme-iom-green {
  background-color: #001a72;
  color: #fff
}

.theme-iom-green h1,
.theme-iom-green h2,
.theme-iom-green h3,
.theme-iom-green h4,
.theme-iom-green h5,
.theme-iom-green h6,
.theme-iom-green .h3-custom,
.theme-iom-green .h2-custom,
.theme-iom-green a {
  color: #fff
}

.brandColor {
  color: #001a72
}

h1,
h2,
.h2 {
  font-size: 52px;
  line-height: 64px
}

.h2-custom {
  font-size: 40px;
  line-height: 50px
}

h3,
.h3 {
  font-size: 30px;
  line-height: 40px
}

.h3-custom {
  font-size: 26px;
  line-height: 34px
}

h4,
.h4 {
  font-size: 22px;
  line-height: 32px
}

.legal-copy {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: .2px;
  color: #333
}

.container-fluid a {
  font-size: 18px;
  line-height: 32px;
  color: inherit
}

.link-custom {
  font-size: 14px;
  line-height: 24px
}

html:not(.firefox) .main-wrapper a:focus,
html:not(.firefox) .main-wrapper a:hover:focus {
  outline-offset: -1px;
  outline: 1px auto #1d7b8a
}

.ext-strict #CQ .x-form-text {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit
}

@media(min-width:1024px) and (max-width:1255px) {

  h1,
  h2,
  .h2 {
    font-size: 42px;
    line-height: 54px
  }

  .h2-custom {
    font-size: 38px;
    line-height: 48px
  }

  h3,
  .h3 {
    font-size: 26px;
    line-height: 36px
  }

  .h3-custom {
    font-size: 24px;
    line-height: 32px
  }
}

@media(min-width:768px) and (max-width:1023px) {

  h1,
  h2,
  .h2 {
    font-size: 34px;
    line-height: 44px
  }

  .h2-custom {
    font-size: 28px;
    line-height: 38px
  }

  h3,
  .h3-custom,
  .h3 {
    font-size: 24px;
    line-height: 32px
  }

  h4,
  .h4 {
    font-size: 18px;
    line-height: 26px
  }
}

html {
  -webkit-text-size-adjust: none
}

@media(max-width:767px) {

  h1,
  h2,
  .h2-custom,
  .h2 {
    font-size: 34px;
    line-height: 44px
  }

  h3,
  .h3-custom,
  .h3 {
    font-size: 24px;
    line-height: 32px
  }

  h4,
  .h4 {
    font-size: 18px;
    line-height: 26px
  }

  .paddings-zero {
    padding: 0
  }
}

.comp-svg-generic {
  font: 0/0 a
}

.adaptive-image-wrapper {
  height: inherit
}

.comp-img-generic a,
.comp-svg-generic a {
  display: block;
  max-height: 100%;
  height: inherit
}

.comp-img-generic a:focus,
.comp-svg-generic a:focus {
  text-decoration: none;
  outline: 1px auto #1d7b8a
}

html:not(.firefox) .comp-img-generic a:focus,
html:not(.firefox) .comp-svg-generic a:focus {
  outline: 1px auto #1d7b8a;
  text-decoration: none
}

.firefox .comp-img-generic a:focus,
.firefox .comp-svg-generic a:focus {
  text-decoration: none
}

html:not(.firefox) .comp-img-generic a:focus:hover,
html:not(.firefox) .comp-svg-generic a:focus:hover {
  outline: 1px auto #1d7b8a
}

html:not(.msie):not(.msedge) .comp-img-generic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center
}

html:not(.msie):not(.msedge) .comp-img-generic a {
  width: inherit;
  height: inherit
}

.bgPurple .comp-img-generic a:focus,
.bgPurple .comp-svg-generic a:focus {
  outline: 1px auto #fff
}

html:not(.firefox) .bgPurple .comp-img-generic a:focus,
html:not(.firefox) .bgPurple .comp-svg-generic a:focus {
  outline: 1px auto #fff
}

html:not(.firefox) .bgPurple .comp-img-generic a:focus:hover,
html:not(.firefox) .bgPurple .comp-svg-generic a:focus:hover {
  outline: 1px auto #fff
}

.title-comp.tooltip-comp,
.form-field-label.tooltip-comp {
  position: relative;
  display: inline
}

.title-comp.tooltip-comp+.tooltip,
.form-field-label.tooltip-comp+.tooltip {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: top;
  margin: 1px 0 1px 20px;
  background-image: url("https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/images/natwest/icon_toottip_nw.svg");
  background-repeat: no-repeat;
  background-size: 100%
}

[tabindex="-1"] {
  outline: 0
}

.title-comp.tooltip-comp+.tooltip {
  cursor: pointer
}

.title-comp.tooltip-comp+.tooltip:focus {
  outline: 1px auto #1d7b8a
}

.custom-tooltip {
  display: none;
  position: absolute;
  font-size: 20px;
  line-height: 24px;
  padding: 25px;
  z-index: 9999;
  letter-spacing: .3px;
  width: 100%;
  background-color: #1e808f;
  color: #fff
}

.custom-tooltip .tooltip-cone {
  position: absolute;
  bottom: -12px;
  border-width: 12px 13px 0 12px;
  border-style: solid;
  display: block;
  width: 0;
  height: 0;
  border-color: #1e808f transparent
}

.custom-tooltip.bottom-tooltip .tooltip-cone {
  top: -12px;
  border-width: 0 13px 12px 12px
}

label.labelinfo-comp,
.form-field-label.labelinfo-comp {
  position: relative;
  display: inline
}

label.labelinfo-comp+.labelinfo,
.form-field-label.labelinfo-comp+.labelinfo {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  margin: 1px 0 1px 15px;
  background-image: url("https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/images/natwest/icon_toottip_nw.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 5px
}

.label-info {
  transition: opacity 250ms cubic-bezier(0.15, 0.43, 0.34, 0.99), margin-top 250ms cubic-bezier(0.15, 0.43, 0.34, 0.99);
  opacity: 0;
  display: none;
  width: 471px;
  position: relative;
  font-size: 18px;
  line-height: 26px;
  padding: 64px 35px 35px 35px;
  background-color: #1e808f;
  color: #fff;
  z-index: 9999;
  letter-spacing: .3px;
  margin-top: 20px;
  margin-bottom: 20px
}

.label-info.displayinfo {
  opacity: 1
}

.label-info .labelinfo-cone {
  position: absolute;
  top: -11px;
  border-width: 0 13px 12px 12px;
  border-style: solid;
  border-color: #1e808f transparent;
  display: block;
  width: 0;
  height: 0
}

.labelinfo-close {
  cursor: pointer;
  width: 24px !important;
  height: 24px;
  background-image: url("https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/images/icon-close-white.svg");
  background-repeat: no-repeat;
  position: absolute;
  right: 20px;
  color: #fff;
  top: 20px;
  background-position: 50%
}

html:not(.firefox) .main-wrapper .labelinfo-close:focus,
html:not(.firefox) .main-wrapper .labelinfo-close:hover:focus {
  outline: 1px auto #fff
}

@media(min-width:1024px) and (max-width:1255px) {
  .title-comp.tooltip-comp+.tooltip {
    width: 26px;
    height: 26px;
    margin: 4.5px 0 4.5px 20px
  }

  .custom-tooltip {
    padding-right: 26px
  }

  .label-info {
    padding: 59px 20px 35px 20px;
    width: 395px
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .title-comp.tooltip-comp+.tooltip {
    width: 28px;
    height: 28px;
    margin: 2px 0 2px 20px
  }

  .custom-tooltip {
    padding-right: 26px
  }

  .label-info {
    padding: 59px 20px 30px 20px;
    width: 403px
  }
}

@media(max-width:767px) {
  .title-comp.tooltip-comp+.tooltip {
    width: 20px;
    height: 20px;
    margin: 6px 0 6px 15px
  }

  .custom-tooltip {
    font-size: 18px;
    line-height: 22px;
    padding-right: 21px;
    padding-left: 20px
  }

  .custom-tooltip .tooltip-cone {
    bottom: -9px;
    border-width: 9px 8px 0 9px
  }

  .custom-tooltip.bottom-tooltip .tooltip-cone {
    top: -9px;
    border-width: 0 8px 9px 9px
  }

  .label-info {
    padding: 59px 20px 30px 20px;
    width: 335px
  }
}

.visually-hidden {
  position: absolute;
  width: 0;
  height: 0;
  clip: rect(1px, 1px, 1px, 1px)
}

.shelf-margin {
  margin-bottom: 20px
}

.shelf-top-margin {
  margin-top: 20px
}

.skip-links {
  height: 0;
  overflow: hidden;
  margin: 0
}

.skip-links li {
  height: 0;
  overflow: hidden;
  margin: 0
}

.skip-links li a {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden
}

.skip-links li a:focus,
.skip-links li a:active {
  width: auto;
  height: auto;
  overflow: visible;
  top: 13px;
  left: 20px;
  z-index: 9999;
  color: #fff
}

.skip-links li a:focus,
.skip-links li a:hover:focus {
  text-decoration: none
}

html:not(.firefox) .skip-links li a:focus,
html:not(.firefox) .skip-links li a:hover:focus {
  outline: 1px auto #fff
}

.cookie {
  background: #001a72;
  position: relative;
  border-bottom: 1px solid #fff
}

.cookie.cookie__hidden {
  width: 1px;
  height: 1px;
  position: absolute;
  padding: 0;
  margin: -.063em;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0)
}

.cookie .cookie_inner {
  padding: 50px 0 30px 0;
  min-height: 40px;
  font-family: "RNHouseSansRegular", sans-serif;
  font-size: 12px;
  color: #fff
}

@media(max-width:767px) {
  .cookie .cookie_inner {
    padding-top: 64px
  }
}

.cookie .cookie_inner .cookie__h-subline {
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 30px;
  line-height: 40px;
  color: #fff
}

@media(min-width:768px) and (max-width:1023px) {
  .cookie .cookie_inner .cookie__h-subline {
    font-size: 26px;
    line-height: 36px
  }
}

@media(max-width:767px) {
  .cookie .cookie_inner .cookie__h-subline {
    font-size: 24px;
    line-height: 32px
  }
}

.cookie .cookie_inner .rteWrapper {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .2px
}

.cookie .cookie_inner .rteWrapper p {
  margin-bottom: 20px
}

.cookie .cookie_inner .rteWrapper p a {
  color: inherit;
  text-decoration: none;
  width: 100%;
  width: -moz-calc(100%);
  width: -webkit-calc(100%);
  width: -o-calc(100%);
  width: calc(100%);
  background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, #fff);
  background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
  background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
  background-image: linear-gradient(rgba(0, 0, 0, 0) calc(100% - 1px), #fff 10px, #fff);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: background-size ease-in-out 180ms;
  -moz-transition: background-size ease-in-out 180ms;
  -o-transition: background-size ease-in-out 180ms;
  transition: background-size ease-in-out 180ms;
  padding-bottom: 3px;
  font-size: inherit;
  line-height: inherit
}

.msie8 .cookie .cookie_inner .rteWrapper p a,
.msie9 .cookie .cookie_inner .rteWrapper p a {
  border-bottom: 1px solid
}

.cookie .cookie_inner .rteWrapper p a:hover {
  background-size: 0 100%;
  border: 0
}

.msedge .cookie .cookie_inner .rteWrapper p a:hover {
  background-image: none
}

.cookie .cookie_inner .rteWrapper p a:focus,
.cookie .cookie_inner .rteWrapper p a:focus:hover {
  background-image: none
}

html:not(.firefox) .cookie .cookie_inner .rteWrapper p a:focus,
html:not(.firefox) .cookie .cookie_inner .rteWrapper p a:focus:hover {
  outline: 1px auto #fff;
  background-image: none
}

.msie8 .cookie .cookie_inner .rteWrapper p a:focus,
.msie9 .cookie .cookie_inner .rteWrapper p a:focus,
.msie8 .cookie .cookie_inner .rteWrapper p a:focus:hover,
.msie9 .cookie .cookie_inner .rteWrapper p a:focus:hover {
  border: 0
}

.cookie .cookie_inner .set_ccfcookie_img {
  display: none
}

.cookie .cookie__close {
  width: 16px;
  height: 16px;
  float: right;
  text-decoration: none;
  cursor: pointer;
  z-index: 999;
  font-size: inherit;
  line-height: inherit;
  margin-top: 50px
}

@media(max-width:767px) {
  .cookie .cookie__close {
    position: absolute;
    margin-top: 28px;
    right: 20px
  }
}

.cookie .cookie__close .hidden {
  display: none;
  text-indent: -9999px
}

.cookie .cookie__close:after {
  font-size: 16px;
  color: #fff;
  font-family: 'nw-icons';
  content: "\e010"
}

html:not(.firefox) .cookie .cookie__close:focus,
html:not(.firefox) .cookie .cookie__close:focus:hover {
  outline: 1px auto #fff
}

.ot-sdk-show-settings {
  margin-top: 20px;
  margin-bottom: 20px
}

.subheader {
  font-size: 18px;
  line-height: 24px;
  font-family: "RNHouseSansRegular", sans-serif;
  color: #001a72
}

.body-copy {
  font-size: 18px;
  line-height: 26px
}

.body-custom-style {
  font-size: 16px;
  line-height: 26px
}

html:not(.firefox) .theme-rose .comp-rich-text a:focus,
html:not(.firefox) .bgPurple .comp-rich-text a:focus,
html:not(.firefox) .theme-blue .comp-rich-text a:focus,
html:not(.firefox) .theme-iom-green .comp-rich-text a:focus,
html:not(.firefox) .theme-iom-lavender .comp-rich-text a:focus,
html:not(.firefox) .theme-iom-olive .comp-rich-text a:focus {
  outline: 1px auto #fff;
  text-decoration: none;
  background-image: none
}

.sa-bgGrey .comp-rich-text h1 {
  color: #001a72
}

.sa-bgGrey .comp-rich-text h2,
.sa-bgGrey .comp-rich-text h3,
.sa-bgGrey .comp-rich-text h4,
.sa-bgGrey .comp-rich-text h5,
.sa-bgGrey .comp-rich-text h6 {
  color: #001a72
}

.comp-rich-text a {
  color: #1d7b8a;
  padding: 6px 0 2px 0;
  text-decoration: none;
  line-height: 26px;
  width: 100%;
  width: -moz-calc(100%);
  width: -webkit-calc(100%);
  width: -o-calc(100%);
  width: calc(100%);
  background-image: -webkit-linear-gradient(transparent 95%, #1d7b8a 10px, #1d7b8a);
  background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #1d7b8a 10px, #1d7b8a);
  background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #1d7b8a 10px, #1d7b8a);
  background-image: linear-gradient(rgba(0, 0, 0, 0) calc(100% - 1px), #1d7b8a 10px, #1d7b8a);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: background-size ease-in-out 180ms;
  -moz-transition: background-size ease-in-out 180ms;
  -o-transition: background-size ease-in-out 180ms;
  transition: background-size ease-in-out 180ms;
  padding-bottom: 3px
}

.msie8 .comp-rich-text a,
.msie9 .comp-rich-text a {
  border-bottom: 1px solid
}

.theme-rose .comp-rich-text a,
.bgPurple .comp-rich-text a,
.theme-blue .comp-rich-text a,
.theme-iom-green .comp-rich-text a,
.theme-iom-lavender .comp-rich-text a,
.theme-iom-olive .comp-rich-text a {
  color: #fff;
  width: 100%;
  width: -moz-calc(100%);
  width: -webkit-calc(100%);
  width: -o-calc(100%);
  width: calc(100%);
  background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, #fff);
  background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
  background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
  background-image: linear-gradient(rgba(0, 0, 0, 0) calc(100% - 1px), #fff 10px, #fff);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: background-size ease-in-out 180ms;
  -moz-transition: background-size ease-in-out 180ms;
  -o-transition: background-size ease-in-out 180ms;
  transition: background-size ease-in-out 180ms;
  padding-bottom: 3px
}

.comp-rich-text a:hover {
  background-size: 0 100%;
  border: 0
}

.msedge .comp-rich-text a:hover {
  background-image: none
}

html:not(.firefox) .comp-rich-text a:focus {
  outline: 1px auto #1d7b8a;
  outline-offset: -2px;
  background-image: none;
  padding-bottom: 6px
}

.firefox .comp-rich-text a:focus {
  background-image: none
}

.msie8 .comp-rich-text a:focus,
.msie9 .comp-rich-text a:focus {
  border: 0
}

html:not(.firefox) .comp-rich-text a:focus:hover {
  outline-offset: -2px
}

.comp-rich-text ol {
  margin: 0 0 10px 20px;
  padding: 0
}

.comp-rich-text ol li {
  margin-top: 20px;
  padding-left: 19px
}

.comp-rich-text ol li:first-child {
  margin-top: 0
}

.comp-rich-text ul {
  margin: 0 0 10px 0;
  padding: 0;
  list-style-type: none
}

.comp-rich-text ul li {
  margin-top: 10px;
  position: relative;
  display: block;
  padding-left: 39px;
  min-height: 26px
}

.comp-rich-text ul li:first-child {
  margin-top: 0
}

.theme-purple .comp-rich-text ul li:before {
  color: $color-champion-purple;
}

.comp-rich-text ul li:before {
  content: "\e018";
  font-family: nw-icons;
  color: #b74271;
  font-size: 14px;
  margin-left: -3px;
  margin-top: -2px;
  position: absolute;
  left: 0
}


.theme-rose .comp-rich-text ul li:before,
.bgPurple .comp-rich-text ul li:before,
.bg-benifits .comp-rich-text ul li:before,
.theme-iom-lavender .comp-rich-text ul li:before,
.theme-iom-green .comp-rich-text ul li:before,
.theme-iom-olive .comp-rich-text ul li:before {
  color: #fff
}

.sa-bgGrey .comp-rich-text ul li:before {
  color: #001a72
}

.comp-rich-text ul.list-tick li {
  padding-left: 39px
}

.comp-rich-text ul.list-tick li:before {
  content: "\e900";
  font-size: 12px;
  margin-left: 0;
  font-weight: bold
}

.comp-rich-text ul.list-square li {
  padding-left: 39px
}

.comp-rich-text ul.list-square li:before {
  content: "\e67c";
  font-size: 6px;
  margin-left: 0
}

.comp-rich-text span.list-item-square {
  position: relative;
  display: block;
  padding-left: 26px
}

.comp-rich-text span.list-item-square:before {
  content: "\e67c";
  font-family: nw-icons;
  color: #001a72;
  font-size: 6px;
  position: absolute;
  left: 0;
  margin-top: -2px
}

.theme-rose .comp-rich-text span.list-item-square:before,
.bgPurple .comp-rich-text span.list-item-square:before,
.theme-iom-green .comp-rich-text span.list-item-square:before,
.theme-iom-lavender .comp-rich-text span.list-item-square:before,
.theme-iom-olive .comp-rich-text span.list-item-square:before {
  color: #fff
}

.sa-bgGrey .comp-rich-text span.list-item-square:before {
  color: #001a72
}

.comp-rich-text span.list-item-tick {
  position: relative;
  display: block;
  padding-left: 49px
}

.comp-rich-text span.list-item-tick:before {
  content: "\e900";
  font-family: nw-icons;
  font-size: 19px;
  font-weight: bold;
  position: absolute;
  left: 0;
  color: #001a72
}

.theme-rose .comp-rich-text span.list-item-tick:before,
.bgPurple .comp-rich-text span.list-item-tick:before,
.bg-benifits .comp-rich-text span.list-item-tick:before,
.theme-iom-green .comp-rich-text span.list-item-tick:before,
.theme-iom-lavender .comp-rich-text span.list-item-tick:before,
.theme-iom-olive .comp-rich-text span.list-item-tick:before {
  color: #fff
}

.sa-bgGrey .comp-rich-text span.list-item-tick:before {
  color: #001a72
}

.theme-rose .comp-rich-text .subheader,
.bgPurple .comp-rich-text .subheader,
.theme-blue .comp-rich-text .subheader,
.bg-benifits .comp-rich-text .subheader,
.theme-iom-green .comp-rich-text .subheader {
  color: #fff
}

.bgTeal .comp-rich-text h1,
.bgTeal .comp-rich-text h2,
.bgTeal .comp-rich-text h3,
.bgTeal .comp-rich-text h4,
.bgTeal .comp-rich-text h5,
.bgTeal .comp-rich-text h6,
.bgGreen .comp-rich-text h1,
.bgGreen .comp-rich-text h2,
.bgGreen .comp-rich-text h3,
.bgGreen .comp-rich-text h4,
.bgGreen .comp-rich-text h5,
.bgGreen .comp-rich-text h6,
.bgOrange .comp-rich-text h1,
.bgOrange .comp-rich-text h2,
.bgOrange .comp-rich-text h3,
.bgOrange .comp-rich-text h4,
.bgOrange .comp-rich-text h5,
.bgOrange .comp-rich-text h6,
.bgHeather .comp-rich-text h1,
.bgHeather .comp-rich-text h2,
.bgHeather .comp-rich-text h3,
.bgHeather .comp-rich-text h4,
.bgHeather .comp-rich-text h5,
.bgHeather .comp-rich-text h6,
.bgBlue .comp-rich-text h1,
.bgBlue .comp-rich-text h2,
.bgBlue .comp-rich-text h3,
.bgBlue .comp-rich-text h4,
.bgBlue .comp-rich-text h5,
.bgBlue .comp-rich-text h6,
.theme-blue .comp-rich-text h1,
.theme-blue .comp-rich-text h2,
.theme-blue .comp-rich-text h3,
.theme-blue .comp-rich-text h4,
.theme-blue .comp-rich-text h5,
.theme-blue .comp-rich-text h6,
.bg-benifits .comp-rich-text h1,
.bg-benifits .comp-rich-text h2,
.bg-benifits .comp-rich-text h3,
.bg-benifits .comp-rich-text h4,
.bg-benifits .comp-rich-text h5,
.bg-benifits .comp-rich-text h6,
.theme-iom-green .comp-rich-text h1,
.theme-iom-green .comp-rich-text h2,
.theme-iom-green .comp-rich-text h3,
.theme-iom-green .comp-rich-text h4,
.theme-iom-green .comp-rich-text h5,
.theme-iom-green .comp-rich-text h6,
.theme-iom-lavender .comp-rich-text h1,
.theme-iom-lavender .comp-rich-text h2,
.theme-iom-lavender .comp-rich-text h3,
.theme-iom-lavender .comp-rich-text h4,
.theme-iom-lavender .comp-rich-text h5,
.theme-iom-lavender .comp-rich-text h6,
.theme-iom-olive .comp-rich-text h1,
.theme-iom-olive .comp-rich-text h2,
.theme-iom-olive .comp-rich-text h3,
.theme-iom-olive .comp-rich-text h4,
.theme-iom-olive .comp-rich-text h5,
.theme-iom-olive .comp-rich-text h6 {
  color: #fff
}

.bgTeal .comp-rich-text a,
.bgGreen .comp-rich-text a,
.bgOrange .comp-rich-text a,
.bgHeather .comp-rich-text a,
.bgBlue .comp-rich-text a,
.theme-blue .comp-rich-text a,
.bg-benifits .comp-rich-text a,
.theme-iom-green .comp-rich-text a,
.theme-iom-lavender .comp-rich-text a,
.theme-iom-olive .comp-rich-text a {
  color: #fff;
  width: 100%;
  width: -moz-calc(100%);
  width: -webkit-calc(100%);
  width: -o-calc(100%);
  width: calc(100%);
  background-image: -webkit-linear-gradient(transparent 95%, #fff 10px, #fff);
  background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #fff 10px, #fff);
  background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #fff 10px, #fff);
  background-image: linear-gradient(rgba(0, 0, 0, 0) calc(100% - 1px), #fff 10px, #fff);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: background-size ease-in-out 180ms;
  -moz-transition: background-size ease-in-out 180ms;
  -o-transition: background-size ease-in-out 180ms;
  transition: background-size ease-in-out 180ms;
  padding-bottom: 3px
}

.bgTeal .comp-rich-text ul li:before,
.bgGreen .comp-rich-text ul li:before,
.bgOrange .comp-rich-text ul li:before,
.bgHeather .comp-rich-text ul li:before,
.bgBlue .comp-rich-text ul li:before,
.theme-blue .comp-rich-text ul li:before,
.bg-benifits .comp-rich-text ul li:before,
.theme-iom-green .comp-rich-text ul li:before,
.theme-iom-lavender .comp-rich-text ul li:before,
.theme-iom-olive .comp-rich-text ul li:before {
  color: #fff
}

.bgTeal .comp-rich-text span.list-item-square:before,
.bgGreen .comp-rich-text span.list-item-square:before,
.bgOrange .comp-rich-text span.list-item-square:before,
.bgHeather .comp-rich-text span.list-item-square:before,
.bgBlue .comp-rich-text span.list-item-square:before,
.theme-blue .comp-rich-text span.list-item-square:before,
.bg-benifits .comp-rich-text span.list-item-square:before,
.theme-iom-green .comp-rich-text span.list-item-square:before,
.theme-iom-lavender .comp-rich-text span.list-item-square:before,
.theme-iom-olive .comp-rich-text span.list-item-square:before {
  color: #fff
}

.bgTeal .comp-rich-text span.list-item-tick:before,
.bgGreen .comp-rich-text span.list-item-tick:before,
.bgOrange .comp-rich-text span.list-item-tick:before,
.bgHeather .comp-rich-text span.list-item-tick:before,
.bgBlue .comp-rich-text span.list-item-tick:before,
.theme-blue .comp-rich-text span.list-item-tick:before,
.bg-benifits .comp-rich-text span.list-item-tick:before,
.theme-iom-green .comp-rich-text span.list-item-tick:before,
.theme-iom-lavender .comp-rich-text span.list-item-tick:before,
.theme-iom-olive .comp-rich-text span.list-item-tick:before {
  color: #fff
}

.bgYellow .comp-rich-text h1,
.bgYellow .comp-rich-text h2,
.bgYellow .comp-rich-text h3,
.bgYellow .comp-rich-text h4,
.bgYellow .comp-rich-text h5,
.bgYellow .comp-rich-text h6 {
  color: #001a72
}

.bgYellow .comp-rich-text a {
  color: #333;
  width: 100%;
  width: -moz-calc(100%);
  width: -webkit-calc(100%);
  width: -o-calc(100%);
  width: calc(100%);
  background-image: -webkit-linear-gradient(transparent 95%, #333 10px, #333);
  background-image: -moz-linear-gradient(transparent -moz-calc(100% - 1px), #333 10px, #333);
  background-image: -o-linear-gradient(transparent -o-calc(100% - 1px), #333 10px, #333);
  background-image: linear-gradient(rgba(0, 0, 0, 0) calc(100% - 1px), #333 10px, #333);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-transition: background-size ease-in-out 180ms;
  -moz-transition: background-size ease-in-out 180ms;
  -o-transition: background-size ease-in-out 180ms;
  transition: background-size ease-in-out 180ms;
  padding-bottom: 3px
}

.bgYellow .comp-rich-text ul li:before {
  color: #333
}

.bgYellow .comp-rich-text span.list-item-square:before {
  color: #333
}

.bgYellow .comp-rich-text span.list-item-tick:before {
  color: #333
}

@media(min-width:1024px) and (max-width:1255px) {

  .theme-rose .comp-rich-text a,
  .bgPurple .comp-rich-text a,
  .theme-blue .comp-rich-text a,
  .theme-iom-green .comp-rich-text a,
  .theme-iom-lavender .comp-rich-text a,
  .theme-iom-olive .comp-rich-text a {
    color: #fff
  }
}

@media(max-width:1023px) {
  .comp-rich-text ul li:before {
    margin-top: -2px
  }

  .iphone .comp-rich-text ul li:before,
  .ipad .comp-rich-text ul li:before {
    margin-top: 1px
  }

  .comp-rich-text span.list-item-square:before {
    margin-top: -2px
  }

  .iphone .comp-rich-text span.list-item-square:before,
  .ipad .comp-rich-text span.list-item-square:before {
    margin-top: 1px
  }
}