@import '../../../variables';
@import '../../../scss/colors.scss';

.field-radio {

  @media (max-width: #{$screen-sm-min}) {
    width: 100%;

    label {
      width: 100%;
    }
  }

  &.field-block--lg {
    .field-title {
      margin-top: 24px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .field-radio__option {

      .btn-select {
        width: 197px;
        height: 76px;
        // border-radius: 26px;
      }
    }
  }

  label {
    margin-bottom: 0;
  }

  .field-radio__option {
    margin-left: -1px;

    .label{
      &:focus {
        border: 2px solid $color-bright-purple;
      }    
    }

    @media (max-width: #{$screen-sm-min}) {
      width: 100%;
      margin-bottom: 16px;

      .btn.btn-select {
        width: 100%;
        span {
          width: 100%;
        }
      }
    }


    &:first-of-type {
      .btn-select {
        border-top-left-radius: 26px;
        border-bottom-left-radius: 26px;
      }
    }
    &:last-of-type {
      .btn-select {
        border-top-right-radius: 26px;
        border-bottom-right-radius: 26px;
      }
    }

    &.active {
      .btn-select {
        border: 1px solid $color-bright-purple;
        background-color: $color-regal-purple;
        color: $color-btn-text;
        
        &:hover {
          border: 1px solid $color-regal-purple;
          background-color: $color-lilac;
          border-color: $color-regal-purple ;
          border-width: 2px;
          // border-radius: 26px;
        }
      }
    }

    &.inactive {
      .btn-select {
        border: 1px solid $color-bright-purple;
        background-color: $color-white;
        color: $color-regal-purple;

        &:hover {
          border: 1px solid $color-regal-purple;
          background-color: $color-lilac;
          border-color: $color-regal-purple ;
          border-width: 2px;
          // border-radius: 26px;
        }
      }

    }

    .btn-select {
      width: 152px;
      height: 56px;
      font-size: 18px;
      vertical-align: middle;
      margin-right: 0;
      line-height: 24px;
      display: table;
      padding: 0;

      
      
      span {
        display: table-cell; 
        vertical-align: middle; 
        text-align: center; 
      }
    }
  }

  & .label {
    font-weight: normal;
    margin-right: 30px;
  }

  & .check {
    display: inline-block;
    vertical-align: middle;
    border: 2px solid #c9c6c6;
    box-sizing: border-box;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 2px;
    margin: 0px 8px 0px 0px;

    &:hover {
      border-color: $color-bright-purple;
      border-width: 2.5px;
    }

    &::after {
      content: "";
      height: 8px;
      width: 8px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: transparent;
      display: block;
    }
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;

    &:checked ~ .check {
      border-color: $color-champion-purple;
      
      &::after {
        height: 12px;
        width: 12px;
        background: $color-champion-purple;
        display: block;
      }
    }
  }
}

.field-block.field-radio:not(.field-radio-button) {
 
  .field-radio__option {
    margin-right: 15px;

    &:focus-within {  
      outline: 2px solid $color-bright-purple;
      outline-offset: 2px;

    }
      
  }

}

