@import "../../mixins";
@import "../../variables";

.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  // height: 402px;
  min-height: 402px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  
  @include lg {
    padding: 0px 0 40px 0px;
    max-width: calc(100%);
  }
  &__white-bg {
    background-color: #f5f5f5;
    color: #001e69;
  }

  &__purple-bg {
    background-color: #001e69;
    color: #fff;
  }
  
  &__purple-end-bg {
    // background-color: #001e69;
    background-image: linear-gradient(to right , #422e5c, #1e1532);
    color: #fff;
  }
}

.carousel {
  &__container {
    align-self: flex-start;
    max-width: 1164px;
    display: flex;
    flex-direction: column;
    line-height: 1.44;
    white-space: pre-wrap;
    padding: 24px;
    @include lg {
      width: 100%;
      padding: 0px;
      padding-bottom: 20px;
      // background: white;
      max-width: 1164px;
      margin: 0 auto;
      padding: 0 20px;
    }
    &__white-h2 {
      width: 100%;
      font-size: 34px;
      line-height: 1.29;
      color: #ffffff;
      @include lg {
        padding: 40px 20px 0px 0px;
      }
    }

    &__purple-h2 {
      width: 100%;
      font-size: 34px;
      line-height: 1.29;
      color: #001e69;
      @include lg {
        padding: 40px 20px 0px 0px;
      }
    }    
  }

  &__white-single-line {
    color: white;
    margin-top: 20px;
    margin-bottom: 24px;
    font-size: 18px;
  }

  &__radio {
    
    & > label {
      display: block;
      border-radius: 8px;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      margin-top: 20px;
      display: inline-flex;
      cursor: pointer;

      &.active {
        border-color: #001e69;

        &--nested {
          border-color: #001e69;
          height: auto;
        }
      }

      
      & > .label {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #001e69;
      }

      & > input {
        opacity: 0;
        pointer-events: none;
        height: 0;
        width: 0;
  
        &:checked ~ .check {
          border-color: #001e69;
          &::after {
            height: 19px;
            width: 18px;
            background: #001e69;
            display: block;
          }
        }
      }

      & .check {
        display: inline-block;
        vertical-align: middle;
        border: 2px solid #001e69;
        box-sizing: border-box;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        position: relative;
        bottom: 2px;
        margin: 0px 15px 0px 0px;

        &::after {
          content: "";
          height: 18px;
          width: 18px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background: transparent;
          display: block;
        }
      }
    }
  }

  &__checklist {
    margin-bottom: 30px;
    list-style-type: none;

    @include lg {
      &__wrapper {
        width: 60%;
        max-width: 649px;
        display: block;
        box-sizing: border-box;
        padding-right: 50px;
        margin-bottom: 0;
      }
    }

    & > li {
      display: block;
      padding-left: 45px;
      position: relative;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      margin-top: 20px;

      @include lg {
        margin-bottom: 25px;
      }

      & > strong {
        font-family: $primary-font-bold;
      }

      & > a {
        color: #288090;
      }

      & > span {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 0;
        background: #001e69;
        border-radius: 50%;
        line-height: 24px;
        text-align: center;
        color: white;
        font-size: 16px;
      }

      & > svg {
        position: absolute;
        left: 0;
        top: 0;
        height: 26px;
        width: 27px;
        object-fit: contain;
      }
    }
    &__btn-list {
      background-color: #001e69;
      display: block;
      height: 56px;
      width: 240px;
      font-size: 18px;
      margin-top: 30px !important;
      text-align: center;
      padding-left: 0 !important;
      color: white !important;
      text-decoration: none;
      line-height: 56px !important; 
      cursor: pointer;
      margin: auto;

      @include lg {
        width: 292px;
        margin: unset;
        margin-top: 30px !important;
      }

    }
    
  }

  &__btn__container {
    display: flex;
    margin-top: 10px;
    flex-direction: column-reverse;
    @include md {
      flex-direction: row;
    }
  
    & > a{
      color: white !important;
      text-decoration: none;
    }
  }

  &__btn {
    display: block;
    height: 56px;
    font-size: 18px;
    line-height: 56px;
    text-align: center;
    margin-top: 20px;
    text-decoration: none;
    cursor: pointer;
    width: 100%;

    @include lg{
      width: 292px;
      margin-left: unset;
      margin-right: 20px;
    }

    @include md{
      width: 292px;
      margin-left: unset;
      margin-right: 20px;
    }

    &__blue {
      background-color: #288090;
      border: solid 1px #288090;
      color: white !important;
      
      & > a {
        color: white !important;
        text-decoration: none;
      }
    }

    &__transparent {
      background-color: transparent;
      border: solid 1px white;
      color: white !important;

      & > a {
        color: white !important;
        text-decoration: none;
      }
    }

    &__white {
      background-color: white;
      border: solid 1px #288090;
      color: #288090 !important;

      & > a {
        color: #288090 !important;
        text-decoration: none;
      }
    }
  }

  &__wrapper{
    // width:'50%';
    @include lg{
      max-width: 640px;
    }      
  }
  
}