@import '../../../variables';
@import "../../../scss/colors.scss";

.field-checkbox {
  color: $color-copy-label;

  &--label {
    font-weight: normal;
  }

  input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    border: 1px solid $color-body-grey;
    accent-color: $color-bright-purple;   
  }

  input[type="checkbox"]:checked {
    &:hover {
     accent-color: $color-regal-purple;
    }
  }

  input[type="checkbox"]:not(:checked){
    &:hover {
      -webkit-box-shadow: 0px 0px 0px 2px $color-bright-purple;
      border-radius: 1px;
    }
  }
  
}
