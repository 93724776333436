@import "../../mixins";
@import "../../variables";
@import "../../scss/colors.scss";

#dropdown-basic {

  &:hover:focus {
    border: none;
    box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
  }

  &:hover {
    border-color: $color-bright-purple;
    border-width: 2px;
  }

}

.Calculator,
.custom-section {
  .pp {
    margin-bottom: 5px !important;
  }

  .van {
    margin-bottom: 27px !important;

    &__alt {
      margin-bottom: 23px !important;
    }
  }

  width: 100%;
  box-sizing: border-box;
  background-color: $color-ground-grey;

  @include lg {
    padding: 38px 32px;
    max-width: 1164px;
    margin: 0 auto;
  }

  .button_wrapper {
    .left {
      position: absolute;
      left: 24px;
      font-size: 16px;
      margin-top: 4px;
    }

    .right {
      position: absolute;
      right: 24px;
      font-size: 16px;
      margin-top: 4px;
    }
  }

  .tooltip_anchor {

    & .tooltip {
      display: none;
      position: absolute;
      background-color: white;
      padding: 6px;
      box-shadow: 0px 0px 2px gray;
      font-size: 12px;
      line-height: 20px;
      left: 0;
      top: -300%;
      z-index: 11;
      color: #333333;
    }

    &:hover .tooltip,
    &:active .tooltip {
      display: block;
    }
  }

  .label_flex {
    display: flex !important;
    position: relative;

    & img {
      margin: auto 4px;
      height: 34px;
    }
  }

  .errorMsg {
    width: 100%;
    background: #e80602;
    padding: 15px 20px 20px 20px;
    display: block;
    box-sizing: border-box;
    font-family: $primary-font !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.2px;
    color: #ffffff !important;
    margin-top: 0px;
    margin-bottom: 20px;
    position: relative;

    &::before {
      content: "";
      border-bottom: 12px solid #e60303;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      display: block;
      height: 0;
      position: absolute;
      top: -9px;
      width: 0;
    }
  }

  &__divider {
    @include lg {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__title {
    font-size: 34px;
    font-family: "RN House Sans";
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: $color-champion-purple;
    font-size: 30px;
    line-height: 1.33;
    padding: 24px 16px;
    text-align: center;

    @include lg {
      font-size: 40px;
      padding: 0px;
      margin: 0px 0px 24px 0px;
      text-align: left;
    }
  }

  &__toggles-wrapper {
    width: 100%;
    display: flex;
  }

  &__toggle__left {
    border-top-left-radius: 26px;
    border-bottom-left-radius: 26px;
    flex: 1;
    outline: 1.5px solid $color-bright-purple;
    color: #001e69;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      color: $color-regal-purple;
      background-color: $color-lilac;
      box-shadow: 2px solid $color-regal-purple;
    }

    
    &:focus,
    &:active {
      outline: 2 px solid $color-bright-purple;
    }
  }

  &__toggle__right {
    border-top-right-radius: 26px;
    border-bottom-right-radius: 26px;
    flex: 1;
    outline: 1.5px solid $color-bright-purple;
    color: #001e69;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      color: $color-regal-purple;
      background-color: $color-lilac;
      box-shadow: 2px solid $color-regal-purple;
    }

    &:focus,
    &:active {
      outline: 2 px solid $color-bright-purple;
    }
  }

  &__toggle_active {
    background-color: $color-regal-purple;
    color: white;
  }

  &__btn-group {
    width: 100%;
    text-align: center;
  }

  &__btn {
    outline: 1.5px solid #FFFFFF;
    color: $color-bright-purple;
    padding: 14px;
    font-size: 16px;
    line-height: 24px;
    background: #FFFFFF;
    width: 350px;
    margin: 0 auto;
    border-radius: 26px;

    @include insmall {
      width: 100%;
    }

    &:hover {
      color: $color-regal-purple;
      background: $color-ground-grey;
    }

    &:focus,
    &:active {
      outline: 2 px solid $color-white;

    }
  }

  &__btn--alt {
    outline: 2px solid #FFFFFF;
    color: $color-white;
    padding: 14px;
    font-size: 16px;
    line-height: 24px;
    background: transparent;
    width: 350px;
    margin: 0 auto;
    border-radius: 26px;

    @include insmall {
      width: 100%;
    }

    &:hover {
      color: $color-regal-purple;
      background: $color-ground-grey;
    }

    &:focus,
    &:active {
      outline: 2 px solid $color-white;

    }
  }

  &__btn--revert {
    background-color: #001e69;
    color: white;
  }

  &__input {
    position: relative;
    padding: 24px;
    background-color: white;

    &_info {
      margin-top: 10px;
      color: #333;
    }

    &::after {
      content: "";
      height: 1px;
      background: #d1d1d1;
      opacity: 0.62;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }

    h2 {
      font-size: 24px;
      font-family: $primary-font;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: -0.1px;
      color: #001e69;
      margin: 0px 0px 30px 0px;
    }

    @include lg {
      width: 100%;
      display: inline-block;
      padding: 24px;
      background-color: white;
      box-sizing: border-box;

      h2 {
        font-size: 30px;
        line-height: 1.33;
        color: #001e69;
      }

      &::after {
        content: "";
        width: 1px;
        background: #d1d1d1;
        opacity: 0.62;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        left: auto;
      }
    }

    &__cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0);
      z-index: 100;
    }

    &__single {
      margin-bottom: 32px;

      .label_flex {
        display: flex !important;
        position: relative;
      }

      label {
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: normal;
        color: #333333;
        display: block;
        margin-bottom: 8px;
        position: relative;
        font-weight: 500;
      }

      .dropdown {
        min-height: 44px;
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.2px;
        border: 1px solid #C9C6C6;
        color: #333333;
        margin-bottom: 8px;
        box-sizing: border-box;
        border-radius: 8px;
        -webkit-appearance: none;
        outline: none;
        width: 100%;
        position: relative;
        background: white;
        cursor: pointer;

        .dropdown-closed {
          display: none;
        }

        .dropdown-open {
          border-top: 1px solid #C9C6C6;
        }

        .indicator-open {
          transform: rotate(-180deg);
          transition: 300ms;
          height: 30px;
          margin-top: 6px;
        }

        .indicator-closed {
          transition: 300ms;
          height: 30px;
          margin-top: 6px;
        }

        .dropdown_option {
          min-height: 44px;
          display: flex;
          padding: 0px 16px;

          &:hover {
            background-color: $color-lilac;
            border: 1px solid $color-bright-purple;
            border-radius: 8px;
          }
          
          &:active {
            color: #fff;
            text-decoration: none;
            background-color: $color-regal-purple;
          }

          div {
            margin: auto 0;
            width: 100%;
            height: 100%;
          }
        }

        .dropdown_option_selected {
          background-color: unset !important;
          color: unset !important;
          border-radius: 8px;

          &:hover:focus {
            border: none;
            box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
          }
        
          &:focus {
            outline: none;
            box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
          }
        
          &:hover {
            border-color: $color-bright-purple;
            border-width: 1.5px;
          }
        }
      }

      input,
      .CI__Wrap input {
        height: 44px;
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.2px;
        border: 1px solid #C9C6C6;
        color: #333333;
        margin-bottom: 8px;
        padding: 14px 18px;
        box-sizing: border-box;
        border-radius: 0px;
        -webkit-appearance: none;
        outline: none;

        &.touched {
          // border-bottom: 6px solid #fbba20;
          padding: 10px 16px !important;
          transition: 100ms;
        }

        &.error {
          border-bottom: 6px solid #e60303;
          padding: 10px 16px !important;
          transition: 100ms;
        }

        &.success {
          // border-bottom: 6px solid $color-bright-purple;
          padding: 10px 16px !important;
          transition: 100ms;
        }

        &.CI__full {
          width: 100%;
          position: relative;
          padding: 10px 16px;
          transition: 100ms;
          border-radius: 8px;
          
          &:hover:focus {
            border: none;
            box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
          }
    
          &:focus {
            outline: none;
            box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
          }
    
          &:hover {
            border-color: $color-bright-purple;
            border-width: 2px;
          }

        }

        @include lg {
          width: 149px;
          margin-right: 17px;

          &.CI__full {
            width: 149px;
            width: 100%;
          }
        }
      }

      .CI__Wrap input.disabled {
        pointer-events: none;
        border: solid 1px #d8d8d8;
        background-color: #ffffff;
        color: #c0c0c0;
      }

      & .CI__full__wrap_amount {
        position: relative;

        &::before {
          content: "£";
          font-size: 16px;
          position: absolute;
          left: 6px;
          top: 25px;
          height: 31px;
          transform: translateY(-50%);
          z-index: 10;
        }
      }

      & .CI__full__wrap_years {
        position: relative;

        &::after {
          content: "months";
          font-size: 16px;
          position: absolute;
          left: 4rem;
          top: 24.5px;
          height: 31px;
          transform: translateY(-50%);
          z-index: 10;
        }
      }

      & .CI__Wrap {
        display: flex;
        justify-content: flex-start;

        &>label {
          margin-right: 10px;

          @include lg {
            max-width: 149px;
            position: relative;
          }

          &>.prepend {
            pointer-events: none;
            position: absolute;
            left: 40px;
            height: 25px;
            top: 32px;
            margin: auto;
            z-index: 10;

            &.padding {
              left: 45px;
            }
          }

          &.disabled>span {
            top: 35px;
            color: #c0c0c0;
          }
        }

        @include lg {
          justify-content: left;
        }
      }
    }
  }

  &__output {
    padding: 24px;

    &_groups {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 800px;

      @include lg {
        min-height: unset;
      }

      & .hidden {
        display: none;
      }
    }

    h2 {
      font-size: 24px !important;
      font-family: $primary-font;
      line-height: 1.33;
      letter-spacing: normal;
      font-weight: 500;
      color: white;
      margin: 30px 0px 20px 0px;
    }

    background-color: $color-champion-purple;

    @include lg {
      width: 100%;
      height: 100%;
      padding: 24px;

      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 24px;
        line-height: 1.31;
        margin: 0px 0px 20px 0px;
      }
    }

    &_values {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 8px;
      row-gap: 20px;

      @include lg {
        grid-template-columns: 1fr 1fr;
      }
    }

    &_single {
      & .CO {
        &__label {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: white;
          display: block;
          position: relative;
        }

        &__value {
          font-size: 26px;
          font-family: $primary-font-bold;
          line-height: 32px;
          letter-spacing: normal;
          color: white;
          display: block;
          margin-bottom: 10px;

          &>span {
            font-size: 18px;
            font-family: $primary-font-bold;
            line-height: 1.44;
            letter-spacing: 0.2px;
            color: white;
          }
        }
      }
    }

    &_info {
      font-size: 14px;
      line-height: 19px;
      color: white;
      margin-top: 26px;
      text-align: left;
    }
  }

  &__CTA {
    background-color: #1d7b8a;
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
    color: white;
    margin-top: 26px;
    padding: 14px;
    line-height: 33px;
    border: 2px solid transparent;

    &:active,
    &:focus {
      border: 2px solid white;
    }
  }
}

.ext-link {
  color: $color-bright-purple;
  padding-bottom: 2px;
  text-decoration: underline;
}

.custom-section-wrapper {
  background-color: #001e69;
  color: white;
}

.custom-section {
  background: transparent;
  padding-top: unset;
  padding-bottom: unset;
}