@import "../../mixins";
@import "../../scss/colors.scss";

.Header {
  display: flex;
  height: 61px;
  border-bottom: solid 1px rgba(51, 51, 51, 0.2);
  background: $color-regal-purple;
  position: relative;
  justify-content: space-between;
  // overflow: hidden;s
  z-index: 11;

  @include sm {
    // overflow: auto;
  }

  @include lg {
    display: block;
    vertical-align: top;
    height: auto;
    overflow: visible;

    &__overlay {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  &__content {
    display: flex;
    padding: 0 22px;
    min-height: 60px;

    @include lg {
      display: block;
    }
  }

  &__top {
    height: 48px;
    box-sizing: border-box;
    vertical-align: top;

    @include lg {
      background: $color-champion-purple;
      position: relative;
    }

    &__links {
      padding-left: 142px;
      vertical-align: middle;
      height: 48px;
      width: calc(100% - 206px);
      overflow: hidden;
      box-sizing: border-box;
      display: none;

      @include lg {
        display: inline-block;
      }

      & > a {
        display: inline-block;
        font-size: 14px;
        color: #fff;
        padding: 9px 26px;
        line-height: 30px;
        vertical-align: middle;
        text-decoration: none;

        &.active {
          background: white;
          color: #001e69;
        }
      }
    }

    @include lg {
      width: 100%;
      display: block;
    }
  }

  &__login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 64px;

    & > button {
      border: none;
      background: none;
      padding: 12px;
      position: relative;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: 20px;
      transform: translate(-50%,-50%);


      & .login-desktop {
        display: none;
        height: 22px;
        padding-left: 6px;
      }

      & .login-mobile {
        width: 24px;
      }

      @include md {
        & {
          margin-left: 0px;
          position: static;
          transform: translate(0,-5px);
        }
      }

      @include lg {
        & {
          position: static;
          transform: translate(0,0);
        }
        & .login-mobile {
          display: none;
        }

        & .login-desktop {
          display: inline-block;
        }
      }

      & > svg {
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      & > span {
        display: block;
        font-size: 14px;
        line-height: 14px;
        position: relative;
        padding-top: 23px;
        color: $color-white;
      }

      &:after {
        content: "";
        width: 2px;
        height: 37px;
        position: absolute;
        display: block;
        top: 12px;
        right: 0px;

        @include md {
          background: #b7b7b7;
        }
      }
    }

    &--options {
      width: 100%;
      min-width: 193px;
      display: block;
      list-style-type: none;
      position: absolute;
      background: #f5f5f5;
      top: 51px;
      right: -30px;
      z-index: 10;
      padding: 20px;
      
      @include insmall {
        right: unset !important;
        left: -8px !important;
      }

      & > li {
        margin-bottom: 17px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        & > a {
          font-size: 18px;
          text-decoration: none;
          line-height: 26px;
          color: $color-ground-grey;
        }
      }
    }

    @include lg {
      position: absolute;
      right: 22px;
      top: 0;
      height: 100%;
      max-width: initial;
      

      & > button {
        padding: 0;
        height: 68%;
        vertical-align: middle;
        background-color: $color-white;
        border-radius: 16px;

        & > svg {
          position: static;
          display: inline-block;
          vertical-align: middle;
        }

        & > span {
          display: inline-block;
          letter-spacing: 0.16px;
          color: $color-bright-purple;
          vertical-align: middle;
          padding-top: 0;
          padding-left: 6px;
          padding-right: 12px;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  
  &__logo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
     right: unset;
    margin: auto;
    height: 25px;
    width: 112px;

    @include md{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    @include lg {
      display: inline-block;
      position: static;
      margin-right: 30px;

      & > a {
        height: 60px;
        position: relative;

        & > svg {
          height: 20px;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    & > a {
      display: block;
      width: 112px;
      margin: 0 auto;
      display: flex;
    }
    img{
      height: 60px;
      margin: auto;
    }
  }
  &__NavToggle {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 64px;
    flex-direction: column;
    padding: 0px 7px 0px 32px;
    color: $color-white;

    @include lg {
      display: none;
    }

    & > span {
      display: block;
      font-size: 14px;

      &:before {
        font-family: "natwest icons";
        content: "\e020";
        font-size: 14px;
        display: block;
      }
    }

    &:before {
      content: "";
      width: 2px;
      height: 37px;
      position: absolute;
      display: block;
      top: 12px;
      left: 0px;
      background: #b7b7b7;
    }
  }
}
