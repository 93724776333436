@import '../../../variables';
@import '../../../scss/colors.scss';

.field-block {

  &.field-block--lg {
    .field-company-search__search {
      > div {
        width: 368px; 
      }
    }
  }
  
  .field-company-search__search {
  
    @media (max-width: #{$screen-sm-min}) {
      width: 100%;

      div {
        width: 100% !important;
      }
    }

    > div:first-of-type {
      border: none;
      outline: none;
      // box-shadow: none;
      box-shadow: 0 0 0 1px $color-border-alternative;

      &:hover {
        border: none;
        outline: none;
        box-shadow: 0 0 0 2px $color-bright-purple;
      }

      &:hover:focus-within {
        border: none;
        outline: none;
        box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
      }

      &:focus-within {
        border: none;
        outline: none;
        box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
      }
    }

    input {
      font-size: 16px;
      line-height: 20px;

      // &:hover {
      //   outline: 2px $color-bright-purple;
      // }

      &:focus {
        box-shadow: none;
        border: none;
      }
    }
  }

  .field-company-search__loading-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;background-color:rgba(0, 0, 0, 0.2);
    z-index: 10000;

    .lds-spinner {
      color: official;
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
      position: absolute;
      left: 47%;
      top: 47%;
    }
    .lds-spinner div {
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;
    }
    .lds-spinner div:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: #fff;
    }
    .lds-spinner div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    .lds-spinner div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    .lds-spinner div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    .lds-spinner div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    .lds-spinner div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    .lds-spinner div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    .lds-spinner div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    .lds-spinner div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    .lds-spinner div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    .lds-spinner div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    .lds-spinner div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    .lds-spinner div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
    @keyframes lds-spinner {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}