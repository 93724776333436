@import "../../mixins";
@import "../../variables";
@import "../../scss/colors.scss";

.DN {
  display: none;
  height: 60px;

  @include lg {
    display: inline-block;
    width: calc(100% - 142px);
  }

  &__Links {
    list-style-type: none;
    display: inline-block;
    vertical-align: middle;
    height: 60px;

    & > li {
      display: inline-block;

      & > a,
      & > button {
        text-decoration: none;
        color: $color-ground-grey;
        padding: 15px 16px;
        font-size: 14px;
        line-height: 30px;
        display: block;

        &.DN__Active {
          position: relative;

          &:before {
            content: "";
            width: calc(100% - 32px);
            height: 1px;
            background: #1d7b8a;
            position: absolute;
            left: 16px;
            bottom: 19px;
            transition: width 0.3s ease;
          }

          &:after {
            content: "";
            bottom: 0;
            position: absolute;
            left: calc(50% - 8px);
            border-right: 8px solid transparent;
            border-bottom: 12px solid #001e69;
            border-left: 8px solid transparent;
          }

          & + div {
            display: block;
          }
        }
      }
    }

    &-2 {
      display: none;
      height: 60px;
      background-color: #001e69;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 108px;
      list-style-type: none;
      z-index: 10;

      & > ul {
        z-index: 1;
        margin: 0 auto;
        position: relative;

        @include xl {
          padding: 0px 22px;
          margin: 0;
        }

        & > li {
          display: inline-block;

          & > a,
          & > button {
            color: white;
            display: block;
            font-size: 14px;
            padding: 15px 24px;
            line-height: 30px;
            text-decoration: none;

            &.DN__Active {
              position: relative;

              &:before {
                content: "";
                width: calc(100% - 48px);
                height: 1px;
                background: #fff;
                position: absolute;
                left: 24px;
                bottom: 19px;
                transition: width 0.3s ease;
              }

              &:after {
                content: "";
                bottom: 0;
                position: absolute;
                left: calc(50% - 8px);
                border-right: 8px solid transparent;
                border-bottom: 12px solid #fff;
                border-left: 8px solid transparent;
              }
              & + div {
                display: flex;
              }
            }
          }
        }
      }
    }

    &-3 {
      // display: flex;
      display: none;
      justify-content: space-between;
      position: absolute;
      top: 60px;
      left: 0;
      background: white;
      padding: 30px 20px 60px 20px;

      &:after {
        content: "";
        width: 100vw;
        height: 100%;
        background: white;
        position: absolute;
        left: calc(calc(-100vw + 922px) / 2);
        top: 0;
        z-index: -1;
      }

      & > .DN__Section {
        width: 33.3% !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        & > h3 {
          font-size: 20px;
          line-height: 30px;
          font-family: $primary-font-bold;
          margin-bottom: 4px;
          color: #303030;
        }

        & > a {
          line-height: 30px;
          font-size: 14px;
        }
      }
    }

    &-3-top {
      top:1px;
    }  
  }


  &__Section {
    width: 307px;
    margin-top: 28px;
    padding-left: 26px;
    margin-bottom: 60px;
    border-left: 1px solid rgba(51, 51, 51, 0.2);

    &:first-child {
      width: 308px;
      padding-left: 25px;
      border-left: none;
    }

    h3 {
      font-family: $primary-font-bold;
      margin-bottom: 4px;
    }

    a {
      color: #1d7b8a;
      text-decoration: none;
      display: block;
      width: 100%;
      padding: 0 20px 0 0;
      font-size: 18px;
    }
  }

  &__Search {
    vertical-align: middle;
    float: right;
    width: 204px;
    position: relative;
    top: 10px;
  }
}

.DN__Links-2{
  height: fit-content;
}