@import '../../../scss/colors';
@import '../../../variables';

.field-block {

  @media (max-width: #{$screen-sm-min}) {
    width: 100%;
    label,
    input {
      width: 100%;
    }

    input.field-address__address-postcode {
      width: 100%;
    }

    button.field-address__btn-search {
      margin-left: 0;
      width: 100%;
    }

    input.field-address--input {
      width: 100%;
    }
  }

  .field-address__address-postcode {
    width: 182px;
    margin-bottom: 8px;
  }

  .field-address__btn-switch-search {
    margin-bottom: 24px;
  }

  button.field-address__btn-search {
    display: inline-block;
    width: 176px;
    margin-left: 19px;
  }
  
  .field-address__search {
    width: 340px;
    
    input {
      font-size: 16px;
      line-height: 20px;
      background-color: red;;
    }
  }

  .field-address--input {
    margin-bottom: 16px;
  }

  input {
    font-size: 16px;
    line-height: 20px;
    color: $color-input;
    width: 235px;
    height: 44px;
    border: 1px solid $color-border;
    border-radius: 0px;
    font-weight: normal;

    &:focus {
      box-shadow: 0 0 0 1px $color-border-focus;
      border: 1px solid $color-border-focus;
    }
  }
}