@import "./variables";
@import './mixins';

// @import "~bootstrap/scss/bootstrap";
@import '~react-bootstrap-typeahead/css/Typeahead.css';

@import './scss/';



// @import url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/singlearticle/clientlibs/clientlib_singlearticle_lombard-_f7feaf3eb631d35f6a4e787199a86d02.min.css");
// @import url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/rte/clientlibs/clientlib_rte_lombard-_d45022b46072a080708e79f108570aa4.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iaglobalsearch/clientlibs/clientlib_ia_global_search_lombard-_f7feaf3eb631d35f6a4e787199a86d02.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/cta/clientlibs/clientlib_cta_lombard-_7ad603327c300d88c7efdd686a27d4b6.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/productlistingmultiple/clientlibs/clientlib_productlisting_multiple_shelf_lombard-_9c0a0f0a76061e2ba98574ca8a0ce1bc.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/productlistingmultiple/clientlibs/clientlib_productlisting_multiple_shelf_lombard-_9c0a0f0a76061e2ba98574ca8a0ce1bc.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/inpagenavigation/clientlibs/clientlib_inpagenavigation_lombard-_7edf6b5990f5e83ef64641a9ae4d5028.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/product/clientlibs/clientlib_product_lombard-_7edf6b5990f5e83ef64641a9ae4d5028.min.css");
// @import url("https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base_lombard-_52116cc441fb90cd2ca76a1f6ec77915.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/whyus/clientlibs/clientlib_whyus_lombard-_7ad603327c300d88c7efdd686a27d4b6.min.css");
// @import url("https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base_lombard-_52116cc441fb90cd2ca76a1f6ec77915.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/standalone/clientlibs/clientlib_standalone_lombard-_014361c0ae757f33248bf169625be1de.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/components/foundation/iaheadernavigation/clientlibs/clientlib_ia_headernavigation_lombard-_9c0a0f0a76061e2ba98574ca8a0ce1bc.min.css");
// @import url("https://lombard.co.uk/etc.clientlibs/responsive/clientlibs/clientlib_base/resources/fonts/nw-icons/fonts/nw-icons.woff2");
// @import url("https://digital.lombard.co.uk/static/css/clientlib_base_lombard-_52116cc441fb90cd2ca76a1f6ec77915.min.css");
@import url("https://lombard.co.uk/etc.clientlibs/responsive/shelves/productlistingmultiple/clientlibs/clientlib_productlisting_multiple_shelf_lombard-_9c0a0f0a76061e2ba98574ca8a0ce1bc.min.css");
@import url("./styles/clientlib_ia_footer_lombard-_52116cc441fb90cd2ca76a1f6ec77915.min.css");

@import "./styles/singlearticle_lombard.min.css";
@import "./styles/clientlib_rte_lombard-_d45022b46072a080708e79f108570aa4.min.css";
@import "./styles/clientlib_base_lombard-_52116cc441fb90cd2ca76a1f6ec77915.min.css";
// @import "../src/styles/"
// @import url("../src/styles/clientlib_singlearticle_lombard-_f7feaf3eb631d35f6a4e787199a86d02.min.css");

// /etc.clientlibs/responsive/shelves/whyus/clientlibs/clientlib_whyus_lombard-_7ad603327c300d88c7efdd686a27d4b6.min.css
// https://lombard.co.uk/etc.clientlibs/responsive/shelves/whyus/clientlibs/clientlib_whyus_lombard-_7ad603327c300d88c7efdd686a27d4b6.min.css

* {
  padding: 0;
  margin: 0;
  font-family: $primary-font;
  font-family: "RNHouseSansRegular",sans-serif;
}

#root {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

button {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

.modal-open {
  overflow: hidden;

  @include lg {
    overflow: hidden;

    // & > #root > div > div::after {
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 10;
    //   background-color: rgba(0, 0, 0, 0.6);
    // }
  }
}

.Slider_wrapper{
  display: flex;
  gap: 24px;
  padding-bottom: 24px;

  .rangeslider-horizontal{
    flex: 1;
  }
  
  .btn {
    transition: 300ms;
    border-radius: 0px;
    border: 1.5px solid $color-bright-purple;
    height: 44px;
    margin: auto;
    color: #1d7b8a;
    font-size: 1.5rem;
    display: grid;
    place-content: center;
    font-family: unset;
    
    &:active{
      transition: 300ms;
      transform: scale(1.02);
    }
  }
}

.rangeslider {
  box-shadow: none;
  border-radius: 100px;
  margin: 13px 0px 11px 0px;

  &-horizontal {
    height: 30px;
    width: 100%;
    position: relative;
    background: transparent;
    &::before {
      content: "";
      background: #C9C6C6;
      height: 6px;
      width: 100%;
      position: absolute;
      top: 0;
      margin: auto;
      bottom: 0;
      z-index: 0;
    }
    & .rangeslider__fill {
      background-color: $color-regal-purple;
      position: absolute;
      top: 0;
      bottom: 0px;
      height: 6px;
      margin: auto;
      z-index: 1;
    }
  }
  & .rangeslider__handle {
    border: none;
    background: $color-bright-purple;
    box-shadow: none;
    z-index: 2;
    outline: none;
    
    &::after {
      display: none;
    }
  }

  & .rangeslider__labels {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    flex-direction: row-reverse;
    pointer-events: none;

    & .rangeslider__label-item {
      display: block;
      position: relative;
      transform: none;
      font-size: 16px;
      color: #333333;
      top: 40px;
      left: 0px !important;

      &:nth-child(1){
        left: 64px !important;
      }

      &:nth-child(2){
        left: -64px !important;
      }
    }
  }
}

@font-face {
  font-family: "RN House Sans";
  src: url("./assets/fonts/RNHouseSans-Regular.ttf") format("truetype"),
    url("./assets/fonts/RNHouseSans-Regular.otf") format("opentype"),
    url("./assets/fonts/RNHouseSansW01Regular.woff") format("woff");
}

@font-face {
  font-family: "RN House Sans Bold";
  src: url("./assets/fonts/RNHouseSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/RNHouseSansW01Bold.woff") format("opentype"),
    url("./assets/fonts/RNHouseSans-Bold.otf") format("woff");
}

@font-face {
  font-family: "natwest icons";
  src: url("./assets/fonts/nw-icons.woff") format("opentype");
}

// 

@font-face {
  font-family: "nw-icons";
  src: url("./assets/fonts/nw-icons.woff") format("opentype");
}

@font-face {
  font-family: "RNHouseSansRegular";
  src: url("./assets/fonts/RNHouseSansW01Regular.ttf") format("truetype"),
  url("./assets/fonts/RNHouseSans-Regular.otf") format("opentype"),
  url("./assets/fonts/RNHouseSansW01Regular.woff") format("woff");
}

@font-face {
  font-family: "RNHouseSansBold";
  src: url("./assets/fonts/RNHouseSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/RNHouseSansW01Bold.woff") format("opentype"),
    url("./assets/fonts/RNHouseSans-Bold.otf") format("woff");
}

@font-face {
  font-family: 'knile-semibold';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/knile-semibold-webfont.eot');
  src:
    local('RN House Sans Bold'),
    url('./assets/fonts/knile-semibold-webfont.woff') format('woff'),
    url('./assets/fonts/knile-semibold-webfont.ttf') format('truetype');
}

@font-face {
  font-family: "RN House Sans Bold";
  src: url("./assets/fonts/RNHouseSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/RNHouseSansW01Bold.woff") format("opentype"),
    url("./assets/fonts/RNHouseSans-Bold.otf") format("woff");
}

@font-face{
  font-family:"RNHouseSansRegular";
  src:url("./assets/fonts/RNHouseSansW01Regular.eot");
  src:url("./assets/fonts/RNHouseSansW01Regular.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/RNHouseSansW01Regular.woff2") format("woff2"),
  url("./assets/fonts/RNHouseSansW01Regular.woff") format("woff"),
  url("./assets/fonts/RNHouseSansW01Regular.ttf") format("truetype"),
  url("./assets/fonts/RNHouseSansW01Regular.svg") format("svg");
  font-display:swap}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}


.btn {
  a:hover {
    text-decoration: none;
  }
}