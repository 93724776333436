@import '../../../variables';


.field-date {

  .field-content {
    display: flex;

    input {
      width: 56px;
      text-align: center;
      margin-right: 16px;
    }

    .field-date__year {
      width: 80px;
    }
  }
}