@import "../../mixins";
@import "../../variables";

.ED {
  box-sizing: border-box;
  display: block;
  text-align: left;

  @include lg {
    &__flex {
      display: flex;
      justify-content: space-between;
    }
  }

  .Bottom__desktop {
    border: none;
  }

  &__info {
    display: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #666666;
    max-width: 600px;

    @include lg {
      display: block;
    }
  }

  &__result {
    position: relative;
    padding: 26px 24px;
    border-radius: 4px;
    border: solid 1.5px #429448;
    margin-bottom: 40px;

    &-warning {
      @extend .ED__result;
      border: solid 1.5px #ce3b57;
    }

    &__content {
      display: inline-block;
      width: calc(100% - 40px);
      vertical-align: top;

      & > span {
        font-family: $primary-font-bold;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: #333333;
      }

      & > p {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: #333333;
      }
    }

    & > svg {
      display: inline-block;
      width: 24px;
      object-fit: contain;
      margin-right: 16px;
    }
  }

  &__wrapper {
    max-width: 1164px;
    margin: 0 auto;

    @include lg {
      padding: 0px 20px;
    }
  }

  &__heading {
    padding: 30px 20px;
    width: 100%;
    background: linear-gradient(to left, #1e1532, #422e5c);
    box-sizing: border-box;

    @include lg {
      padding: 30px 0px;
    }

    &__title > h1 {
      display: block;
      max-width: 80%;
      font-size: 34px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 12px;
      text-align: left;

      @include lg {
        font-size: 40px;
      }
    }
  }

  &__content {
    padding: 30px 20px;
    box-sizing: border-box;

    @include lg {
      padding: 50px 0px;
    }

    &__title {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #001e69;
      margin-bottom: 30px;
      text-align: left;

      &--large {
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #001e69;
        margin-bottom: 20px;

        @include lg {
          font-size: 30px;
        }
      }
    }

    &__divider {
      height: 1px;
      display: block;
      margin: 30px 0;
      background: #d1d1d1;
    }

    &__checklist {
      margin-bottom: 30px;
      list-style-type: none;

      @include lg {
        &__wrapper {
          width: 60%;
          max-width: 649px;
          display: block;
          box-sizing: border-box;
          padding-right: 50px;
          margin-bottom: 0;
        }
      }

      &__wrapper.checklist-warning {
        border-bottom: solid 1px #c9c6c6;
      }

      & > li {
        display: block;
        padding-left: 45px;
        position: relative;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: #333333;
        margin-bottom: 15px;

        @include lg {
          margin-bottom: 25px;
        }

        & > strong {
          font-family: $primary-font-bold;
        }

        & > a {
          color: #288090;
        }

        & > span {
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          left: 0;
          top: 0;
          background: #001e69;
          border-radius: 50%;
          line-height: 24px;
          text-align: center;
          color: white;
          font-size: 16px;
        }

        & > svg {
          position: absolute;
          left: 0;
          top: 0;
          height: 26px;
          width: 27px;
          object-fit: contain;
        }
      }
    }

    &__para {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #333333;
      margin-bottom: 30px;

      & > strong {
        font-family: $primary-font-bold;
        color: #001e69;
      }
    }
  }
  &__Calculator__output {
    width: 100%;
    height: 100%;
    display: block;
    padding: 32px;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.03);
    border: solid 1px #eae9e9;
    background-color: #ffffff;

    @include lg {
      width: 40%;
      min-width: 363px;
    }
    h2 {
      font-size: 24px;
      font-family: $primary-font-bold;
      line-height: 1.33;
      letter-spacing: normal;
      color: #333333;
      margin: 30px 0px 20px 0px;
    }

    & > h3 {
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #333333;
      margin-bottom: 32px;
    }

    & > p {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #333333;
      margin-bottom: 30px;
    }

    &_btn {
      height: 56px;
      padding: 0px 24px;
      border: solid 1px #288090;
      background-color: #288090;
      display: block;
      text-decoration: none;
      font-size: 18px;
      line-height: 56px;
      letter-spacing: 0.2px;
      text-align: center;
      color: #ffffff;

      @include lg {
        width: auto;
      }
    }

    &_info {
      display: block;
      color: #7f7f7f;
      margin-top: 20px;
    }

    @include lg {
      width: 40%;
      height: 100%;
      padding-left: 32px;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;

      h2 {
        font-size: 26px;
        line-height: 1.31;
        margin: 0px 0px 20px 0px;
      }
    }

    &_single {
      & .CO {
        &__label {
          font-size: 18px;
          line-height: 1.44;
          letter-spacing: 0.2px;
          color: #333333;
          display: block;
          margin-bottom: 6px;
        }
        &__value {
          font-size: 24px;
          font-family: $primary-font-bold;
          line-height: 1.33;
          letter-spacing: normal;
          color: #001e69;
          display: block;
          margin-bottom: 10px;

          & > span {
            font-size: 18px;
            font-family: $primary-font-bold;
            line-height: 1.44;
            letter-spacing: 0.2px;
            color: #001e69;
          }
        }
      }
    }
    &_info {
      font-size: 16px;
      line-height: 1.5;
      color: #666666;
      margin-top: 26px;
    }
  }

  &__bottom__preapply {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
    background: white;
    margin: 0 auto;

    &__wrapper {
      margin: 0 auto;
    }
  }


  &__bottom {
    display: block;
    max-width: 1164px;
    margin: 0 auto;
    padding-bottom: 20px;
    background: white;
    max-width: 1164px;
    margin: 0 auto;

    &__wrapper {
      max-width: 1164px;
      margin: 0 auto;
    }

    &__links {
      list-style-type: none;
      margin-bottom: 40px;

      & > li {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        margin-top: 40px;

        & > a {
          color: #288090;
        }
      }
    }

    &.bottom-warning {
      width: 100%;

      @include lg {
        max-width: 649px;
        margin-right: auto;
        margin-left: 0;
      }
    }

    & > h2 {
      width: 100%;
      padding: 30px 20px 0px 20px;
      font-size: 34px;
      line-height: 1.29;
      color: #001e69;

      @include lg {
        padding: 46px 0px 0px 0px;
      }
    }

    & > h3 {
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.29px;
      color: #001e69;
      padding: 56px 20px 0px 0px;
      margin-bottom: 24px;

      @include lg {
        padding: 56px 0px 0px 0px;
      }
    }

    & > div > p {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #333333;
    }
  }

  &__btn {
    background-color: #288090;
    border: solid 1px #288090;
    display: block;
    height: 56px;
    font-size: 18px;
    line-height: 56px;
    text-align: center;
    margin-top: 20px;
    color: white !important;
    text-decoration: none;

    @include lg {
      width: 292px;

      &__item {
        margin-top: 50px;
        padding-left: 0 !important;
      }
    }
  }

  &__desktop {
    display: none;
    @include lg {
      display: block;
    }
  }

  &__mobile {
    @include lg {
      display: none;
    }
  }
}

.Accordion {
  border-top: solid 1px #c9c6c6;
  &__single,
  &__single--inner {
    border-bottom: solid 1px #c9c6c6;
    padding: 0px 20px;

    & > button {
      width: 100%;
      max-width: 1164px;
      margin: 0 auto;
      display: block;
      & > h3 {
        display: block;
        width: 100%;
        font-size: 24px;
        line-height: 1.44;
        letter-spacing: 0.2px;
        color: #333333;
        position: relative;
        padding: 32px 30px 32px 0px;
        box-sizing: border-box;
        text-align: left;

        & > svg {
          width: 20px;
          object-fit: contain;
          position: absolute;
          right: 0px;
          top: 32px;
          transition: transform 0.2s;
        }
      }
    }
    &.active {
      padding-bottom: 10px;

      & > button > h3 > svg {
        transform: rotate(45deg);
      }
    }
    &__content {
      max-height: 0;
      max-width: 1164px;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      transition: max-height 0.3s;

      & > p,
      & > ul {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #333333;
        padding-right: 20px;
        padding-bottom: 20px;
      }
      & > ul {
        padding: 0px 20px;
        & > li {
          margin-bottom: 10px;
        }
      }
    }

    &.active > .Accordion__single__content {
      max-height: 3000px;
    }
  }
}
