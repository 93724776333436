@import '../../../variables';


.field-input {

  @media (max-width: #{$screen-sm-min}) {
    width: 100%;
    label,
    input {
      width: 100%;
    }
  }

  &.field-block--sm {
    input {
      width: 184px;
    }
  }

  &.field-block--lg {
    input {
      width: 368px;
    }
  }

  .field-content > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 53%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
  }
}