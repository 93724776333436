@import '../../variables';
@import "../../scss/colors.scss";


// ******************************************************
// field
// ******************************************************
.field-block {
  margin-top: 24px;
  font-size: 16px;

  label {
    margin-bottom: 0;
  }

  input {
    font-size: 16px;
    line-height: 20px;
    color: $color-input;
    width: 235px;
    height: 44px;
    border: 1px solid $color-border;
    border-radius: 0px;

    &:focus {
      box-shadow: 0 0 0 1px $color-border-focus;
      border: 1px solid $color-border-focus;
    }
  }

  .field-title--group {
    color: $color-copy;
  }

  .field-title {
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 8px;
    color: $color-copy-label;

    &:hover {
      outline: 2px $color-bright-purple;
    } 
  }

  .field-subtitle {
    display: flex;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    margin-bottom: 8px;
    color: $color-copy-label;

    
    svg {
      display: block;
      width: 18px;
      height: 18px;
      // background-color: #666666;
    }
    span {
      display: block;
      margin-left: 8px;
      margin-top: 3px;
    }
  }

  .field-content {
    position: relative;
    

    & > input {
      padding-left: 12px;
      padding-right: 12px;
      outline: none;
      border-radius: 8px;

      &:hover:focus {
        border: none;
        box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px grey, 0 0 0 2px $color-white, 0 0 0 4px $color-bright-purple;
      }

      &:hover {
        border-color: $color-bright-purple;
        border-width: 2px;
      }
    }
  }


} 

input[class*="error"] {
  border: 2px solid red !important;
}

.error {
  border: 2px solid red !important;
}


// .field-block {

//   .field-title--group {
//     color: $black;
//   }

//   .field-subtitle {
//     svg {
//       width: 24px;
//       height: 24px;
//       background-color: #666666;
//     }
//   }
// }