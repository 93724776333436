$primary-font: "RN House Sans";
$primary-font-bold: "RN House Sans Bold";
$secondary-font: "knile-semibold";

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Small mobile phones
$screen-xsm-min: 380px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 1024px;

// Large tablets and desktops
$screen-xl-min: 1400px;



